import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { SchoolRepository } from "../../repository/SchoolRepository";

export function ClusterViewModel()
{


    const schoolRepository=SchoolRepository(); 

    const [lstClusters, setClusters] = useState({


        data: []
    })


    const [lstFilterCluster, setFilterCluster] = useState(
        []
    )

    const [validate, setValidate] = useState({
        cluster_id: 0,
        cluster_code: '',
        cluster_name: '',
        city_id: 0,
        city: '',
        updated_date: ''
    })

    const [cluster, setCluster] = useState({
        cluster_id: 0,
        cluster_code: '',
        cluster_name: '',
        city_id: 0,
        city: '',
        updated_date: ''
    })

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: any) => {
        debugger;
        let filteredClusters = lstFilterCluster.filter((cluster: any) =>
            cluster.cluster_name.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFilterCluster(filteredClusters);
        setSearchTerm(e.target.value);
    };

    const getClusters = async () => {
        try { 

            const data:any = await  schoolRepository.getclusters();
          
            setClusters({
                data
            })

            setFilterCluster(data);

        }
        catch (error) {
            setClusters({
                data: []
            })
        }
    }

    const [lstCities, setCities] = useState({
        data: []
    })

    const getCities = async () => {
        try { 

            const data:any = await  schoolRepository.getCitys();
       
            setCities({
                data
            })

        }
        catch (error) {
            setCities({
                data: []
            })

        }
    }

    useEffect(() => {
        getClusters();
        getCities();

    }, [alert]);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, f: string) => {
        debugger;
        if ('value' in e.target) {
            debugger
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setCluster((prev) => ({ ...prev, [name]: value }));

            //getClusterbycitys();


        } else {
            debugger
            const { name, value } = e.target as HTMLSelectElement;
            setCluster((prev) => ({ ...prev, [name]: value }));
        }


    }


    const cancel = () => {
        debugger;

        setCluster({
            cluster_id: 0,
            cluster_code: '',
            cluster_name: '',
            city_id: 0,
            city: '',
            updated_date: ''
        })

        setValidate({
            cluster_id: 0,
            cluster_code: '',
            cluster_name: '',
            city_id: 0,
            city: '',
            updated_date: ''
        })


    }

    const saveCluster = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const newValidate = { ...validate };

        if (!cluster.cluster_code.trim()) {
            newValidate.cluster_code = "Cluster code required"
        }

        if (!cluster.cluster_name.trim()) {
            newValidate.cluster_name = "Cluster name required"
        }

        if (cluster.city_id === 0) {
            newValidate.city = "City required"
        }

        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger;
                const responseData:any = await   schoolRepository.saveCluster(cluster);
                   
                if (responseData[0].db_result > 0) {
                    cancel();
                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;

                    if (buttonElement) {
                        buttonElement.click();
                    }

                    getClusters();
                }
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            } catch (error) {
            }
        } else {
            setValidate(newValidate);
        }
    }

    const editCluster = (e: any) => {
        debugger;
        setCluster(e);
    }

    const deleteClusters = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
      
        if (willDelete.isConfirmed) {
            debugger;
            const responseData :any= await  schoolRepository.deleteclusters({ 'cluster_id': e});
            if (responseData[0].db_result > 0) {
                debugger;
                Swal.fire("Record Deleted successfully", "", "success");
                getClusters();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }

    const [currentPage, setCurrentPage] = useState(1);
    debugger;
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filteredClusters = lstFilterCluster.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstFilterCluster.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }

        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }

    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    }



    return{searchTerm,handleSearch,filteredClusters,editCluster,deleteClusters,prePage,currentPage,changePage,nextPage,numbers,cancel,cluster,handleChange,validate,lstCities,saveCluster}


}