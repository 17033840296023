import React from "react";
import AsyncSelect from "react-select/async";
import { DiscountViewModel } from "./Discountmodel";

const DiscountView: React.FC = () => {
    const discountModel = DiscountViewModel();
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">Discount</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Discount </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                                <label>Search:<input type="search" className="form-control form-control-sm"
                                                    placeholder="" aria-controls="products-datatable"
                                                    value={discountModel.searchTerm} onChange={discountModel.handleSearch} /></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2"
                                                data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={discountModel.newdisountclose}>
                                                <i className="mdi mdi-plus-circle me-2"></i> Add Discount
                                            </a>
                                        </div>
                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name</th>
                                                            <th>Coupon Code</th>
                                                            <th>Type</th>
                                                            <th>Valid From</th>
                                                            <th>Valid Thru</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {discountModel.filtereDiscs.map((item: any, index: number) => (
                                                            <tr key={item.discount_id}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.discount_name}</td>
                                                                <td>{item.coupon_code}</td>
                                                                <td>{item.discount_type}</td>
                                                                <td>{item.start_date}</td>
                                                                <td>{item.end_date}</td>
                                                                <td>
                                                                    <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => discountModel.editDiscount(item)}></i>
                                                                    {<i className="mx-2 uil uil-trash-alt" onClick={() => discountModel.deleteDiscount(item.discount_id)}></i>}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }

                                                    </tbody>
                                                </table>
                                                <nav className="text-right mt-3" >

                                                    <ul className='pagination float-end' >
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={discountModel.prePage}>prev</a>
                                                        </li>
                                                        {

                                                            discountModel.numbers.map((n, i) =>
                                                            (

                                                                <li key={i} className={`page-item ${discountModel.currentPage === n ? 'active' : ''}`} >
                                                                    <a className='page-link' onClick={() => discountModel.changePage(n)}>{n}</a>
                                                                </li>
                                                            ))
                                                        }
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={discountModel.nextPage}>next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="standard-modal" className="modal fade " tabIndex={-1} aria-labelledby="standard-modalLabel" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-full-width">
                    <div className="modal-content">
                        <form onSubmit={discountModel.saveDiscount}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="standard-modalLabel">Add Discount </h4>
                                <button type="button" className="btn-close" id="myModal" data-bs-dismiss="modal" aria-label="Close" onClick={discountModel.cancel} ></button>
                            </div>
                            <div className="modal-body">

                                <div className="row">
                                    <div className="col-md-3 mb-2">
                                        <label htmlFor="">Discount Name</label>
                                        <input type="text" name="discount_name" className="form-control" value={discountModel.discount.discount_name} onChange={discountModel.handleChange} />
                                        {<div className="text-danger">{discountModel.validate.discount_name}</div>}
                                    </div>
                                    <div className="col-md-3 mb-2">
                                        <label>Discount Type</label>
                                        <select name="discount_type" className="form-control" value={discountModel.discount.discount_type} onChange={discountModel.handleChange}>
                                            <option selected > Select</option>
                                            <option value="Product">Product</option>
                                            <option value="Coupon">Coupon</option>
                                            <option value="NewCustomer">New Customer</option>
                                            <option value="Delivery">Delivery</option>
                                        </select>
                                        {<div className="text-danger">{discountModel.validate.discount_type}</div>}
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <label >Coupon code</label>
                                        <input type="text" id="coupon_code" name="coupon_code" className="form-control" value={discountModel.discount.coupon_code} onChange={discountModel.handleChange} />
                                        {<div className="text-danger">{discountModel.validate.coupon_code}</div>}
                                    </div>


                                    <div className="col-md-3 mb-2">
                                        <label >Start Date</label>
                                        <input type="date" id="start_date" name="start_date" className="form-control" value={discountModel.discount.start_date} onChange={discountModel.handleChange} />

                                        {<div className="text-danger">{discountModel.validate.start_date}</div>}
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <label >End Date</label>
                                        <input type="date" id="end_date" name="end_date" className="form-control" value={discountModel.discount.end_date} onChange={discountModel.handleChange} />
                                        {<div className="text-danger">{discountModel.validate.end_date}</div>}
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <label >Max Order Value</label>
                                        <input type="number" id="max_order_value" name="max_order_value" className="form-control" value={discountModel.discount.max_order_value} onChange={discountModel.handleChange} />
                                        {<div className="text-danger">{discountModel.validate.max_order_value_validate}</div>}
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <label >Max Disc Amount</label>
                                        <input type="number" id="max_discount_amount" name="max_discount_amount" className="form-control" value={discountModel.discount.max_discount_amount} onChange={discountModel.handleChange} />
                                        {<div className="text-danger">{discountModel.validate.max_discount_amount}</div>}
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <label>Discount Percentage</label>
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1"><b>%</b></span>
                                            </div>
                                            <input type="number" className="form-control" id="discount_percentage" placeholder="0.00" name="discount_percentage" value={discountModel.discount.discount_percentage} onChange={discountModel.handleChange} />
                                            {<div className="text-danger">{discountModel.validate.discount_percentage}</div>}
                                        </div>
                                    </div>


                                    <div className="col-md-3 mb-2">
                                        <label >No Of Applicables</label>
                                        <input type="number" id="no_of_applicables" name="no_of_applicables" className="form-control" value={discountModel.discount.no_of_applicables} onChange={discountModel.handleChange} />
                                        {<div className="text-danger">{discountModel.validate.no_of_applicables}</div>}
                                    </div>

                                    <div className="col-md-3 mb-2">
                                        <label>Products</label>
                                        <AsyncSelect
                                            isClearable
                                            isSearchable
                                            cacheOptions
                                            defaultOptions={discountModel.lstProductsDrp}
                                            onChange={discountModel.handleProductsChange}
                                            value={discountModel.lstProductsDrp.length > 0 ? (discountModel.lstProductsDrp.find((option: any) => option.value == discountModel.product.product_id) || null) : null}
                                            loadOptions={discountModel.loadProducts}
                                        />
                                        {/* {(categoryViewModel.variantSubmit==true && categoryViewModel.categoryVariant.variant_group_id ==0) ? (<div className="text-danger">Required </div>) : ''} */}
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <br></br>
                                        <button type="button" className="btn btn-primary" onClick={() => discountModel.addProducts()}>Add </button>
                                        {/* <button  className="btn btn-primary" onClick={() => varientViewModel.addVariant()} >Add </button> */}
                                    </div>

                                    <div className="mb-2 col-md-12 mt-3">
                                        <div className="table-responsive-sm">
                                            <table className="table table-centered mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Product Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        discountModel.productFeilds.map((item, index) => (
                                                            item.title &&
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.title}</td>
                                                                <td>

                                                                    <i className="mx-2 uil uil-trash-alt" onClick={() => discountModel.removeProductFeild(item)}></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DiscountView;