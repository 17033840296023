import AsyncSelect from "react-select/async";
import { VarientViewModel } from "./VarientViewModel";

const VarientView: React.FC = () => {

    const varientViewModel = VarientViewModel();


    return (


        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">EIT </a></li>
                                        {/* <li className="breadcrumb-item"><a href="javascript: void(0);">ECommerce </a></li> */}
                                        <li className="breadcrumb-item active">Varient</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Varient </h4>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="mb-2 col-md-6">
                                        <div id="products-datatable_filter" className="dataTables_filter">
                                            <label>Search:<input type="search" className="form-control form-control-sm" value={varientViewModel.searchTerm} onChange={varientViewModel.handleSearch} /></label>
                                        </div>
                                    </div>
                                    <div className="mb-2 col-md-6 text-end my-auto">
                                        <a className="btn btn-danger mb-2"
                                        data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={varientViewModel.newVarients}>
                                            <i className="mdi mdi-plus-circle me-2"></i> Add varients
                                        </a>
                                    </div>

                                    <div className="mb-2 col-md-12 mt-3">
                                        <div className="table-responsive-sm">
                                            <table className="table table-centered mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Varient Group</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {varientViewModel.filteredVarient.map((d: any, index: number) => (
                                                        <tr key={d.variant_group_name}>
                                                            <td>{index + 1}</td>
                                                            <td>{d.variant_group_name}</td>
                                                            <td>
                                                                <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => varientViewModel.editVarients(d)}></i>
                                                                {<i className="mx-2 uil uil-trash-alt" onClick={() => varientViewModel.deleteVarients(d.variant_group_id)}></i>}
                                                            </td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>
                                            <nav className="text-right mt-3" >

                                                <ul className='pagination float-end' >
                                                    <li className='page-item'>
                                                        <a className='page-link' onClick={varientViewModel.prePage}>prev</a>
                                                    </li>
                                                    {

                                                        varientViewModel.numbers.map((n, i) =>
                                                        (

                                                            <li key={i} className={`page-item ${varientViewModel.currentPage === n ? 'active' : ''}`} >
                                                                <a className='page-link' onClick={() => varientViewModel.changePage(n)}>{n}</a>
                                                            </li>
                                                        ))
                                                    }
                                                    <li className='page-item'>
                                                        <a className='page-link' onClick={varientViewModel.nextPage}>next</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="standard-modal" className="modal fade" tabIndex={-1} aria-labelledby="standard-modalLabel"
                aria-modal="true" role="dialog" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={varientViewModel.saveVarients}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="standard-modalLabel">Add Varient </h4>
                                <button type="button" className="btn-close" id="myModal" data-bs-dismiss="modal" aria-label="Close" onClick={varientViewModel.cancel}></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">


                                    <div className="col-md-12 mb-2">
                                        <label >Varient Group</label>
                                       
                                        {/* <AsyncSelect
                                            isClearable
                                            isSearchable
                                            cacheOptions
                                            defaultOptions={varientViewModel.lstVariantGroupsDrp}
                                           // onChange={categoryViewModel.handleVariantChange}
                                            value={varientViewModel.lstVariantGroupsDrp.length > 0 ? (varientViewModel.lstVariantGroupsDrp.find((option: any) => option.value == varientViewModel.varient.variant_group_id) || null) : null}
                                            loadOptions={varientViewModel.loadVarients}
                                       />
                                        {(varientViewModel.variantSubmit == true && varientViewModel.varient.variant_group_id== 0) ? (<div className="text-danger">Required </div>) : ''} */}


                                        <select name="variant_group_id" id="variant_group_id" className="form-control" value={varientViewModel.varient.variant_group_id} onChange={(e) => varientViewModel.handleChange(e)}> <option value="" selected aria-readonly>Select</option>
                                            {varientViewModel.lstVarientGroup.data.map((d: any, index: number) => (
                                                <option value={d.variant_group_id}>{d.variant_group_name}</option>))}
                                        </select>
                                        {/* {<div className="text-danger">{varientViewModel.validate.variant_group_name}</div>} */}
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <label>Notes</label>
                                        <textarea name="notes" id="notes" className="form-control" value={varientViewModel.varient.notes} onChange={varientViewModel.handleChange}></textarea>
                                        {<div className="text-danger">{varientViewModel.validate.notes}</div>}
                                    </div>

                                    <div className="col-md-8 mb-2">

                                        <label >Variant Name</label>
                                        <input type="text" id="variant_name" name="variant_name" value={varientViewModel.newvarient.variant_name} className="form-control" onChange={varientViewModel.newVarient} />
                                        {/* {<div className="text-danger">{varientViewModel.newvarient.variant_name}</div>} */}

                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <br></br>
                                        <button type="button" className="btn btn-primary" onClick={() => varientViewModel.addVariant()}>Add </button>
                                        {/* <button  className="btn btn-primary" onClick={() => varientViewModel.addVariant()} >Add </button> */}

                                    </div>
                                    <div className="mb-2 col-md-12 mt-3">
                                        <div className="table-responsive-sm">
                                            <table className="table table-centered mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Varient Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        varientViewModel.varientFeilds.map((item, index) => (
                                                            item.variant_name &&
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.variant_name}</td>
                                                                <td>
                                                                    <i className="uil uil-edit-alt" onClick={() => varientViewModel.editVarientFeild(item)}></i>
                                                                    <i className="mx-2 uil uil-trash-alt" onClick={() => varientViewModel.removeVarientFeild(item)}></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={varientViewModel.cancel} >Close </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    )





}
export default VarientView;