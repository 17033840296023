import { UsersviewModel } from "./UsersviewModel";

const Usersview: React.FC = () => {


    const userViewModel = UsersviewModel();


    return (

        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        {/* <li className="breadcrumb-item"><a href="javascript: void(0);">eCommerce </a></li> */}
                                        <li className="breadcrumb-item active">User </li>
                                    </ol>
                                </div>
                                <h4 className="page-title">User </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                                <label>Search:<input type="search" className="form-control form-control-sm" value={userViewModel.searchTerm} onChange={userViewModel.handleSearch} /></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#user-modal" onClick={userViewModel.addUser}>
                                                <i className="mdi mdi-plus-circle me-2"></i> Add User
                                            </a>
                                        </div>

                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Name </th>
                                                            <th>Email</th>
                                                            <th>Contact</th>
                                                            <th>Address</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {userViewModel.filteredListusers.map((d: any, index: number) => (
                                                            <tr key={d.user_id}>
                                                                <td>{index + 1}</td>
                                                                <td>{d.full_name}</td>
                                                                <td>{d.email}</td>
                                                                <td>{d.contact_no}</td>
                                                                <td>{d.address}</td>
                                                                <td>
                                                                    <i className="uil uil-pen mx-2" data-bs-toggle="modal" data-bs-target="#user-modal" onClick={() => userViewModel.editUser(d)}></i>
                                                                    <i className="uil uil-trash-alt" onClick={() => userViewModel.deleteUser(d.user_id)}></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <nav className="text-right mt-3" >

                                                    <ul className='pagination float-end' >
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={userViewModel.prePage}>prev</a>
                                                        </li>
                                                        {

                                                            userViewModel.numbers.map((n, i) =>
                                                            (

                                                                <li key={i} className={`page-item ${userViewModel.currentPage === n ? 'active' : ''}`} >
                                                                    <a className='page-link' onClick={() => userViewModel.changePage(n)}>{n}</a>
                                                                </li>
                                                            ))
                                                        }
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={userViewModel.nextPage}>next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div id="user-modal" className="modal fade" aria-labelledby="standard-modalLabel" data-bs-backdrop="static" aria-modal="true" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={userViewModel.saveUser}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="user-modalLabel">Add User</h4>
                                <button type="button" className="btn-close" id="myModal" data-bs-dismiss="modal" aria-label="Close" onClick={userViewModel.cancel}></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <label >Names </label>
                                        <input type="text" name="full_name" value={userViewModel.users.full_name} className="form-control" placeholder="Name" onChange={userViewModel.handleChange} />
                                        {<div className="text-danger">{userViewModel.validate.full_name}</div>}
                                    </div>

                                    {/* <div className="col-md-4 mb-2">
                                        <label >Code</label>
                                        <input type="number" name="code" value={users.code} className="form-control" placeholder="Code" onChange={handleChange} />
                                        {<div className="text-danger">{validate.code}</div>}
                                    </div> */}

                                    <div className="col-md-12 mb-2">
                                        <label >Contact no</label>
                                        <input type="number" name="contact_no" value={userViewModel.users.contact_no} className="form-control" placeholder="Contact no" onChange={userViewModel.handleChange} />
                                        {<div className="text-danger">{userViewModel.validate.full_name}</div>}
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <label >Email</label>
                                        <input type="text" name="email" value={userViewModel.users.email} className="form-control" placeholder="Email" onChange={userViewModel.handleChange} />
                                        {<div className="text-danger">{userViewModel.validate.email}</div>}
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <label >Password</label>
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Password" name="password" value={userViewModel.users.password} onChange={userViewModel.handleChange} />
                                            <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon2" onClick={userViewModel.generatePassword}>Generate</span>
                                            </div>
                                        </div>
                                        {<div className="text-danger">{userViewModel.validate.password}</div>}
                                    </div>

                                    <div className="col-md-6 mb-2">
                                        <label >Role</label>
                                        <select name="role" value={userViewModel.users.role} className="form-control" onChange={userViewModel.handleChange}>
                                            <option selected disabled>Select</option>
                                            <option value="Admin">Admin</option>
                                            <option value="User">User</option>
                                        </select> 
                                        {<div className="text-danger">{userViewModel.validate.role}</div>}
                                    </div>


                                    <div className="col-md-6 mb-2">
                                        <label >Report To</label>
                                        <select name="report_to" value={userViewModel.users.report_to} className="form-control" onChange={userViewModel.handleChange}>
                                            {/* <option selected disabled>Select</option> */}
                                            <option value="1">User 1</option>
                                            <option value="2">User 2</option>
                                        </select> 
                                        {<div className="text-danger">{userViewModel.validate.report_to}</div>}
                                    </div>


                                    <div className="col-md-12">
                                        <label >Address</label>
                                        <textarea name="address" value={userViewModel.users.address} cols={30} rows={3} className="form-control" placeholder="Address" onChange={userViewModel.handleChange}></textarea>
                                        {<div className="text-danger">{userViewModel.validate.address}</div>}
                                    </div>

                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={userViewModel.cancel}>Close </button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    )

}
export default Usersview;