import { RaisePickupsModel } from "./RaisePickupsModel"

const RaisePickups:React.FC=()=>
{
const viewModel=RaisePickupsModel();


 return(<>
 
 <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">RisePickups</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">RisePickups </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                <div className="row">

                                <div className="col-3">
                                                <label className="form-label">Package Count</label>
                                                <input className="form-control" id="package_count" readOnly type="number" value={viewModel.package_count}    />
                                            </div>

                                <div className="col-3">
                                                <label className="form-label">Pickup Date</label>
                                                <input className="form-control" id="pickup_date" type="date" value={viewModel.pickup_date || viewModel.getCurrentDate() } onChange={viewModel.onPickupDateChange} />
                                            </div>


                                            <div className="col-3">
                                                <label className="form-label">Pickup Time</label>
                                                <input className="form-control" id="pickup_time" type="time" value={viewModel.pickup_time} name="_order_date" onChange={viewModel.onPickupTimeChange} />
                                            </div>
                                            <div className="col-3">
                                            <br></br>
                                            <button type="button" className="btn btn-light" onClick={viewModel.onRaisePickupClick}  >Raise Pickup </button>
                                            </div>
                                            </div>


                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
                                </div>
 
 </>)


}
export default RaisePickups