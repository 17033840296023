import React from "react";
import { MerchantViewModel } from "./Merchantmodel";

const MerchantView:React.FC = () =>{
  const merchantModel = MerchantViewModel();
    return(
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">Merchant</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Merchant </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                                <label>Search:<input type="search" className="form-control form-control-sm"
                                                    placeholder="" aria-controls="products-datatable" 
                                                    value={merchantModel.searchTerm} onChange={merchantModel.handleSearch}/></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a href="javascript:void(0);" className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#standard-modal">
                                                <i className="mdi mdi-plus-circle me-2"></i> Add Merchants
                                            </a>
                                        </div>
                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Merchant Legal Name</th>
                                                            <th>Business Category</th>
                                                            <th>Website</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                      {
                                                        merchantModel.filterMerchants.map((item:any,index:number)=>(
                                                          <tr> 
                                                            <td>{index+1}</td>
                                                            <td>{item.merchant_legal_name}</td>
                                                            <td>{item.business_category}</td>
                                                            <td>{item.website}</td>
                                                            <td>{item.status}</td>
                                                            <td>
                                                              <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => merchantModel.editMerchant(item)}></i>
                                                              {<i className="mx-2 uil uil-trash-alt" onClick={() => merchantModel.deleteMerchant(item.merchant_id)}></i>}
                                                            </td>
                                                        </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="standard-modal" className="modal fade " tabIndex={-1} aria-labelledby="standard-modalLabel" aria-modal="true" role="dialog">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                   <form onSubmit={merchantModel.saveMerchant}>
                    <div className="modal-header">
                        <h4 className="modal-title" id="standard-modalLabel">Add Merchant </h4>
                        <button type="button" className="btn-close" id="myModal" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                          <div className="col-md-3 mb-2">
                                <label>Merchant Legal Name</label>
                               <input type="text" name="merchant_legal_name" id="merchant_legal_name" className="form-control" value={merchantModel.merchant.merchant_legal_name} onChange={merchantModel.handleChange}/>
                               {<div className="text-danger">{merchantModel.validate.merchant_legal_name}</div>}
                          </div>  

                          <div className="col-md-3 mb-2">
                            <label >Reg No</label>
                            <input type="text" name="reg_no" id="reg_no" className="form-control" value={merchantModel.merchant.reg_no} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.reg_no}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Business Category</label>
                            <input type="text" name="business_category" id="business_category" className="form-control" value={merchantModel.merchant.business_category} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.business_category}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Currency</label>
                            <input type="text" id="currency" name="currency" className="form-control" value={merchantModel.merchant.currency} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.currency}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Merchant Name</label>
                            <input type="text" id="merchant_name" name="merchant_name" className="form-control" value={merchantModel.merchant.merchant_name} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.merchant_name}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Website</label>
                            <input type="text" id="website" name="website" className="form-control" value={merchantModel.merchant.website} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.website}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Login Password</label>
                            <input type="text" id="login_password" name="login_password" className="form-control" value={merchantModel.merchant.login_password} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.login_password}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Address Name</label>
                            <input type="text" id="address_name" name="address_name" className="form-control" value={merchantModel.merchant.address_name} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.address_name}</div>}
                          </div> 


                          <div className="col-md-3 mb-2">
                            <label >Phone Number</label>
                            <input type="text" id="phone_number" name="phone_number" className="form-control" value={merchantModel.merchant.phone_number} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.phone_number}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Alternate Phone Number</label>
                            <input type="text" id="alternate_phone_number" name="alternate_phone_number" className="form-control" value={merchantModel.merchant.alternate_phone_number} onChange={merchantModel.handleChange}/>
                            
                          </div> 
                          
                          <div className="col-md-3 mb-2">
                            <label >Pincode</label>
                            <input type="text" id="pincode" name="pincode" className="form-control" value={merchantModel.merchant.pincode} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.pincode}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >State</label>
                            <select name="state_id" id="state_id" className="form-control" value={merchantModel.merchant.state_id} onChange={merchantModel.handleChange}>
                                {/* <option selected disabled> Select</option> */}
                                {
                                  merchantModel.lstStates &&  merchantModel.lstStates.map((item)=>
                                    (<option value={item.state_id}>{item.state_name}</option>)
                                  )
                                }
                                
                            </select>
                            {<div className="text-danger">{merchantModel.validate.state}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >City</label>
                            <input type="text" id="city" name="city" className="form-control" value={merchantModel.merchant.city} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.city}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >House No Bulding Name</label>
                            <input type="text" id="house_no_bulding_name" name="house_no_bulding_name" className="form-control" value={merchantModel.merchant.house_no_bulding_name} onChange={merchantModel.handleChange}/>

                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Road Area Colony</label>
                            <input type="text" id="road_area_colony" name="road_area_colony" className="form-control" value={merchantModel.merchant.road_area_colony} onChange={merchantModel.handleChange}/>
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Email Id</label>
                            <input type="text" id="email_id" name="email_id" className="form-control" value={merchantModel.merchant.email_id} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.email_id}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Fax No</label>
                            <input type="text" id="fax_no" name="fax_no" className="form-control" value={merchantModel.merchant.fax_no} onChange={merchantModel.handleChange}/>
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Bank Name</label>
                            <input type="text" id="bank_name" name="bank_name" className="form-control" value={merchantModel.merchant.bank_name} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.bank_name}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Bank Branch</label>
                            <input type="text" id="bank_branch" name="bank_branch" className="form-control" value={merchantModel.merchant.bank_branch} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.bank_branch}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >IFSC Code</label>
                            <input type="text" id="ifsc_code" name="ifsc_code" className="form-control" value={merchantModel.merchant.ifsc_code} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.ifsc_code}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Bank Account</label>
                            <input type="text" id="bank_account" name="bank_account" className="form-control" value={merchantModel.merchant.bank_account} onChange={merchantModel.handleChange}/>
                            {<div className="text-danger">{merchantModel.validate.bank_account}</div>}
                          </div> 

                          <div className="col-md-3 mb-2">
                            <label >Verified By</label>
                            <select name="verified_by" id="verified_by" className="form-control" value={merchantModel.merchant.verified_by} onChange={merchantModel.handleChange}>
                                <option selected disabled> Select</option>
                                <option value="1">User 1</option>
                                <option value="1">User 2</option>
                            </select>
                          </div> 


                          <div className="col-md-3 mb-2">
                            <label >Status</label>
                            <select name="status" className="form-control" value={merchantModel.merchant.status} onChange={merchantModel.handleChange}>
                                <option selected disabled> Select</option>
                                <option value="1">Verfied</option>
                                <option value="1">Blocked</option>
                            </select>
                          </div> 
                        </div>                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal"  onClick={merchantModel.cancel}>Close </button>
                        <button type="submit" className="btn btn-primary">Save </button>
                    </div>
                    </form>
                </div>
            </div>
        </div>
        </div>
    )
}

export default MerchantView;