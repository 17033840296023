import React, { Component } from "react";
import ReactDOM from "react-dom";
import async from "react-select/dist/declarations/src/async/index";
import * as CryptoJS from 'crypto-js';

export function SchoolRepository() {

    // const apiUrl = 'https://educationtodayindia.com/Schoolwebapi/api/';
    // const apiUrl='https://educationtodayindia.com/educationtodayindiaschoolwebapi/api/';
    // onst imagePath="https://educationtodayindia.com/educationtodayindiaschoolwebapi/public/images/"
    const apiUrl='https://api.educationtodayindia.com/educationtodayindiaschoolwebapi/api/';
    const imagePath = "https://api.educationtodayindia.com/educationtodayindiaschoolwebapi/public/images/"


    const getCities = async () => {

        debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getcities');
            const data: any = await response.json();
            $("#loaderParent").hide();

            return data;


        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const saveCity = async (city: any) => {
        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'savecity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(city)
            })
            const responseData: any = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }
    const deleteCitys = async (city: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletecity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(city),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const getCitysDistricts = async () => {
        debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getdistricts');
            const data: any = await response.json();

            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const adminLogin = async (loginDetails: any) => {
        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'adminlogin', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginDetails),
            })
            debugger;
            const data = await response.json();
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const getclusters = async () => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getclusters');
            const data: any = await response.json();
            $("#loaderParent").hide();
            return data;

        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const getCitys = async () => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getcities');
            const data: any = await response.json();
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const saveCluster = async (cluster: any) => {

        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'savecluster', {

                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(cluster)
            })
            const responseData: any = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const deleteclusters = async (cluster: any) => {

        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletecluster', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(cluster),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const getStates = async () => {
        $("#loaderParent").show();
        try { 
            
            const response = await fetch(apiUrl + 'getstates'); 
           // const response = await fetch(apiUrl + 'getstates');
            const data = await response.json();
            $("#loaderParent").hide();
            return data;

        } catch (e) {
            $("#loaderParent").hide();
        }
    }
    const encryptData = (data: string, key: string) => {
        // Make sure the key is 32 bytes (256 bits)
        if (key.length !== 32) {
            throw new Error('AES-256-CBC key must be 32 bytes long');
        }

        // Generate a random IV (Initialization Vector)
        const iv = CryptoJS.lib.WordArray.random(16);

        // Encrypt using AES-256-CBC
        const cipherText = CryptoJS.AES.encrypt(data, CryptoJS.enc.Utf8.parse(key), {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }).toString();

        return { cipherText, iv: CryptoJS.enc.Base64.stringify(iv) };
    };
    // const encryptData = (data: string, key: string) => {
    //     // Generate a random IV
    //     const iv = CryptoJS.lib.WordArray.random(16);

    //     // Encrypt data
    //     const cipherText = CryptoJS.AES.encrypt(data, key, { iv }).toString();

    //     // Return both the ciphertext and the IV
    //     return { cipherText, iv: CryptoJS.enc.Base64.stringify(iv) };
    //   }

    const saveState = async (state: any) => {
            debugger;
        
            try {
                const response = await fetch(apiUrl + 'savestate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body:JSON.stringify( state)
                });
                const responseData = await response.json();
                $("#loaderParent").hide();
                return responseData;
            } catch (e) {
                $("#loaderParent").hide();
            }
        }
    
    // const saveState = async (state: any) => {
    //     debugger;
    //     const secretKey = 'Hg5kP0l9Zx6Cv7Bn8Mj1Qa2Ws3Ed4Rf5';
    //     // const iv = '5739363859398995';
    //     //   const iv = CryptoJS.lib.WordArray.random(16);
    //     const { cipherText, iv } = encryptData(JSON.stringify(state), secretKey);

    //     // const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(state), secretKey).toString();
    //     //  const cipherText = CryptoJS.AES.encrypt(JSON.stringify(state), secretKey, { iv }).toString();

    //     let data = { encryptedData: cipherText };
    //     $("#loaderParent").show();
    //     try {
    //         const response = await fetch(apiUrl + 'savestate', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'iv': iv
    //             },
    //             body: cipherText
    //         });
    //         const responseData = await response.json();
    //         $("#loaderParent").hide();
    //         return responseData;
    //     } catch (e) {
    //         $("#loaderParent").hide();
    //     }
    // }

    const deleteState = async (state: any) => {
        debugger;

        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletestate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(state),
            })

            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData
        } catch (e) {
            $("#loaderParent").hide();
        }

    }

    const getDistricts = async () => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getdistricts');
            const data = await response.json();
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const getDistrictStates = async () => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getstates');
            const data = await response.json();
            $("#loaderParent").hide();
            return data;

        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const saveDistricts = async (district: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'savedistrict', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(district)
            })
            const responseData: any = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }


    }


    const deleteDistrict = async (district: any) => {

        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletedistrict', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(district),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const getResources = async (resource: any) => {
        debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'viewschoolresources', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(resource)
            })
            debugger;
            const data = await response.json();
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const getSchool = async () => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getschools');
            const data = await response.json();
            $("#loaderParent").hide();
            return data;

        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const saveResource = async (resource: any) => {

        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'saveSchoolresources', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(resource),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }

    }


    const uploadImage = async (formData: any) => {
        debugger;

        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'upload', {
                method: 'POST',
                body: formData,
            });
            debugger;
            if (response.ok) {
                debugger;
                const responseData = await response.json();
                $("#loaderParent").hide();
                return responseData;


            } else {
                console.error('Failed to upload image. Server returned:', response.status);
            }
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const deleteUser = async (userData: any) => {

        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deleteloginuser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const updateUser = async (userdetails: any) => {
        debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'loginuserchangepassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userdetails)
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }



    const Validateuser = async (userdetails: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'userdetailsbyemail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userdetails)
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const saveAnnouncement = async (Announcemnts: any) => {

        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'saveannouncements', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Announcemnts),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }

    }


    const getAnnouncements = async (Announcemnts: any) => {
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'getannouncements', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Announcemnts),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const deleteAnnouncement = async (Announcemnts: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deleteannouncements', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(Announcemnts),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const getSchools = async () => {

        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getschools');
            const data = await response.json();
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const getschoolStates = async () => {

        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getstates');
            const data = await response.json();
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const getDistrictByState = async (schoolInfo: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getdistrictsbystate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(schoolInfo)
            })
            const data = await response.json();
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }

    }

    const getCityByDistrict = async (addressInfo: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getcitiesbydistrict', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(addressInfo)
            })
            const data = await response.json();
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const getClusterByCity = async (addressInfo: any) => {
        debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getclustersbycity', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(addressInfo)
            })
            debugger;
            const data = await response.json();
            $("#loaderParent").hide();
            return data;

        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const saveSchool = async (schoolInfo: any, addressInfo: any, resourceInfo: any, facilityInfo: any) => {
        debugger;
        $("#loaderParent").show();
        try {
            var concatenatedInfoString = JSON.stringify({ ...schoolInfo, ...addressInfo, ...resourceInfo, ...facilityInfo });
            const response = await fetch(apiUrl + 'saveschool', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: concatenatedInfoString
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }

    }

    const saveAboutus = async (aboutus: any) => {

        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'saveschoolaboutus', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(aboutus)
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;

        } catch (e) {
            $("#loaderParent").hide();
        }

    }


    const deleteSchool = async (schoolInfo: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deleteschool', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(schoolInfo),
            })

            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const getLoginUsers = async () => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getloginuser');
            const data = await response.json();
            $("#loaderParent").hide();
            return data;

        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const saveUser = async (users: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'saveloginuser ', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(users),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const deleteloginUser = async (users: any) => {
        debugger;
        $("#loaderParent").show();
        try {
            //  console.log(apiUrl +'deleteloginuser')
            const response = await fetch(apiUrl + 'deleteloginuser', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(users),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }

    }


    const getschoolApprovelist = async () => {

        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getschools');
            const data = await response.json();
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }








    const schoolVerifiedStatusUpdate = async (schoolApprovalData: any) => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'schoolverifiedstatusupdate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(schoolApprovalData),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        }
        catch (e) {
            $("#loaderParent").hide();
        }
    }



    const schoolApprovelStatusUpdate = async (schoolApprovalData: any) => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'schoolapprovelstatusupdate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(schoolApprovalData),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const getschoolApprove = async (schoolApprove: any) => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'getschoolbyschoolid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ school_id: schoolApprove }),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }




    return { imagePath, schoolVerifiedStatusUpdate, schoolApprovelStatusUpdate, adminLogin, getCities, saveCity, deleteCitys, getclusters, getCitys, saveCluster, deleteclusters, getStates, saveState, deleteState, getDistricts, getDistrictStates, saveDistricts, deleteDistrict, getResources, getSchool, saveResource, uploadImage, deleteUser, updateUser, Validateuser, saveAnnouncement, getAnnouncements, deleteAnnouncement, getSchools, getschoolStates, getschoolApprove, getDistrictByState, getCityByDistrict, getClusterByCity, saveSchool, saveAboutus, deleteSchool, getLoginUsers, saveUser, deleteloginUser, getCitysDistricts };

}