import { useEffect, useState } from "react";
import { EcommerceRepository } from "../../repository/EcommerceRepository";
import Swal from "sweetalert2";
import { useHistory, useLocation } from "react-router-dom";
import { CommonSolutions } from "../../CommonFunctionality/CommonSolutions";
import { AutoAccessorPropertyDeclaration } from "typescript";
interface OptionType {
    label: string;
    value: string;
    children: [];
}

export function ListingViewModel(sku_id: any, product_detail_id: any, product_id: any) {
    const commonFunctionality = CommonSolutions();
    const { search } = useLocation();
    const history = useHistory();



    // else if(sku_id == ''){

    // }
    // const product_sku_id = sku_id;
    const ecommerceRepository = EcommerceRepository();
    const [showCheckmark, setCheckmark] = useState({
        listingInformation: 'false',
        Details: 'false',
        variants: 'false',
        keywords: 'false'
    })
    const [product_Ids, setProductId] = useState(0);
    const imagePath = ecommerceRepository.imagePath;
    const [listingDetail, setListingDetail] = useState({
        product_id: 0,
        product_details_id: 0,
        type: '',

    })

    // product Save
    const [product, setListProduct] = useState({
        product_id: 0,
        category_id: 0,
        brand_id: 0,
        merchant_id: 0,
        hsn_id: 0,
        hsn_code: '',
        cess: '',
        manufacturer: '',
        packer: '',
        ProductType: '',
        updated_date: ''
    })

    const [productValidate, setProductValidate] = useState({
        product_id: 0,
        product_name: '',
        category_id: 0,
        category_name: '',
        brand_id: 0,
        brand_name: '',
        merchant_id: 0,
        merchant_name: '',
        hsn_id: 0,
        hsn_code: '',
        cess: '',
        manufacturer: '',
        packer: '',
        ProductType: '',
        updated_date: '',

    })


    let product_sku_id = '';
    let product_details_id = '';
    let productId = '';
    if (sku_id != '') {
        product_sku_id = sku_id;
        product_details_id = product_detail_id;
        productId = product_id;

    }

    const saveProduct = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const validateProduct = { ...productValidate };

        if (!(product.category_id > 0)) {
            validateProduct.category_name = "Category required!";
        }
        else {
            validateProduct.category_name = '';

        }
        if (!(product.brand_id > 0)) {
            validateProduct.brand_name = "Brand required!";
        }
        else {
            validateProduct.brand_name = '';

        }
        if (!(product.hsn_code.trim())) {
            validateProduct.hsn_code = "HSN required!";
        }
        else {
            validateProduct.hsn_code = '';

        }


        // if (!(product.merchant_id > 0)) {
        //     validateProduct.merchant_name = "Merchant required!";
        // }
        if (Object.values(validateProduct).every((error) => !error)) {



            const responseData: any = await ecommerceRepository.saveProduct(product);

            if (responseData[0].db_result > 0) {





                setListingDetail(prevListingDetail => ({
                    ...prevListingDetail,
                    product_id: responseData[0].product_id ? responseData[0].product_id : '',
                }));

                Swal.fire("Record saved successfully", "Record saved successfully", "success");

            }


        }
        setProductValidate(validateProduct)
    }

    const cancelProduct = () => {


        setListProduct({
            product_id: 0,
            category_id: 0,
            brand_id: 0,
            merchant_id: 0,
            hsn_id: 0,
            hsn_code: '',
            cess: '',
            manufacturer: '',
            packer: '',
            ProductType: '',
            updated_date: ''
        });

        setProductValidate({
            product_id: 0,
            product_name: '',
            category_id: 0,
            category_name: '',
            brand_id: 0,
            brand_name: '',
            merchant_id: 0,
            merchant_name: '',
            hsn_id: 0,
            hsn_code: '',
            cess: '',
            manufacturer: '',
            packer: '',
            ProductType: '',
            updated_date: ''
        });
    }

    // product details
    const [productDetails, setProductDetails] = useState({
        product_details_id: 0,
        product_id: 0,
        title: '',
        sku_id: '',
        status: '',
        mrp: 0,
        selling_price: 0,
        dispatched_days: 0,
        stock: 0,
        notify_qty: 0,
        height: 0,
        length: 0,
        bradth: 0,
        weight: 0,
        pack_qty: 0,
        local_delivery_charges: 0,
        zonal_delivery_charges: 0,
        national_delivery_charges: 0,
        notes: '',
        updated_date: '',
        Product: [] as any,
        ProductFeatureFeilds: [] as any,
        ProductKeywords: [] as any,
        ProductResources: [] as any,
        ProductVariants: [] as any
    })

    const [productDetailsValidate, setProductDetailsValidate] = useState({
        product_details_id: 0,
        product_id: 0,
        title: '',
        sku_id: '',
        status: '',
        mrp: 0,
        mrp_validate: '',
        selling_price: 0,
        selling_price_validate: '',
        dispatched_days: 0,
        dispatched_days_validate: '',
        stock: 0,
        stock_validate: '',
        notify_qty: 0,
        notify_qty_validate: '',
        height: 0,
        height_validate: '',
        length: 0,
        length_validate: '',
        bradth: 0,
        bradth_validate: '',
        weight: 0,
        weight_validate: '',
        pack_qty: 0,
        pack_qty_validate: '',
        local_delivery_charges: 0,
        local_delivery_charges_validate: '',
        zonal_delivery_charges: 0,
        zonal_delivery_charges_validate: '',
        national_delivery_charges: 0,
        national_delivery_charges_validate: '',
        notes: '',

        updated_date: ''
    })



    const [productFeatureDetailsValidate, setproductFeatureDetailsValidate] = useState({

        product_feild_title: '',

    })
    const [validationError, serValidationError] = useState('');

    const [resourceSubmit, setResourceSubmit] = useState(false);
    const saveProductDetails = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;

        let validationErrorDetails = '';
        e.preventDefault();


        setResourceSubmit(false);

        const validateProductdetails = { ...productDetailsValidate };


        if (!productDetails.sku_id.trim()) {
            // validateProductdetails.sku_id = "Required!";

            validationErrorDetails = validationErrorDetails + '<br/>' + 'SKU Required ';
        } else {
            validateProductdetails.sku_id = "";
        }

        if (!productDetails.title.trim()) {
            // validateProductdetails.title = "Required!";
            validationErrorDetails = validationErrorDetails + '<br/>' + 'Title Required ';
        } else {
            validateProductdetails.title = "";
        }
        if (!productDetails.status.trim()) {
            // validateProductdetails.status = "Required!";
            validationErrorDetails = validationErrorDetails + '<br/>' + 'Status Required ';
        } else {
            validateProductdetails.status = "";
        }

        if (!(productDetails.mrp > 0)) {
            // validateProductdetails.mrp_validate = "Required!";
            validationErrorDetails = validationErrorDetails + '<br/>' + 'mrp Required ';
        } else {
            validateProductdetails.mrp_validate = "";
        }
        if (!(productDetails.dispatched_days > 0)) {
            // validateProductdetails.dispatched_days_validate = "Required!";
            validationErrorDetails = validationErrorDetails + '<br/>' + 'Dispatched days Required ';
        } else {
            validateProductdetails.dispatched_days_validate = "";
        }

        if (!(productDetails.stock > 0)) {
            // validateProductdetails.stock_validate = "Required!"; 
            validationErrorDetails = validationErrorDetails + '<br/>' + 'Stock Required ';

        } else {

            validateProductdetails.stock_validate = "";
        }

        if (!(productDetails.height > 0)) {
            //validateProductdetails.height_validate = "Required!"; 
            validationErrorDetails = validationErrorDetails + '<br/>' + 'Height Required ';
        } else {

            validateProductdetails.height_validate = "";
        }

        if (!(productDetails.weight > 0)) {
            // validateProductdetails.weight_validate = "Required!";
            validationErrorDetails = validationErrorDetails + '<br/>' + 'Weight Required ';
        }
        else {
            validateProductdetails.weight_validate = "";

        }
        if (!(productDetails.length > 0)) {
            // validateProductdetails.length_validate = "Required!"; 
            validationErrorDetails = validationErrorDetails + '<br/>' + 'Length Required ';
        }
        else {

            validateProductdetails.length_validate = "";
        }

        if (!(productDetails.bradth > 0)) {
            // validateProductdetails.bradth_validate = "Required";
            validationErrorDetails = validationErrorDetails + '<br/>' + 'Bradth Required ';
        }
        else {
            validateProductdetails.bradth_validate = '';
        }

        if (!(productDetails.local_delivery_charges > 0)) {
            // validateProductdetails.local_delivery_charges_validate = "Required";
            validationErrorDetails = validationErrorDetails + '<br/>' + 'Local Delivery Charges Required ';
        }
        else {

            validateProductdetails.local_delivery_charges_validate = "";
        }
        if (!(productDetails.zonal_delivery_charges > 0)) {
            //validateProductdetails.zonal_delivery_charges_validate = "Required"; 

            validationErrorDetails = validationErrorDetails + '<br/>' + 'Zonal Delivery Charges Required ';
        } else {
            validateProductdetails.zonal_delivery_charges_validate = "";

        }
        if (!(productDetails.national_delivery_charges > 0)) {
            // validateProductdetails.national_delivery_charges_validate = "Required"; 
            validationErrorDetails = validationErrorDetails + '<br/>' + 'National Delivery Charges Required ';
        } else {
            validateProductdetails.national_delivery_charges_validate = "";

        }

        setProductDetailsValidate(validateProductdetails)
        //    alert(JSON.stringify( lstResource));
        if (lstResource.length > 1 && lstResource[0].resource_name == '') {
            lstResource.splice(0, 1);
            // lstProductResource[0].product_details_id == listingDetail.product_details_id;

        }



        if (lstResource.length == 0 || lstResource[0].resource_name == '') {
            validationErrorDetails = validationErrorDetails + '<br/>' + 'Image Required ';

        }
        if (validationErrorDetails != '') {
            Swal.fire("Error", validationErrorDetails, "error");

        }
        if (Object.values(validateProductdetails).every((error) => !error)) {

            if (productDetails.product_id > 0) {
                productDetails.product_id = productDetails.product_id
            } else {
                productDetails.product_id = listingDetail.product_id;
            }
            //   productDetails.status = ""
            if (productDetails.Product != '') {
                productDetails.Product = []

            }

            if (productDetails.ProductFeatureFeilds != '') {
                productDetails.ProductFeatureFeilds = []
            }
            if (productDetails.ProductKeywords != '') {
                productDetails.ProductKeywords = []
            }
            if (productDetails.ProductResources != '') {
                productDetails.ProductResources = []

            }
            if (productDetails.ProductVariants != '') {
                productDetails.ProductVariants = []
            }
            if (lstResource.length > 0) {

                const responseData: any = await ecommerceRepository.saveProductDetails(productDetails);

                if (responseData[0].db_result > 0) {


                    debugger;
                    for (let i = 0; i < lstProductResource.length; i++) {
                        lstProductResource[i].product_details_id = responseData[0].product_details_id;
                    }
                    uploadImage();
                    const queryParams = new URLSearchParams(search);

                    queryParams.set('id', ((responseData[0].product_details_id) > 0 ? responseData[0].product_details_id.toString() : (productDetails.product_details_id.toString())));
                    queryParams.set('title', productDetails.title);
                    queryParams.set('skuid', productDetails.sku_id);
                    queryParams.set('product_id', ((listingDetail.product_id) > 0 ? listingDetail.product_id.toString() : (productDetails.product_id.toString())));
                    history.push(`?${queryParams.toString()}`);

                    listingDetail.product_details_id = responseData[0].product_details_id;
                    setListingDetail(listingDetail);




                    if (lstVariants.length > 1) {

                        selectVariant(productDetails.sku_id, '');
                    }
                    Swal.fire("Record saved successfully", "Record saved successfully", "success");

                }
            }

            else {
                setResourceSubmit(true);
                //  if (lstResource.length == 0 || lstResource[0].resource_name == '') {
                //     validationErrorDetails = validationErrorDetails + '<br/>' + 'Image Required ';

                // }
                //  Swal.fire("Error", validationErrorDetails, "error");
            }

        } else {
            setProductDetailsValidate(validateProductdetails)
        }

        // }
        // else {
        //     setResourceSubmit(true);
        //   //  if (lstResource.length == 0 || lstResource[0].resource_name == '') {
        //    //     validationErrorDetails = validationErrorDetails + '<br/>' + 'Image Required ';

        //    // }
        //   //  Swal.fire("Error", validationErrorDetails, "error");
        // }
    }
    const statusChange = (e: any) => {
        debugger;
        let productDetailsInfo = { ...productDetails };
        productDetails.status = e.target.value;


        setProductDetails(productDetails);
    }

    const typeChange = (e: any) => {
        debugger;
        let productDetailsInfo = { ...product };
        product.ProductType = e.target.value;


        setListProduct(product);
    }


    const handleProductDetailsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {

            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setProductDetails((prev) => ({ ...prev, [name]: value }));
        } else {

            const { name, value } = e.target as HTMLSelectElement;
            setProductDetails((prev) => ({ ...prev, [name]: value }));
        }


    }

    const cancelProductDetails = () => {

        setProductDetails({
            product_details_id: 0,
            product_id: 0,
            title: '',
            sku_id: '',
            status: '',
            mrp: 0,
            selling_price: 0,
            dispatched_days: 0,
            stock: 0,
            notify_qty: 0,
            height: 0,
            length: 0,
            bradth: 0,
            weight: 0,
            pack_qty: 0,
            local_delivery_charges: 0,
            zonal_delivery_charges: 0,
            national_delivery_charges: 0,
            notes: '',
            updated_date: '',
            Product: '',
            ProductFeatureFeilds: '',
            ProductKeywords: '',
            ProductResources: '',
            ProductVariants: '',
        })

        setProductDetailsValidate({
            product_details_id: 0,
            product_id: 0,
            title: '',
            sku_id: '',
            status: '',
            mrp: 0,
            mrp_validate: '',
            selling_price: 0,
            selling_price_validate: '',
            dispatched_days: 0,
            dispatched_days_validate: '',
            stock: 0,
            stock_validate: '',
            notify_qty: 0,
            notify_qty_validate: '',
            height: 0,
            height_validate: '',
            length: 0,
            length_validate: '',
            bradth: 0,
            bradth_validate: '',
            weight: 0,
            weight_validate: '',
            pack_qty: 0,
            pack_qty_validate: '',
            local_delivery_charges: 0,
            local_delivery_charges_validate: '',
            zonal_delivery_charges: 0,
            zonal_delivery_charges_validate: '',
            national_delivery_charges: 0,
            national_delivery_charges_validate: '',
            notes: '',

            updated_date: ''
        })

        // setproductFeatureDetailsValidate({
        //     product_feild_title: '',
        // })
    }

    // Category view
    const [lstCategoryDrp, setCategoryDrp] = useState<OptionType[]>([]);


    const [lstCategory, setCategorys] = useState([{
        category_fields: '',
        category_id: 0,
        category_image: '',
        category_name: '',
        category_variants: '',
        parent_category_id: 0,
        parent_category_name: '',
        updated_date: '',
    }])




    const getCategory = async () => {

        try {

            const lstCategory: any = await ecommerceRepository.getHierarchicalCategories();
            let data = lstCategory.filter((x: any) => x.product_configurable == 1);

            const formattedOptions = formatOptions(data);
            setCategoryDrp(formattedOptions);
        }
        catch (error) {
            setCategorys(
                []
            )
        }
    }

    //merchant view
    const [lstMerchantDrp, setMerchantDrp] = useState<{ label: string; value: string }[] | []>([]);
    const getMerchant = async () => {

        try {

            const data: any = await ecommerceRepository.getMerchant();
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.merchant_name,
                value: item.merchant_id.toString(),
            }));
            setMerchantDrp(options);
        }
        catch (error) {
            setMerchantDrp(
                []
            )
        }
    }

    const merchantChange = (selectedOption: any | null) => {

        setListProduct(prevsetCategory => ({
            ...prevsetCategory,
            merchant_id: selectedOption ? selectedOption.value : '',
        }));
    };

    const loadMerchant = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {

        const filteredOptions = filterMerchant(inputValue);
        callback(filteredOptions);
    };


    const filterMerchant = (inputValue: string) => {

        if (inputValue != '') {
            return lstMerchantDrp.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstMerchantDrp;
        }
    };



    const formatOptions = (data: any[]): OptionType[] => {
        return data.map((item) => ({
            label: item.parent_category_name,
            value: item.parent_category_id.toString(),
            children: item.child_categories
                ? commonFunctionality.stringFormatForJson(item.child_categories).map((child: any) => ({
                    label: child.child_category_name,
                    value: child.child_category_id.toString(),
                }))
                : undefined,
        }));
    };

    const handleCategoryChange = (selectedOption: any | null) => {


        let id = '';
        let name = '';

        for (let i = 0; i < lstCategoryDrp.length; i++) {

            if (lstCategoryDrp[i].children != undefined && lstCategoryDrp[i].children.length > 0) {

                let myArray: any = lstCategoryDrp[i].children;
                for (let j = 0; j < myArray.length; j++) {

                    if (myArray[j].value == selectedOption) {

                        name = lstCategoryDrp[i].label;
                        id = lstCategoryDrp[i].value;
                        break;
                    }
                }
            }
            if (lstCategoryDrp[i].value == selectedOption) {

                name = lstCategoryDrp[i].label;
                id = lstCategoryDrp[i].value;
                break;
            }
        }


        setListProduct(prevsetCategory => ({
            ...prevsetCategory,
            category_id: selectedOption ? selectedOption : '',
        }));


        getCategoryFeilds(selectedOption, '');
        getCategoryVariantGroups(selectedOption, '');
    };

    // brand view
    const [lstBrandDrp, setBrandDrp] = useState<{ label: string; value: string }[] | []>([]);
    const getBrand = async () => {

        try {

            const data: any = await ecommerceRepository.getBrands();
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.brand_name,
                value: item.brand_id.toString(),
            }));
            setBrandDrp(options);
        }
        catch (error) {
            setBrandDrp(
                []
            )
        }
    }

    const brandChange = (selectedOption: any | null) => {

        setListProduct(prevsetCategory => ({
            ...prevsetCategory,
            brand_id: selectedOption ? selectedOption.value : '',
        }));
    };

    const loadBrand = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {

        const filteredOptions = filterBrand(inputValue);
        callback(filteredOptions);
    };

    const filterBrand = (inputValue: string) => {

        if (inputValue != '') {
            return lstBrandDrp.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstBrandDrp;
        }
    };

    const [lstHSNDrp, setHSNDrp] = useState<{ label: string; value: string }[] | []>([]);
    const getHSN = async () => {

        try {

            const data: any = await ecommerceRepository.getHsn();
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.hsn_code,
                value: item.hsn_id.toString(),
            }));
            setHSNDrp(options);
        }
        catch (error) {
            setHSNDrp(
                []
            )
        }
    }

    const hsnChange = (selectedOption: any | null) => {

        setListProduct(prevsetCategory => ({
            ...prevsetCategory,
            hsn_code: selectedOption ? selectedOption.label : '',
        }));
    };


    const filterHSN = (inputValue: string) => {

        if (inputValue != '') {
            return lstHSNDrp.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstHSNDrp;
        }
    };


    const loadHSN = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {

        const filteredOptions = filterHSN(inputValue);
        callback(filteredOptions);
    };







    const handleProductChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {

            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setListProduct((prev) => ({ ...prev, [name]: value }));
        } else {

            const { name, value } = e.target as HTMLSelectElement;
            setListProduct((prev) => ({ ...prev, [name]: value }));
        }
    }





    const [lstCategoryFeilds, setCategoryFeilds] = useState([{
        category_fields_id: 0,
        category_id: 0,
        is_required: 0,
        product_feild_value: '',
        product_feild_title: '',
        validate: '',
        product_details_id: 0
    }])

    const getCategoryFeilds = async (e: any, data1: any) => {

        try {

            const data: any = await ecommerceRepository.getCategoryFeilds({ 'category_id': e });



            if (sku_id != '' && sku_id != undefined && data1 != '') {
                setCategoryFeilds(commonFunctionality.stringFormatForJson(((data1))));
            }
            else {
                setCategoryFeilds(data);
            }
        }
        catch (error) {
            setCategoryFeilds(
                []
            )
        }
    }

    const saveCategoryFeilds = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();
    }

    const saveFeatureDetails = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;

        e.preventDefault();

        // const validateProductFeaturedetails = { ...productFeatureDetailsValidate };
        // if (!productDetails.ProductFeatureFeilds.product_feild_title.trim()) {
        //     validateProductFeaturedetails.product_feild_title = "Required!";
        // } else {
        //     validateProductFeaturedetails.product_feild_title = "";
        // }
        // setproductFeatureDetailsValidate(validateProductFeaturedetails)


        for (let i = 0; i < lstCategoryFeilds.length; i++) {

            if (lstCategoryFeilds[i].is_required == 1 && lstCategoryFeilds[i].product_feild_value == '') {

                lstCategoryFeilds[i].validate = "Required";
            }
            lstCategoryFeilds[i].product_details_id = (+listingDetail.product_details_id)
        }
        setCategoryFeilds([...lstCategoryFeilds]);


        let error: any = lstCategoryFeilds.filter((x: any) => x.is_required == 1 && x.product_feild_value == '');


        if (error.length == 0) {

            productDetails.product_id = listingDetail.product_id;
            const responseData: any = await ecommerceRepository.saveFeatureDetails(lstCategoryFeilds);

            if (responseData[0].db_result > 0) {

                // setListingDetail(prevListingDetail => ({
                //     ...prevListingDetail,
                //     product_details_id: responseData[0].product_id ? responseData[0].product_id : '',
                // }));
                Swal.fire("Details saved successfully", "Details saved successfully", "success");

            }


        }
        // setproductFeatureDetailsValidate(validateProductFeaturedetails)

    }

    const handleFeildsChange = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            let res = [...lstCategoryFeilds];
            for (let i = 0; i < lstCategoryFeilds.length; i++) {
                if (index == lstCategoryFeilds[i].category_fields_id) {

                    res[i].product_feild_value = e.target.value;
                    res[i].validate = "";
                    setCategoryFeilds(res);
                    break;
                }
            }
        }
    }

    const [lstVariantGroups, setVariantGroups] = useState([{
        category_id: 0,
        product_details_id: 0,
        category_variant_id: 0,
        is_required: 0,
        variant_group_id: 0,
        product_variants_title: "",
        product_variant_value: "",
        variants: [],
        validate: ""
    }])

    const getCategoryVariantGroups = async (e: any, variants: any) => {

        try {

            // const data = '';
            let data: any = []
            if (variants == 'new' || variants == '') {

                data = await ecommerceRepository.getCategoryVariantGroups({ 'category_id': e, 'product_details_id': '' });
            }
            else {

                data = await ecommerceRepository.getCategoryVariantGroups({ 'category_id': e, 'product_details_id': variants });
            }


            for (let i = 0; i < data.length; i++) {

                let variants = commonFunctionality.stringFormatForJson(data[i].variants);

                const options: { label: string; value: string }[] = variants.map((item: any) => ({
                    // label: item.product_variants_title,
                    // value: item.product_variants_value,
                    label: item.variant_name,
                    value: item.variant_name,
                }));
                data[i].variants = options;
            }


            // data[0].product_variant_value = 224;

            if (sku_id != '' && sku_id != undefined) {

                // setVariantGroups(commonFunctionality.stringFormatForJson( ((variants.replace(/\n/g, "")).replace(/'/g,"\""))));
                setVariantGroups(data);
            }
            else {
                setVariantGroups(data);
            }


            // setVariantGroups(data);


            // for(let j=0;j<lstVariantGroups.length;j++){
            //    
            //     let data = commonFunctionality.stringFormatForJson(variants.filter((x:any)=>x.product_variants_title == lstVariantGroups[j].product_variants_title));
            //    
            //     if(data.length > 0){
            //         lstVariantGroups[j].product_variant_value = data[0].product_feild_value;
            //     }
            // }


        }
        catch (error) {
            setVariantGroups(
                []
            )
        }
    }

    const handleGroupChange = (index: number, e: any) => {

        // if ('value' in e.target) {

        for (let i = 0; i < lstVariantGroups.length; i++) {
            if (index == lstVariantGroups[i].category_variant_id) {

                lstVariantGroups[i].product_variant_value = e.label;
                // lstVariantGroups[i].product_variant_value = e.value;
                lstVariantGroups[i].product_details_id = (+listingDetail.product_details_id);
                lstVariantGroups[i].validate = "";
                setVariantGroups([...lstVariantGroups]);
                break;
            }

        }
        // } 
    }

    const loadGroups = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {

        const filteredOptions = filterGroups(inputValue);
        callback(filteredOptions);
    };

    const filterGroups = (inputValue: string) => {

        if (inputValue != '') {
            return lstCategoryDrp.filter((option: any) => {
                return option.value.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstCategoryDrp;
        }
    };

    const saveVariant = async (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        for (let i = 0; i < lstVariantGroups.length; i++) {

            if (lstVariantGroups[i].is_required == 1 && lstVariantGroups[i].product_variant_value == '') {

                lstVariantGroups[i].validate = "Required";
            }
            lstVariantGroups[i].product_details_id = (+listingDetail.product_details_id);
        }
        setVariantGroups([...lstVariantGroups]);


        let error: any = lstVariantGroups.filter((x: any) => x.is_required == 1 && x.product_variant_value == '');


        if (error.length == 0) {

            // let productDetails_Id =  productDetails.product_id;
            productDetails.product_id = listingDetail.product_id;
            const responseData: any = await ecommerceRepository.SaveProductVariants(lstVariantGroups);


            const queryParams = new URLSearchParams(search);

            queryParams.set('id', ((listingDetail.product_details_id) > 0 ? listingDetail.product_details_id.toString() : (productDetails.product_details_id.toString())));
            queryParams.set('title', productDetails.title);
            queryParams.set('skuid', productDetails.sku_id);
            queryParams.set('product_id', ((listingDetail.product_id) > 0 ? listingDetail.product_id.toString() : (productId.toString())));
            history.push(`?${queryParams.toString()}`);

            if (responseData[0].db_result > 0) {


                let id = ((listingDetail.product_details_id) > 0 ? listingDetail.product_details_id.toString() : (productDetails.product_details_id.toString()));
                viewProducts(productDetails.sku_id, id);
                listingDetail.type = "Existing";
                setListingDetail(listingDetail);
                // setlstVariants(lstVariantGroups);
                Swal.fire("Varients saved successfully", "Varients saved successfully", "success");
            }
            // window.location.reload()

        }

    }

    const [lstKeywords, setKeywords] = useState([{
        product_keywords: 0,
        product_details_id: 0,
        keyword: '',
        validate: ""
    }]);

    const addNewKeyword = () => {

        setKeywords((prevKeywords) => [
            ...prevKeywords,
            {
                product_keywords: 0,
                product_details_id: 0,
                keyword: '',
                validate: '',
            },
        ]);
    }

    const removeKeyword = (e: any) => {
        debugger;
        const updatedKeywords = [...lstKeywords];
        updatedKeywords.splice(e, 1);
        setKeywords(updatedKeywords);
    }

    const handleKeywordChange = (index: number, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {

        if ('value' in e.target) {

            for (let i = 0; i < lstKeywords.length; i++) {
                if (index == i) {

                    lstKeywords[i].keyword = e.target.value;
                    lstKeywords[i].product_details_id = (+listingDetail.product_details_id);
                    lstKeywords[i].validate = "";
                    setKeywords([...lstKeywords]);
                    break;
                }
            }
        }
    }

    const saveKeywords = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();
        debugger;
        for (let i = 0; i < lstKeywords.length; i++) {

            if (lstKeywords[i].keyword == '') {

                lstKeywords[i].validate = "Required";
            }
            lstKeywords[i].product_details_id = (+listingDetail.product_details_id);
        }
        setKeywords([...lstKeywords]);


        let error: any = lstKeywords.filter((x: any) => x.keyword == '');


        if (error.length == 0) {

            productDetails.product_id = listingDetail.product_id;
            const responseData: any = await ecommerceRepository.saveProductKeywords(lstKeywords);

            if (responseData[0].db_result > 0) {
                Swal.fire("Keywords saved successfully", "Keywords saved successfully", "success");



                // viewProducts(product_sku_id, listingDetail.product_details_id);

            }

        }

    }

    const [file, setFile] = useState<File | undefined>(undefined);

    const [lstProductResource, setProductResource] = useState([{
        product_resources_id: 0,
        product_details_id: 0,
        resource_name: '',
        resource_type: '',
        image: '',
        file: <File | undefined>(undefined),
        validate: '',
    }]);

    const [lstResource, setlstResource] = useState([{
        image: '',
        resource_name: ''
    }]);
    const [lstVariants, setlstVariants] = useState([{
        title: '',
        sku_id: '',
        product_id: '',
        product_details_id: '',
    }]);

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        debugger;
        if (e.target instanceof HTMLInputElement && e.target.files && e.target.files.length > 0) {

            const selectedFile = e.target.files[0];

            let resourceType = '';

            if (selectedFile.type == 'video/mp4') {
                resourceType = 'Video';
            } else if (selectedFile.type == 'image/png' || selectedFile.type == 'image/jpeg' || selectedFile.type == 'image/jpg' || selectedFile.type == 'image/gif') {
                resourceType = 'Image';
            }

            if (selectedFile.size > ((1024 * 1024) * 3)) {
                Swal.fire("Opps", "Max Image size is 3MB ", "error");
            }
            else {

                // setFile(selectedFile);
                // Read the file and convert it to a data URL
                const reader = new FileReader();
                reader.onloadend = () => {
                    debugger;
                    // setProductResource(reader.result as string);

                    let date = new Date();
                    if (lstProductResource.length < 9) {
                        setProductResource((prevProductResource) => [
                            ...prevProductResource,
                            {
                                product_resources_id: 0,
                                product_details_id: (+listingDetail.product_details_id),
                                // product_details_id : 24, 
                                resource_name: resourceType + date.getTime().toString(),
                                resource_type: resourceType,
                                image: '',
                                file: selectedFile,
                                validate: '',
                            },
                        ]);

                        setlstResource((prevlstResource) => [
                            ...prevlstResource,
                            {
                                image: (reader.result as string),
                                // image_data : (reader.result as string),
                                resource_name: resourceType + date.getTime().toString(),
                            },
                        ]);
                    } else {
                        Swal.fire("Info", "Max 8 file can be uploaded", "info");
                    }
                };
                reader.readAsDataURL(selectedFile);
                e.target.value = '';
            }
        }
    };



    const deleteImage = (item: any, e: any) => {

        // let resourceInd = lstProductResource.findIndex(x => (x.resource_name == item.resource_name));
        // lstProductResource.splice(resourceInd, 1);
        // // category.category_fields= [categoryFeilds];
        // setProductResource([...lstProductResource]); 

        let resourceInd = lstResource.findIndex(x => (x.resource_name == item.resource_name));
        lstResource.splice(resourceInd, 1);
        // category.category_fields= [categoryFeilds];
        setlstResource([...lstResource]);

    }


    const uploadImage = async () => {
        debugger;
        const formData = new FormData();
        let index: number;


        for (let i = 0; i < lstProductResource.length; i++) {

            if (lstProductResource[i].resource_name == '') {
                lstProductResource.splice(i, 1);
                // lstProductResource[0].product_details_id == listingDetail.product_details_id;

            }



            if (lstProductResource.length > 0)
                if (lstProductResource[i].resource_name != '' && lstProductResource[i].file) {

                    formData.append('imageToSave', lstProductResource[i].resource_name);
                    formData.append('file', lstProductResource[i].file as Blob);
                    try {

                        const responseData: any = await ecommerceRepository.uploadImage(formData);

                        // return responseData;
                    }
                    catch (error) {
                        console.error('Error uploading image:', error);
                    }
                }
            index = i;

            if ((index + 1) == lstProductResource.length) {

                // for(let i=0;i<lstProductResource.length;i++){
                //     lstProductResource[i].product_details_id = listingDetail.product_details_id;

                // }
                const responseData1: any = await ecommerceRepository.SaveProductResources(lstProductResource);


            }
        }




    }



    const viewProducts = async (sku_id: any, getVariants: any) => {

        // productDetails.product_id = listingDetail.product_id;
        let skuid = '';

        if (sku_id == undefined) {

            skuid = productDetails.sku_id
        } else {

            // sku_id =skuid;
            skuid = sku_id;
        }

        const responseData: any = await ecommerceRepository.ViewProducts(skuid);


        if (responseData[0].product_details_id > 0) {
            // setListProduct(responseData[0].Product);

            let product = (commonFunctionality.stringFormatForJson(((responseData[0].Product))))
            setListProduct(product[0]);

            setListProduct(prevsetCategory => ({
                ...prevsetCategory,
                category_id: product[0].category_id ? product[0].category_id : '',
            }));





            setProductDetails(responseData[0]);

            // setCategoryFeilds(responseData[0].ProductFeatureFeilds);
            // setCategoryFeilds(commonFunctionality.stringFormatForJson( ((responseData[0].ProductFeatureFeilds.replace(/\n/g, "")).replace(/'/g,"\""))));

            // setKeywords(responseData[0].ProductKeywords);

            if (responseData[0].ProductKeywords != '') {
                setKeywords(commonFunctionality.stringFormatForJson(((responseData[0].ProductKeywords))));
            }


            // setlstResource(responseData[0].ProductResources);
            if (responseData[0].ProductResources != '') {
                let resresource = commonFunctionality.stringFormatForJson(((responseData[0].ProductResources)));
                setlstResource(resresource);
                debugger;
                let blstPr: any = [];
                for (let i = 0; i < resresource.length; i++) {

                    blstPr.push({
                        product_resources_id: 0,
                        product_details_id: responseData[0].product_details_id,
                        resource_name: resresource[i].resource_name,
                        resource_type: '',
                        image: resresource[i].image,
                        file: <File | undefined>(undefined),
                        validate: ''
                    })
                }
                setProductResource(blstPr);

            }


            // setVariantGroups(responseData[0].ProductVariants);
            // setVariantGroups(commonFunctionality.stringFormatForJson( ((responseData[0].ProductVariants.replace(/\n/g, "")).replace(/'/g,"\""))));
            // setVariantGroups(commonFunctionality.stringFormatForJson(((responseData[0].ProductVariants))));

            if (responseData[0].variants != '') {



                // let val=((responseData[0].variants);
                //  let variants=commonFunctionality.stringFormatForJson(productVariantsString);
                setlstVariants(commonFunctionality.stringFormatForJson(responseData[0].variants));


                if (listingDetail.type != 'New') {

                    getCategoryVariantGroups(product[0].category_id, getVariants);
                } else if (getVariants == 'getVariants') {

                    getCategoryVariantGroups(product[0].category_id, responseData[0].ProductVariants);
                } else {
                    getCategoryVariantGroups(product[0].category_id, '');
                }


                getCategoryFeilds(product[0].category_id, responseData[0].ProductFeatureFeilds);

            }



        }
    }



    const getProducts = async (params: any) => {
        debugger;
        const queryParams = {
            id: params.product_details_id,
            title: params.title,
            skuid: params.sku_id,
            product_id: params.product_id,
        };

        const queryString = new URLSearchParams(queryParams).toString();

        history.push(`/products?${queryString}`);

    }






    const newVariant = async () => {

        debugger;
        // listingDetail.product_details_id = 
        setProductDetails(prevState => ({
            ...prevState,
            product_details_id: 0,
            sku_id: '',
            title: '',
            mrp: 0,
            selling_price: 0
        }));

        setListingDetail(prevListingDetail => ({
            ...prevListingDetail,
            type: ('New' ? 'New' : 'New'),
        }));



        setlstResource([]);
        setVariantGroups([]);
        setKeywords([]);
        setCategoryFeilds([]);

        // getCategoryVariantGroups(product.category_id, '');

        getCategoryFeilds(product.category_id, '');
        getCategoryVariantGroups(product.category_id, 'new');


    }




    const selectVariant = async (e: any, f: any) => {

        debugger;
        // const queryParams = new URLSearchParams(search);
        // queryParams.set('id', productDetails.product_details_id.toString());
        // queryParams.set('title', productDetails.title);
        // queryParams.set('skuid', productDetails.sku_id);
        // queryParams.set('product_id', ((listingDetail.product_id) > 0 ? listingDetail.product_id.toString() : (productDetails.product_id.toString()) ));

        // // Convert the updated parameters to string and update the location
        // history.push(`?${queryParams.toString()}`);
        setKeywords([]);
        setVariantGroups([])

        let detailsId = 0;

        if (e.product_details_id == undefined) {
            detailsId = listingDetail.product_details_id;
        } else {
            detailsId = e.product_details_id;
        }
        setListingDetail(prevListingDetail => ({
            ...prevListingDetail,
            product_details_id: (detailsId ? detailsId : detailsId),
            type: '',
        }));

        viewProducts(e.sku_id, detailsId);
    }



    useEffect(() => {

        getCategory();
        getBrand();
        getMerchant();
        getHSN();
        debugger;

        if (product_sku_id != '' && product_sku_id != undefined) {

            viewProducts(product_sku_id, product_detail_id);
        }



        if (product_details_id != '' && product_details_id != undefined) {

            setListingDetail(prevListingDetail => ({
                ...prevListingDetail,
                product_details_id: product_detail_id ? product_detail_id : '',
                product_id: product_id ? product_id : '',
            }));
        }


    }, [alert])


    useEffect(() => {
        debugger;
        try {
            let newCheck = { ...showCheckmark };
            if (listingDetail.type == 'New') {
                newCheck.listingInformation = 'false';
                newCheck.Details = 'false';
                newCheck.variants = 'false';
                newCheck.keywords = 'false';
                setCheckmark(newCheck)
            } else if ((+product_id) > 0 && productDetails.sku_id != '') {
                // setCheckmark(true) 
                // alert(1)
                newCheck.listingInformation = 'true';
            }
            // else if ((+product_id) > 0 && lstCategoryFeilds.length > 0 && lstCategoryFeilds[0].product_feild_value !='') {
            //     // setCheckmark(true) 
            //     newCheck.Details = 'true';
            // }
            // else if ((+product_id) > 0 && lstVariantGroups.length > 0 && lstVariantGroups[0].product_variant_value !='') {
            //     // setCheckmark(true) 
            //     newCheck.variants = 'true';
            // }
            // else if ((+product_id) > 0 && lstKeywords.length > 0 && lstKeywords[0].keyword !='') {
            //     // setCheckmark(true) 
            //     newCheck.keywords = 'true';
            // }
            setCheckmark(newCheck);
            if (product_sku_id != '' && product_sku_id != undefined) {

                viewProducts(product_sku_id, product_detail_id);
            }
        } catch (e) {

        }

    }, [alert])


    useEffect(() => {
        //   /  debugger;
        let newCheck = { ...showCheckmark };
        if (listingDetail.type == 'New') {
            // setCheckmark(false)
            newCheck.listingInformation = 'false';
            newCheck.Details = 'false';
            newCheck.variants = 'false';
            newCheck.keywords = 'false';
            setCheckmark(newCheck)
        }
        if ((+product_id) > 0 && lstCategoryFeilds.length > 0 && lstCategoryFeilds[0].product_feild_value != '') {
            // setCheckmark(true) 
            newCheck.Details = 'true';
        }
        if ((+product_id) > 0 && productDetails.sku_id != '') {
            // setCheckmark(true) 
            newCheck.listingInformation = 'true';
        }
        setCheckmark(newCheck);
    }, [alert])


    useEffect(() => {
        let newCheck = { ...showCheckmark };
        if ((+product_id) > 0 && listingDetail.type == 'New') {
            // setCheckmark(false)
            newCheck.listingInformation = 'false';
            newCheck.Details = 'false';
            newCheck.variants = 'false';
            newCheck.keywords = 'false';
            setCheckmark(newCheck)
        }
        else if ((+product_id) > 0 && lstVariantGroups.length > 0 && lstVariantGroups[0].product_variant_value != '') {
            // setCheckmark(true) 
            newCheck.variants = 'true';
        }
        setCheckmark(newCheck);
    }, [lstVariantGroups.length > 0 && lstVariantGroups[0].product_variant_value != ''])

    useEffect(() => {
        let newCheck = { ...showCheckmark };
        if ((+product_id) > 0 && listingDetail.type == 'New') {
            // setCheckmark(false)
            newCheck.listingInformation = 'false';
            newCheck.Details = 'false';
            newCheck.variants = 'false';
            newCheck.keywords = 'false';
            setCheckmark(newCheck)
        }
        else if ((+product_id) > 0 && lstKeywords.length > 0 && lstKeywords[0].keyword != '') {
            // setCheckmark(true) 
            newCheck.keywords = 'true';
        }
        setCheckmark(newCheck);
    }, [lstKeywords.length > 0 && lstKeywords[0].keyword != ''])




    const ProductgetVariantValue = (variantGroup: any) => {


        if (typeof (productDetails.ProductVariants) == 'undefined' || productDetails.ProductVariants.length == 0) {
            return '';
        }

        try {
            return commonFunctionality.stringFormatForJson(productDetails.ProductVariants).filter((product: any) => {

                return product.product_variants_title == variantGroup
            })[0].product_variant_value;
        } catch (e) {
            return '';
        }

    }


    return {
        statusChange,
        ProductgetVariantValue,
        // Categroy
        lstCategoryDrp, lstCategory, handleCategoryChange,
        // Product
        product, productValidate, saveProduct, handleProductChange, cancelProduct, viewProducts,
        // product Details functions
        productDetails, productDetailsValidate, saveProductDetails, cancelProductDetails, handleProductDetailsChange,
        // Brand
        lstBrandDrp, brandChange, loadBrand,

        // GET Category feilds and Varinat groups
        lstCategoryFeilds, saveCategoryFeilds, handleFeildsChange, saveFeatureDetails,

        lstVariantGroups, loadGroups, handleGroupChange, saveVariant,

        lstKeywords, handleKeywordChange, addNewKeyword, removeKeyword, saveKeywords,

        lstProductResource, handleImageChange, deleteImage, uploadImage, lstResource,
        imagePath,
        newVariant, lstVariants,
        selectVariant, listingDetail, resourceSubmit, merchantChange, lstMerchantDrp, hsnChange, loadMerchant, lstHSNDrp, loadHSN, product_Ids, showCheckmark, getProducts, typeChange
    }
}