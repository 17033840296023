import React, { Component } from "react";
import async from "react-select/dist/declarations/src/async/index";
// import * as CryptoJS from 'crypto-js';


export function EcommerceRepository() {

    // testing api starts here...
    const apiUrl = 'https://api.educationtodayindia.com/educationtodayindiawebapi/api/';
    // const imagePath = "https://educationtodayindia.com/educationtodayindiawebapi/webapi/images/";
    const imagePath = "https://api.educationtodayindia.com/educationtodayindiawebapi/public/images/";
    // testing api ends here...

    // api calling functions starts here...
    const getBrands = async () => {
        //debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getbrands');
            const data: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const getMerchant = async () => {
        //debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'viewmerchant');
            const data: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }
    


    const prepareForwardShipment = async (shipmentDetails: any) => {
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'prepareforwardshipment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(shipmentDetails)
            })
            const responseData: any = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const saveBrand = async (brand: any) => {
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'savebrand', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(brand)
            })
            const responseData: any = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const deleteBrand = async (brand: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletebrand', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(brand),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const saveVarientGroup = async (varientFeilds: any) => {
        //debugger;
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'savevariantgroup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(varientFeilds)
            })
            const responseData: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }


    }


    const getVarientGroup = async () => {
        //debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getvariantgroups');
            const data: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const deleteVarientGroup = async (varientGroup: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletevariantgroup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(varientGroup),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const getVarients = async () => {
        //debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'getvariants');
            const data: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const saveVarients = async (varientFeilds: any) => {

        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'savevariant', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(varientFeilds)
            })
            const responseData: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }


    }


    const deleteVarient = async (varient: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletevariant', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(varient),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const uploadImage = async (formData: any) => {
        //debugger;

        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'upload', {
                method: 'POST',
                body: formData,
            });
            //debugger;
            if (response.ok) {
                //debugger;
                const responseData = await response.json();
                $("#loaderParent").hide();
                return responseData;


            } else {
                console.error('Failed to upload image. Server returned:', response.status);
            }
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const saveHsn = async (hsn: any) => {

        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'savehsn', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(hsn)
            })
            const responseData: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }


    }

    const getHsn = async () => {
        //debugger;
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'viewhsn');
            const data: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const deleteHsn = async (hsn: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletehsn', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(hsn),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const saveCategory = async (category: any) => {
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'savecategory', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(category)
            })
            const responseData: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }



    const getCategories = async () => {
        //debugger;
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'getcategories');
            const data: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }



    const deleteCategory = async (varientGroup: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletecategory', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(varientGroup),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const saveDiscount = async (discount: any, productFeilds: any) => {
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'savediscount', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                // body:JSON.stringify({'discount':productFeilds })
                body: JSON.stringify({ 'discount': discount, 'product_discounts': productFeilds })
            })
            const responseData: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const viewDiscount = async (discount: any) => {
        //debugger;
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'viewdiscount');
            const data: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const deleteDiscount = async (discount: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletediscount', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(discount),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    // const ViewProducts = async (sku_id: any) => {
    //     //debugger;
    //     $("#loaderParent").show();
    //     try {
    //         const response = await fetch(apiUrl + 'viewproducts', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify(sku_id),
    //         })
    //         const responseData = await response.json();
    //         $("#loaderParent").hide();
    //         return responseData;
    //     } catch (e) {
    //         $("#loaderParent").hide();
    //     }
    // }  


    const ViewProducts = async (sku_id: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'viewproducts', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ 'sku_id': sku_id }),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }





    const saveProduct = async (product: any) => {
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'savelisting', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(product)
            })
            const responseData: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const saveProductDetails = async (details: any) => {
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'saveproductdetails', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(details)
            })
            const responseData: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }



    const getHierarchicalCategories = async () => {
        //debugger;
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'gethierarchicalcategories');
            const data: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }



    const getCategoryFeilds = async (category_id: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'viewcategoryfeilds', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(category_id),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }



    const saveFeatureDetails = async (details: any) => {
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'productfeaturefeilds', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(details)
            })
            const responseData: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const getCategoryVariantGroups = async (category_id: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'viewcategoryvariantgroups', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(category_id),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const SaveProductVariants = async (details: any) => {
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'saveproductvariants', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(details)
            })
            const responseData: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const saveProductKeywords = async (details: any) => {
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'saveproductkeywords', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(details)
            })
            const responseData: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const SaveProductResources = async (resources: any) => {
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'saveproductresources', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(resources)
            })
            const responseData: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const deleteProduct = async (product: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deleteproduct', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(product),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const saveMerchant = async (merchant: any) => {
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'savemerchant', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(merchant)
            })
            const responseData: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const viewMerchant = async () => {
        //debugger;
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'viewmerchant');
            const data: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const deleteMerchant = async (discount: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletemerchant', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(discount),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const viewState = async (state: any) => {
        //debugger;
        $("#loaderParent").show();
        try {
            //debugger;
            const response = await fetch(apiUrl + 'viewstate');
            const data: any = await response.json();
            //debugger;
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const saveBanner = async (banner: any) => {
        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'savebanner', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(banner)
            })
            const responseData: any = await response.json();
            debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const viewBanner = async (banner: any) => {
        debugger;
        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'viewbanners');
            const data: any = await response.json();
            debugger;
            $("#loaderParent").hide();
            return data;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const deleteBanner = async (banner: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletebanner', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(banner),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }



    const savePurchaseInvoice = async (purchaseInvoice: any) => {
        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'savepurchaseinvoice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(purchaseInvoice)
            })
            const responseData: any = await response.json();
            debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }

    const viewPurchaseInvoice = async (purchaseInvoice: any) => {
        debugger;
        // $("#loaderParent").show();
        // try {
        //     debugger;
        //     const response = await fetch(apiUrl + 'viewpurchaseinvoice');
        //     const data: any = await response.json();
        //     debugger;
        //     $("#loaderParent").hide();
        //     return data;
        // } catch (e) {
        //     $("#loaderParent").hide();
        // }


        $("#loaderParent").show();
        try {
            debugger;
            const response = await fetch(apiUrl + 'viewpurchaseinvoice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(purchaseInvoice)
            })
            const responseData: any = await response.json();
            debugger;
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }

    }

    const deletePurchaseInvoice = async (purchaseInvoice: any) => {
        $("#loaderParent").show();
        try {
            const response = await fetch(apiUrl + 'deletepurchaseinvoice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(purchaseInvoice),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    } 


    const viewReurns = async (_return_date: any) => {
        debugger;
         $("#loaderParent").show();
        try {
            debugger; 
            
            const response = await fetch(apiUrl + 'getOrderReturns', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({'date':_return_date}),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }




    const vieworders = async (_order_date: any) => {
        debugger;
         $("#loaderParent").show();
        try {
            debugger; 
            
            const response = await fetch(apiUrl + 'vieworders', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({'date':_order_date}),
            })
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    


    const getRaisePickupList = async () => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'getraisepickuplist', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                } 
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }
    
    

    const returnStatusUpdate = async (orderConfirmationdata: any) => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'returnstatusupdate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderConfirmationdata),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const orderConfirmStatusUpdate = async (orderConfirmationdata: any) => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'orderstatusupdate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(orderConfirmationdata),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }



    const raisePickup = async (raisePickupDetails: any) => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'raisepickup', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(raisePickupDetails),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }
    


    const getKitDetailsById = async (kit_id:number) => {
        debugger;
      

        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'getkitdetailsbyid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({kit_id:kit_id}),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const viewKit = async () => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'viewkit', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                } 
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    
    const saveKit = async (kitDetails: any) => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'savekit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(kitDetails),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }
   
    
    const deleteKit = async (kitDetails: any) => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'deletekit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(kitDetails),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }
   
    const getStockAdjustmentsDetailsById = async (stock_adjustment_id:number) => {
        debugger;
      

        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'getstockadjustmentsdetailsbyid', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({stock_adjustment_id:stock_adjustment_id}),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    const viewStockAdjustments = async () => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'viewstockadjustments', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                } 
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }


    
    const saveStockAdjustments = async (StockAdjustmentsDetails: any) => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'savestockadjustments', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(StockAdjustmentsDetails),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }
   
    
    const deleteStockAdjustments = async (StockAdjustmentsDetails: any) => {
        debugger;
        $("#loaderParent").show();
        try {

            debugger;
            const response = await fetch(apiUrl + 'deletestockadjustment', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(StockAdjustmentsDetails),
            })
            debugger;
            const responseData = await response.json();
            $("#loaderParent").hide();
            return responseData;
        } catch (e) {
            $("#loaderParent").hide();
        }
    }
   



    // api calling functions ends here...

    return {
        returnStatusUpdate,
        viewReurns,
        viewStockAdjustments,
        saveStockAdjustments,
        deleteStockAdjustments, 
        getStockAdjustmentsDetailsById,
        getKitDetailsById,
        deleteKit,viewKit,saveKit,
        raisePickup,
        getRaisePickupList,
        prepareForwardShipment,
        getBrands, saveBrand, deleteBrand, saveVarientGroup, getVarientGroup, deleteVarientGroup, getVarients, saveVarients, deleteVarient, uploadImage, imagePath, saveHsn, getHsn, deleteHsn, saveCategory, getCategories, deleteCategory, saveDiscount, viewDiscount, deleteDiscount, ViewProducts,
        saveProduct
        , saveProductDetails
        , getHierarchicalCategories
        , getCategoryFeilds
        , saveFeatureDetails
        , getCategoryVariantGroups
        , SaveProductVariants
        , saveProductKeywords
        , SaveProductResources
        , deleteProduct
        , saveMerchant
        , viewMerchant
        , deleteMerchant
        , viewState
        , getMerchant
       , saveBanner 
      , deleteBanner
      , viewBanner 
      ,deletePurchaseInvoice 
     , viewPurchaseInvoice 
      ,savePurchaseInvoice 
      ,vieworders
      ,orderConfirmStatusUpdate


    }

}  