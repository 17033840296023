import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { EcommerceRepository } from "../../repository/EcommerceRepository";

export function BannerViewModel() {

    const ecommerceRepository = EcommerceRepository();
    const imagePath = ecommerceRepository.imagePath; 
    const [searchTerm, setSearchTerm] = useState('');

    const [lstBanners, setBanners] = useState([{
        BannersID: 0,
        BannersName: '',
        Image: '',
        Fld1: '',
        Fld2: '',
        Fld3: '',
        Fld4: '',
        Fld5: '',
        BannerType: '',
        BannerTypeId: 0,
        UpdateLogicColumn: '',
        updated_date: '',
    }])


    const [validate, setValidate] = useState({
        BannersID: 0,
        BannersName: '',
        Image: '',
        Fld1: '',
        Fld2: '',
        Fld3: '',
        Fld4: '',
        Fld5: '',
        BannerType: '',
        BannerTypeId: 0,
        BannerTypeId_validate: '',
        UpdateLogicColumn: '',
        updated_date: '',
    })

    const [Banner, setBanner] = useState({
        BannersID: 0,
        BannersName: '',
        Image: '',
        Fld1: '',
        Fld2: '',
        Fld3: '',
        Fld4: '',
        Fld5: '',
        BannerType: '',
        BannerTypeId: 0,
        UpdateLogicColumn: '',
        updated_date: '',
    })

    const [file, setFile] = useState<File | undefined>(undefined);
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    const fileInputRef = useRef<HTMLInputElement | null>(null);


    const SaveBanner = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();
        const newValidate = { ...validate };
        if (file) {
            let date = new Date();
            let imageName = date.getTime().toString(); // (category.category_id) + 
            Banner.Image = imageName;
        }

        if (!Banner.BannersName.trim()) {
            newValidate.BannersName = "Required!";
        } else {
            newValidate.BannersName = "";
        }

        if (!Banner.BannerType.trim()) {
            newValidate.BannerType = "Required!";
        } else {
            newValidate.BannerType = "";
        }

        if (!(Banner.BannerTypeId > 0)) {
            newValidate.BannerTypeId_validate = "Required!";
        } else {
            newValidate.BannerTypeId_validate = "";
        }

        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger;

                const response: any = await ecommerceRepository.saveBanner(Banner);
                debugger;
                if (response[0].db_result > 0) {

                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;
                    if (buttonElement) {
                        buttonElement.click();
                    }
                    if (file) {
                        await uploadImage();
                    }
                    viewBanner()
                    cancel();

                }
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            }
            catch (error) {

            }
        } else {
            setValidate(newValidate);
        }
    }


    const uploadImage = async () => {
        debugger;
        if (file) {
            debugger;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('imageToSave', Banner.Image);
            try {
                debugger;
                const responseData: any = await ecommerceRepository.uploadImage(formData);
                return responseData;
            }
            catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setBanner((prev) => ({ ...prev, [name]: value }));
        } else {
            debugger;
            const { name, value } = e.target as HTMLSelectElement;
            setBanner((prev) => ({ ...prev, [name]: value }));
        }

        if (e.target.value == 'Offer') {
            viewDiscount();
        } else if (e.target.value == 'Product') {
            viewProduct();
        } else if (e.target.value == 'Category') {
            viewCategory();
        }
    }

    const [lstData, setData] = useState<{ label: string; value: string }[] | []>([]);
    // const [lstProducts, setProducts] = useState<{ label: string; value: string }[] | []>([]);
    // const [lstCategories, setCategories] = useState<{ label: string; value: string }[] | []>([]); 


    const handleSearch = (e: any) => {
        debugger;
        let filteredMerchant = lstBanners.filter((banner: any) =>
        banner.BannersName.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setBanners(filteredMerchant);
        setSearchTerm(e.target.value);

        if(e.target.value == ""){
            viewbanners();
        }
    };

    const viewDiscount = async () => {
        try {
            debugger;
            const data: any = await ecommerceRepository.viewDiscount('');
            debugger;

            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.discount_name,
                value: item.discount_id.toString(),
            }));

            setData(options)
        }
        catch (error) {
            setData(
                []
            )
        }
    }

    const viewProduct = async () => {
        try {
            debugger;
            const data: any = await ecommerceRepository.ViewProducts('');
            debugger;

            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.title,
                value: item.product_details_id.toString(),
            }));

            setData(options)
        }
        catch (error) {
            setData(
                []
            )
        }
    }

    const viewCategory = async () => {
        try {
            debugger;
            const data: any = await ecommerceRepository.getCategories();
            debugger;

            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.category_name,
                value: item.category_id.toString(),
            }));

            setData(options)
        }
        catch (error) {
            setData(
                []
            )
        }
    }

    const editBanner = (e: any) => {
        debugger; 
        setBanner(e);

        if (e.BannerType == 'Offer') {
            viewDiscount();
        } else if (e.BannerType == 'Product') {
            viewProduct();
        } else if (e.BannerType == 'Category') {
            viewCategory();
        }
   
    }



    const deleteBanner = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });
        if (willDelete.isConfirmed) {
            debugger;
            const responseData: any = await ecommerceRepository.deleteBanner({'banner_id': e });
            if (responseData[0].db_result > 0) {
                debugger;
                Swal.fire("Record Deleted successfully", "", "success");
                viewBanner();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }

    const viewBanner = async () => {
        debugger;
        try {
            debugger;
            const data: any = await ecommerceRepository.viewBanner('');
            debugger;
            setBanners(data)
        }
        catch (error) {
            setBanners(
                []
            )
        }
    }


    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        debugger;
        if (e.target instanceof HTMLInputElement && e.target.files && e.target.files.length > 0) {
            debugger;
            const selectedFile = e.target.files[0];

            if (selectedFile.size > ((1024 * 1024) * 3)) {
                Swal.fire("Opps", "Max Image size is 3MB ", "error");
            }
            else {
                setFile(selectedFile);
                // Read the file and convert it to a data URL
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImageSrc(reader.result as string);
                };
                reader.readAsDataURL(selectedFile);
                debugger;
            }
        }
    };


    const viewbanners = async () => {
        debugger;
        try {
            debugger;
            const data: any = await ecommerceRepository.viewBanner('');
            setBanners(data);
        }
        catch (error) {
            setBanners([]);
        }
    }



    const loadBanner = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterBanner(inputValue);
        callback(filteredOptions);
    };

    const filterBanner = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstData.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstData;
        }
    };

    const handleSelectChange = (selectedOption: any | null) => {
        debugger;
        setBanner(prevsetBanner => ({
            ...prevsetBanner,
            BannerTypeId: selectedOption ? selectedOption.value : '',
        }));
    };


    const cancel = async () => {
        debugger;
        setBanner({
            BannersID: 0,
            BannersName: '',
            Image: '',
            Fld1: '',
            Fld2: '',
            Fld3: '',
            Fld4: '',
            Fld5: '',
            BannerType: '',
            BannerTypeId: 0,
            UpdateLogicColumn: '',
            updated_date: '',
        });

        setValidate({
            BannersID: 0,
            BannersName: '',
            Image: '',
            Fld1: '',
            Fld2: '',
            Fld3: '',
            Fld4: '',
            Fld5: '',
            BannerType: '',
            BannerTypeId: 0,
            BannerTypeId_validate: '',
            UpdateLogicColumn: '',
            updated_date: '',
        });



    }


    useEffect(() => {
        viewbanners();
    }, []);



    return {
        SaveBanner, editBanner, deleteBanner, handleImageChange, handleChange, cancel, lstBanners, imageSrc, Banner, imagePath, validate,
        lstData , loadBanner  , handleSelectChange,handleSearch,searchTerm
    }
}