import React from "react";
import { ClusterViewModel } from "./ClusterViewModel";

const clusterview:React.FC=()=>
 {
    const clusterViewmodels = ClusterViewModel()
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">EIT </a></li>
                                        {/* <li className="breadcrumb-item"><a href="javascript: void(0);">ECommerce </a></li> */}
                                        <li className="breadcrumb-item active">Cluster </li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Cluster </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                                <label>Search:<input type="search" className="form-control form-control-sm" value={clusterViewmodels.searchTerm} onChange={clusterViewmodels.handleSearch} /></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#user-modal" >
                                                <i className="mdi mdi-plus-circle me-2"></i> Add Cluster
                                            </a>
                                        </div>

                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Cluster Code </th>
                                                            <th>Cluster Name</th>
                                                            <th>City</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {clusterViewmodels.filteredClusters.map((d: any, index: number) => (
                                                            <tr key={d.cluster_code}>
                                                                <td>{index + 1}</td>
                                                                <td>{d.cluster_code}</td>
                                                                <td>{d.cluster_name}</td>
                                                                <td>{d.city_name}</td>

                                                                <td>
                                                                    <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#user-modal" onClick={() => clusterViewmodels.editCluster(d)}></i>
                                                                    {<i className="mx-2 uil uil-trash-alt" onClick={() => clusterViewmodels.deleteClusters(d.cluster_id)}></i>}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>

                                                <nav className="text-right mt-3" >

                                                    <ul className='pagination float-end scrool-bar'>
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={clusterViewmodels.prePage}>prev</a>
                                                        </li>
                                                        {

                                                            clusterViewmodels.numbers.map((n, i) =>
                                                            (

                                                                <li key={i} className={`page-item ${clusterViewmodels.currentPage === n ? 'active' : ''}`} >
                                                                    <a className='page-link' onClick={() => clusterViewmodels.changePage(n)}>{n}</a>
                                                                </li>
                                                            ))
                                                        }
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={clusterViewmodels.nextPage}>next</a>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="user-modal" className='modal fade' tabIndex={-1} data-bs-backdrop="static" aria-labelledby="user-modalLabel" aria-modal="true" role="dialog" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={clusterViewmodels.saveCluster}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="standard-modalLabel">Add Cluster </h4>
                                <button type="button" id="myModal" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={clusterViewmodels.cancel}></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <label >Cluster Code</label>
                                        <input type="text" id="cluster_code" name="cluster_code" value={clusterViewmodels.cluster.cluster_code} className="form-control" onChange={(e) => clusterViewmodels.handleChange(e, '')} />    {<div className="text-danger">{clusterViewmodels.validate.cluster_code}</div>}
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <label >Cluster Name</label>
                                        <input type="text" id="cluster_name" name="cluster_name" value={clusterViewmodels.cluster.cluster_name} className="form-control" onChange={(e) => clusterViewmodels.handleChange(e, '')} />
                                        {<div className="text-danger">{clusterViewmodels.validate.cluster_name}</div>}
                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <label >City</label>
                                        <select name="city_id" id="city_id" className="form-control" value={clusterViewmodels.cluster.city_id} onChange={(e) => clusterViewmodels.handleChange(e, '')}>      <option value="" selected aria-readonly>Select</option>
                                            {clusterViewmodels.lstCities.data.map((d: any, index: number) => (
                                                <option value={d.city_id}>{d.city_name}</option>))}
                                        </select>
                                        {<div className="text-danger">{clusterViewmodels.validate.city}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                {/* <button type="button" className="btn btn-light" onClick={handleClick}>New</button> */}
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={clusterViewmodels.cancel} >Close </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )


}
export default clusterview;