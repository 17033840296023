import { useEffect, useState } from "react"
import { EcommerceRepository } from "../../repository/EcommerceRepository";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
export function KitModel()
{
    const search = useLocation().search;
    const kitId = new URLSearchParams(search).get("id");
    let kit_Id:any = kitId;
// const[lstKet,setKit]=useState([]);
const history = useHistory();
const repository=EcommerceRepository();
const navigate = useHistory();

useEffect(()=>
{
    debugger;
    if(kit_Id)
    {
       getKitDetailsById(kit_Id) ;
    }
    getKitList();

},[kit_Id])


const getKitDetailsById=(kit_Id:number)=>
{

debugger;
    repository.getKitDetailsById(kit_Id).then((data:any)=>
    {

        setSelectedKit(data[0]);
        setKitChildProducts((JSON.parse(((data[0].kit_product_details.replace(/\n/g, "")).replace(/'/g, "\"")))));
    })

}



useEffect(()=>
{
    debugger;
    if(kit_Id==null)
    getKitList();

},[alert])
const getKitList=()=>
{
    setkitDetails([]); 
    repository.viewKit().then((result:any)=>
    {

        setkitDetails(result);
        setFilterProductKit(result)
    })
}
const createKit= () =>{
    debugger;
 
history.push(`/createkit`);
 
}


const [lstFilterProductKit, setFilterProductKit] = useState([{
    kit_id : 0,
    title : '',
    
    product_details_id :0 
}]) 

   
const [searchTerm, setSearchTerm] = useState('');   

const [currentPage, setCurrentPage] = useState(1);
debugger;
const recordsPerPage = 10;
const lastIndex = currentPage * recordsPerPage;
const firstIndex = lastIndex - recordsPerPage;
const filteredProductKit = lstFilterProductKit.slice(firstIndex, lastIndex);
// const npage = Math.ceil(lstFilterProductKit.length / recordsPerPage);
const [kitDetails,setkitDetails] = useState([{
    kit_id : 0,
    title : '',
    
    product_details_id :0 
}]); 


const handleSearch = (e: any) => {
    debugger;
    let filteredProductKit = kitDetails.filter((kitDetails: any) => 
    kitDetails.title.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setFilterProductKit(filteredProductKit);
   // setFilterProduct({...filteredProducts});
    setSearchTerm(e.target.value);
};

const edit = (d:any) => {
    debugger;

    const queryParams = {
        id: d.kit_id 
      };
      const queryString = new URLSearchParams(queryParams).toString();
      history.push(`/createkit?${queryString}`);

}
const deleteKit = async (d:any) =>{
    debugger;
    const willDelete = await Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    });

    if (willDelete.isConfirmed) {
        debugger;
        const responseData: any = await repository.deleteKit({'kit_id': d.kit_id });
        debugger;
            if (responseData[0].db_result > 0) {
                debugger;
                getKitList();
               Swal.fire("Record Deleted successfully", "", "success");
               
            } 
        Swal.fire("Record Deleted successfully", "", "success");
    }else{
        Swal.fire("Your record is safe!", "", "info");
    } 

}







const [selectedKit,setSelectedKit]=useState({kit_id:0,product_details_id:0,qty:0,notes:'',updated_date:'',title:'', kit_product_details:[]});

const saveKit=()=>
{
    debugger;
if(validateKit())
{

debugger;



    repository.saveKit({kit_details:selectedKit,kit_products_details:lstKitChildProducts}).then((response:any)=>
    {
debugger; if (response[0].db_result > 0) {
    debugger;
    if (selectedKit.kit_id == 0) {
        Swal.fire("Kit created successfully", "", "success");

        
    }
    else {
        Swal.fire("Kit updated successfully", "", "success");

       
    }
    navigate.push('/kit');
}

    })
}
  
}

const [lstKitProducts,setKitProducts]=useState([]);


const onKitProductChange=(e:any)=>
{

debugger;
let cpKitProduct={...selectedKit};

cpKitProduct.product_details_id=e!=null?  e.product_details_id:0;
setSelectedKit(cpKitProduct);
}

const loadKit = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
    debugger;
    const filteredOptions = filterKit(inputValue);
    callback(filteredOptions);
};

const filterKit = (inputValue: string) => {
    debugger;
    if (inputValue != '') {
        return lstKitProducts.filter((option: any) => {
            return option.label.toLowerCase().includes(inputValue.toLowerCase());
        }
        );
    } else {
        return lstKitProducts;
    }
};
const onQtyChange=(e:any)=>
{
let sKit={...selectedKit};

sKit.qty=e.target.value;
setSelectedKit(sKit)

}  



const onNotesChange=(e:any)=>
{
let sKit={...selectedKit};

sKit.notes=e.target.value;
setSelectedKit(sKit)

}  
const getKit=()=>
{

     
history.push(`/kit`);
}

const addProduct=()=>
{
    setSelectedProduct({product_details_id:0,qty:0,kit_product_id:0,title:''});

}
const [lstKitChildProducts,setKitChildProducts]=useState([]);
const removeKitProduct=(index:number)=>
{
debugger;
let lstcKitChildProducts=[...lstKitChildProducts];
lstcKitChildProducts.splice(index,1);
setKitChildProducts(lstcKitChildProducts)
}

const kitChildEdit=(data:any,index:number)=>
{
setSelectedProduct(data);
}





const loadProduct = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
    debugger;
    const filteredOptions = filterProduct(inputValue);
    callback(filteredOptions);
};

const filterProduct = (inputValue: string) => {
    debugger;
    if (inputValue != '') {
        return lstProducts.filter((option: any) => {
            return option.label.toLowerCase().includes(inputValue.toLowerCase());
        }
        );
    } else {
        return lstProducts;
    }
};


useEffect(()=>
{

    viewProducts();
},[alert])
const viewProducts=()=>
{


repository.ViewProducts('').then((result:any)=>
{
debugger;

let kitProducts=result.filter((x:any)=> x.ProductType=='Kit');

setKitProducts(kitProducts);


let products=result.filter((x:any)=> x.ProductType!='Kit');
setProducts(products);

})

}

const [selectedProduct,setSelectedProduct]=useState({product_details_id:0,qty:0,kit_product_id:0,title:''});

const onProductQtyChange=(e:any)=>
{
    debugger;
    let sproduct={...selectedProduct};

sproduct.qty=e.target.value;

setSelectedProduct(sproduct);

}
const [lstProducts,setProducts]=useState([]);


const onProductChange=(e:any)=>
{
    debugger;
let sproduct={...selectedProduct};

sproduct.product_details_id=e.product_details_id;
sproduct.title=e.title;
setSelectedProduct(sproduct);

}
const addProductDetails=(type:any)=>
{

    if(validateProduct())
    {

        let clstKitChildProducts:any=[...lstKitChildProducts];

        clstKitChildProducts.push(selectedProduct);
        setKitChildProducts(clstKitChildProducts);
        setSelectedProduct({product_details_id:0,qty:0,kit_product_id:0,title:''});
        if(type=='add')
        {
            $("#addProductModelClose").click();
        }

    }

}

const validateProduct=():any=>
{
    debugger;
let validate:any=true;


if(selectedProduct.product_details_id<=0 && validate )
{
    validate=false;
    Swal.fire("Select Product!", "", "error");
}

if(  selectedProduct.qty<=0  && validate)
{
    validate=false;
    Swal.fire("Invalid Qty!", "", "error");
}
if(    validate)
{

  let index=  lstKitChildProducts.findIndex((x:any)=> x.product_details_id==selectedProduct.product_details_id);

  if(index>-1 && validate)
  {

    validate=false;
    Swal.fire("Products already exist!", "", "error");
  }
}
  return validate;
}

const validateKit=():any=>
{
    let validate:any=true;

    if(selectedKit.product_details_id<=0
    && validate )
    {
        validate=false;
        Swal.fire("Select Kit Product!", "", "error");
    }
    
    if(  selectedKit.qty<=0  && validate)
    {
        validate=false;
        Swal.fire("Invalid Kit Qty!", "", "error");
    }
    if(lstKitChildProducts.length==0 && validate)
    {
        validate=false;
        Swal.fire("Add Kit Products!", "", "error");

    }
    
    // else{

    //     selectedKit.kit_product_details=lstKitChildProducts;
    // }
    return  validate;
}

return {addProductDetails,selectedProduct,onProductQtyChange,loadProduct,onProductChange,lstProducts,kitChildEdit,lstKitChildProducts,removeKitProduct,addProduct,getKit,onNotesChange,onQtyChange,saveKit,loadKit,onKitProductChange,lstKitProducts,selectedKit,deleteKit,edit,filteredProductKit,handleSearch,searchTerm,createKit}
}