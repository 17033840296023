import { useEffect, useState } from "react"
 
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { EcommerceRepository } from "../repository/EcommerceRepository";
export function StockAdjustmentsModel()
{
    const search = useLocation().search;
    const StockAdjustmentsId = new URLSearchParams(search).get("id");
    let stock_adjustment_id:any = StockAdjustmentsId;
// const[lstKet,setStockAdjustments]=useState([]);
const history = useHistory();
const repository=EcommerceRepository();
 
useEffect(()=>
{
    debugger;
    if(stock_adjustment_id)
    {
       getStockAdjustmentsDetailsById(stock_adjustment_id) ;
    }
    getStockAdjustmentsList();

},[stock_adjustment_id])


const getStockAdjustmentsDetailsById=(stock_adjustment_id:number)=>
{

debugger;
    repository.getStockAdjustmentsDetailsById(stock_adjustment_id).then((data:any)=>
    {

        setSelectedStockAdjustments(data[0]);
        setStockAdjustmentsChildProducts((JSON.parse(((data[0].stock_adjustments_product_details.replace(/\n/g, "")).replace(/'/g, "\"")))));
    })

}



useEffect(()=>
{
    debugger;
    if(stock_adjustment_id==null)
    getStockAdjustmentsList();

},[alert])
const getStockAdjustmentsList=()=>
{
    debugger;
    setStockAdjustmentsDetails([]); 
    setFilterProductStockAdjustments([]); 
    repository.viewStockAdjustments().then((result:any)=>
    {
        debugger;
        setStockAdjustmentsDetails(result);
        setFilterProductStockAdjustments(result)
    })
}
const createStockAdjustments= () =>{
    debugger;
 
history.push(`/createstockadjustments`);
 
}


const [lstFilterProductStockAdjustments, setFilterProductStockAdjustments] = useState([{
    stock_adjustment_id : 0,
    voucher_no : '',
    type:'',
    date:'',
    notes :''
}]) 

   
const [searchTerm, setSearchTerm] = useState('');   

const [currentPage, setCurrentPage] = useState(1);
debugger;
const recordsPerPage = 10;
const lastIndex = currentPage * recordsPerPage;
const firstIndex = lastIndex - recordsPerPage;
const filteredProductStockAdjustments = lstFilterProductStockAdjustments.slice(firstIndex, lastIndex);
// const npage = Math.ceil(lstFilterProductStockAdjustments.length / recordsPerPage);
const [StockAdjustmentsDetails,setStockAdjustmentsDetails] = useState([{
    stock_adjustment_id : 0,
    voucher_no : '',
    type:'',
    date:'',
    notes :''
}]); 


const handleSearch = (e: any) => {
    debugger;
    let filteredProductStockAdjustments = StockAdjustmentsDetails.filter((StockAdjustmentsDetails: any) => 
    StockAdjustmentsDetails.voucher_no.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setFilterProductStockAdjustments(filteredProductStockAdjustments);
   // setFilterProduct({...filteredProducts});
    setSearchTerm(e.target.value);
};

const edit = (d:any) => {
    debugger;

    const queryParams = {
        id: d.stock_adjustment_id 
      };
      const queryString = new URLSearchParams(queryParams).toString();
      history.push(`/createStockAdjustments?${queryString}`);

}
const deleteStockAdjustments = async (d:any) =>{
    debugger;
    const willDelete = await Swal.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
    });

    if (willDelete.isConfirmed) {
        debugger;
        const responseData: any = await repository.deleteStockAdjustments({'stock_adjustment_id': d.stock_adjustment_id });
        debugger;
            if (responseData[0].db_result > 0) {
                debugger;
                getStockAdjustmentsList();
               Swal.fire("Record Deleted successfully", "", "success");
               
            } 
        Swal.fire("Record Deleted successfully", "", "success");
    }else{
        Swal.fire("Your record is safe!", "", "info");
    } 

}







const [selectedStockAdjustments,setSelectedStockAdjustments]=useState({
    stock_adjustment_id : 0,
    voucher_no : '',
    type:'',
    date:'',
    notes :'',updated_date:'',  stock_adjustment_product_details:[]});

const saveStockAdjustments=()=>
{
    debugger;
if(validateStockAdjustments())
{

debugger;



    repository.saveStockAdjustments({stock_adjustments_details:selectedStockAdjustments,stock_adjustment_products_details:lstStockAdjustmentsChildProducts}).then((response:any)=>
    {
debugger; if (response[0].db_result > 0) {
    debugger;
    if (selectedStockAdjustments.stock_adjustment_id == 0) {
        Swal.fire("StockAdjustments created successfully", "", "success");

        
    }
    else {
        Swal.fire("StockAdjustments updated successfully", "", "success");

       
    }
    history.push('/stockadjustments');
}

    })
}
  
}

 

 
 
// const onQtyChange=(e:any)=>
// {
// let sStockAdjustments={...selectedStockAdjustments};

// sStockAdjustments.qty=e.target.value;
// setSelectedStockAdjustments(sStockAdjustments)

// }  



const onNotesChange=(e:any)=>
{
let sStockAdjustments={...selectedStockAdjustments};

sStockAdjustments.notes=e.target.value;
setSelectedStockAdjustments(sStockAdjustments)

}  
const getStockAdjustments=()=>
{

     
history.push(`/StockAdjustments`);
}

const addProduct=()=>
{
    setSelectedProduct({product_details_id:0,qty:0,stock_adjustment_product_id:0,title:''});

}
const [lstStockAdjustmentsChildProducts,setStockAdjustmentsChildProducts]=useState([]);
const removeStockAdjustmentsProduct=(index:number)=>
{
debugger;
let lstcStockAdjustmentsChildProducts=[...lstStockAdjustmentsChildProducts];
lstcStockAdjustmentsChildProducts.splice(index,1);
setStockAdjustmentsChildProducts(lstcStockAdjustmentsChildProducts)
}

const StockAdjustmentsChildEdit=(data:any,index:number)=>
{
setSelectedProduct(data);
}





const loadProduct = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
    debugger;
    const filteredOptions = filterProduct(inputValue);
    callback(filteredOptions);
};

const filterProduct = (inputValue: string) => {
    debugger;
    if (inputValue != '') {
        return lstProducts.filter((option: any) => {
            return option.label.toLowerCase().includes(inputValue.toLowerCase());
        }
        );
    } else {
        return lstProducts;
    }
};


useEffect(()=>
{

    viewProducts();
},[alert])
const viewProducts=()=>
{


repository.ViewProducts('').then((result:any)=>
{
debugger;

// let StockAdjustmentsProducts=result.filter((x:any)=> x.ProductType=='StockAdjustments');

// setStockAdjustmentsProducts(StockAdjustmentsProducts);


// let products=result.filter((x:any)=> x.ProductType!='StockAdjustments');
setProducts(result);

})

}

const [selectedProduct,setSelectedProduct]=useState({product_details_id:0,qty:0,stock_adjustment_product_id:0,title:''});

const onProductQtyChange=(e:any)=>
{
    debugger;
    let sproduct={...selectedProduct};

sproduct.qty=e.target.value;

setSelectedProduct(sproduct);

}
const [lstProducts,setProducts]=useState([]);


const onProductChange=(e:any)=>
{
    debugger;
let sproduct={...selectedProduct};

sproduct.product_details_id=e.product_details_id;
sproduct.title=e.title;
setSelectedProduct(sproduct);

}
const addProductDetails=(type:any)=>
{

    if(validateProduct())
    {

        let clstStockAdjustmentsChildProducts:any=[...lstStockAdjustmentsChildProducts];

        clstStockAdjustmentsChildProducts.push(selectedProduct);
        setStockAdjustmentsChildProducts(clstStockAdjustmentsChildProducts);
        setSelectedProduct({product_details_id:0,qty:0,stock_adjustment_product_id:0,title:''});
        if(type=='add')
        {
            $("#addProductModelClose").click();
        }

    }

}

const validateProduct=():any=>
{
    debugger;
let validate:any=true;


if(selectedProduct.product_details_id<=0 && validate )
{
    validate=false;
    Swal.fire("Select Product!", "", "error");
}

if(  selectedProduct.qty<=0  && validate)
{
    validate=false;
    Swal.fire("Invalid Qty!", "", "error");
}
if(    validate)
{

  let index=  lstStockAdjustmentsChildProducts.findIndex((x:any)=> x.product_details_id==selectedProduct.product_details_id);

  if(index>-1 && validate)
  {

    validate=false;
    Swal.fire("Products already exist!", "", "error");
  }
}
  return validate;
}

const validateStockAdjustments=():any=>
{
    let validate:any=true;

    if(selectedStockAdjustments.type =='Select'
    && validate )
    {
        validate=false;
        Swal.fire("Select Stcok Adjustment Type!", "", "error");
    }
    
    if(  selectedStockAdjustments.date==''  && validate)
    {
        validate=false;
        Swal.fire("Select Date!", "", "error");
    }
    if(lstStockAdjustmentsChildProducts.length==0 && validate)
    {
        validate=false;
        Swal.fire("Add StockAdjustments Products!", "", "error");

    }
    
    // else{

    //     selectedStockAdjustments.stock_adjustment_product_details=lstStockAdjustmentsChildProducts;
    // }
    return  validate;
}

const onDateChange=(e:any)=>
{
   let cselectedStockAdjustments={...selectedStockAdjustments};
   cselectedStockAdjustments.date=e.target.value;
   setSelectedStockAdjustments(cselectedStockAdjustments);
}
const onTypeChange=(e:any)=>
{
    debugger;
   let cselectedStockAdjustments={...selectedStockAdjustments};
   cselectedStockAdjustments.type=e.target.value;
   setSelectedStockAdjustments(cselectedStockAdjustments);
}

return {onTypeChange,onDateChange,addProductDetails,selectedProduct,onProductQtyChange,loadProduct,onProductChange,lstProducts,StockAdjustmentsChildEdit,lstStockAdjustmentsChildProducts,removeStockAdjustmentsProduct,addProduct,getStockAdjustments,onNotesChange,saveStockAdjustments,selectedStockAdjustments,deleteStockAdjustments,edit,filteredProductStockAdjustments,handleSearch,searchTerm,createStockAdjustments}
}