import { useEffect, useState } from "react";
import { EcommerceRepository } from "../../repository/EcommerceRepository";
import Swal from "sweetalert2";

export function MerchantViewModel(){

    const ecommerceRepository=EcommerceRepository();

    const [lstMerchant, setMerchants] = useState([{
        merchant_id : 0,
        merchant_legal_name : '',
        reg_no : '',
        business_category : '',
        currency : '',
        merchant_name : '',
        website : '',
        login_password : '',
        address_name : '',
        phone_number : '',
        alternate_phone_number : '',
        pincode : '',
        state_id : 0,
        state : '',
        city : '',
        house_no_bulding_name : '',
        road_area_colony : '',
        email_id : '',
        fax_no : '',
        bank_name : '',
        bank_branch : '',
        ifsc_code : '',
        bank_account : '',
        verified_by : 0,
        status : 0,
        updated_date : ''
    }]) 

    const [lstStates, setStates] = useState([{
        state_id : 0,
        state_code : '',
        state_name : '',
    }]) 

    // const [lstFilterdMerchant, setFilteredMerchant] = useState(
    //     []
    // )

    const [merchant, setMerchant] = useState({
        merchant_id : 0,
        merchant_legal_name : '',
        reg_no : '',
        business_category : '',
        currency : '',
        merchant_name : '',
        website : '',
        login_password : '',
        address_name : '',
        phone_number : '',
        alternate_phone_number : '',
        pincode : '',
        state_id : 0,
        state : '',
        city : '',
        house_no_bulding_name : '',
        road_area_colony : '',
        email_id : '',
        fax_no : '',
        bank_name : '',
        bank_branch : '',
        ifsc_code : '',
        bank_account : '',
        verified_by : 0,
        status : 0,
        updated_date : ''
    }) 

    const [validate, setValidate] = useState({
        merchant_id : 0,
        merchant_legal_name : '',
        reg_no : '',
        business_category : '',
        currency : '',
        merchant_name : '',
        website : '',
        login_password : '',
        address_name : '',
        phone_number : '',
        alternate_phone_number : '',
        pincode : '',
        state_id : 0,
        state : '',
        city : '',
        house_no_bulding_name : '',
        road_area_colony : '',
        email_id : '',
        fax_no : '',
        bank_name : '',
        bank_branch : '',
        ifsc_code : '',
        bank_account : '',
        verified_by : 0,
        status : 0,
        updated_date : ''
    }) 

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: any) => {
        debugger;
        let filteredMerchant = lstMerchant.filter((merc: any) =>
        merc.merchant_legal_name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setMerchants(filteredMerchant);
        setSearchTerm(e.target.value);

        if(e.target.value == ""){
            viewMerchant();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setMerchant((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setMerchant((prev) => ({ ...prev, [name]: value }));
        }
    }

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filterMerchants = lstMerchant.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstMerchant.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);   

    // function prePage() {
    //     if (currentPage != 1) {
    //         debugger;
    //         setCurrentPage(currentPage - 1);
    //     }
    // }

    // function nextPage() {
    //     if (currentPage != npage) {
    //         debugger;
    //         setCurrentPage(currentPage + 1);
    //     }
    // }

    // function changePage(id: React.SetStateAction<number>) {
    //     debugger;
    //     setCurrentPage(id);
    // }

    const saveMerchant = async (e: React.FormEvent<HTMLFormElement>) =>{
        debugger;
        e.preventDefault();

        const newValidate = { ...validate };

        if(!merchant.merchant_legal_name.trim()){
            newValidate.merchant_legal_name = "Required!";
        }
        else{
            newValidate.merchant_legal_name ='';
        }
        if(!merchant.reg_no.trim()){
            newValidate.reg_no = "Required!";
        } 
        else{
            newValidate.reg_no ='';
        }
        if(!merchant.business_category.trim()){
            newValidate.business_category = "Required!";
        } 
        else{
            newValidate.business_category ='';
        }
        if(!merchant.currency.trim()){
            newValidate.currency = "Required!";
        } 
        else{
            newValidate.currency ='';
        }
        if(!merchant.merchant_name.trim()){
            newValidate.merchant_name = "Required!";
        }
        else{
            newValidate.merchant_name ='';
        }

        if(!merchant.login_password.trim()){
            newValidate.login_password = "Required!";
        }
        else{
            newValidate.login_password ='';
        }
        if(!merchant.address_name.trim()){
            newValidate.address_name = "Required!";
        }
        else{
            newValidate.address_name ='';
        }
        if(!merchant.phone_number.trim()){
            newValidate.phone_number = "Required!";
        }
        else{
            newValidate.phone_number ='';
        }
        if(!merchant.pincode.trim()){
            newValidate.pincode = "Required!";
        }
        else{
            newValidate.pincode ='';
        }
        if((merchant.state_id <= 0)){
            newValidate.state = "Required!";
        }
        else{
            newValidate.state = "";
        }
        if(!merchant.city.trim()){
            newValidate.city = "Required!";
        }
        else{
            newValidate.city ='';
        }
        if(!merchant.email_id.trim()){
            newValidate.email_id = "Required!";
        }
        else{
            newValidate.email_id ='';
        }
        if(!merchant.bank_name.trim()){
            newValidate.bank_name = "Required!";
        }
        if(!merchant.bank_branch.trim()){
            newValidate.bank_name= "Required!";
        }
        else{
            newValidate.bank_name ='';
        }
        if(!merchant.ifsc_code.trim()){
            newValidate.ifsc_code = "Required!";
        }
        else{
            newValidate.ifsc_code ='';
        }
        if(!merchant.bank_account.trim()){
            newValidate.bank_account = "Required!";
        }
        else{
            newValidate.bank_account ='';
        }

        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger;
                const response:any = await ecommerceRepository.saveMerchant(merchant);
                if (response[0].db_result > 0) {
                    cancel();
                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;
                    if (buttonElement) {
                        buttonElement.click();
                    }
                    viewMerchant();
                }
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            }
            catch(error){

            }
        }else{
            setValidate(newValidate);
        }
        
    }

    const viewMerchant = async () => {
        debugger;
        try {
            debugger;
            const data:any = await  ecommerceRepository.viewMerchant();
            setMerchants(data)
            // setFilteredMerchant(data);
        }
        catch (error) {
            setMerchants([])
        }
    } 

    const viewState = async () => {
        debugger;
        try {
            debugger;
            const data:any = await  ecommerceRepository.viewState('');
            debugger;
            setStates(data)
        }
        catch (error) {
            setMerchants([])
        }
    } 

    const editMerchant = (e: any) => {
        debugger;
        setMerchant(e);
    }

    const deleteMerchant = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
            const responseData :any= await ecommerceRepository.deleteMerchant({ 'Merchant_id': e });
            if (responseData[0].db_result > 0) {
                debugger;
                Swal.fire("Record Deleted successfully", "", "success");
                viewMerchant();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }

    const cancel = () => {
        debugger;

        setMerchant({
            merchant_id : 0,
            merchant_legal_name : '',
            reg_no : '',
            business_category : '',
            currency : '',
            merchant_name : '',
            website : '',
            login_password : '',
            address_name : '',
            phone_number : '',
            alternate_phone_number : '',
            pincode : '',
            state_id : 0,
            state : '',
            city : '',
            house_no_bulding_name : '',
            road_area_colony : '',
            email_id : '',
            fax_no : '',
            bank_name : '',
            bank_branch : '',
            ifsc_code : '',
            bank_account : '',
            verified_by : 0,
            status : 0,
            updated_date : ''
        })

        setValidate({
            merchant_id : 0,
            merchant_legal_name : '',
            reg_no : '',
            business_category : '',
            currency : '',
            merchant_name : '',
            website : '',
            login_password : '',
            address_name : '',
            phone_number : '',
            alternate_phone_number : '',
            pincode : '',
            state_id : 0,
            state : '',
            city : '',
            house_no_bulding_name : '',
            road_area_colony : '',
            email_id : '',
            fax_no : '',
            bank_name : '',
            bank_branch : '',
            ifsc_code : '',
            bank_account : '',
            verified_by : 0,
            status : 0,
            updated_date : ''
        })

    }

    useEffect(() => {
        viewMerchant();
        viewState();
    }, [alert]); 

    return{lstMerchant,
        // lstFilterdMerchant,
        merchant,validate,searchTerm,
        // prePage,nextPage,changePage,
        handleSearch,saveMerchant,
        deleteMerchant,
        editMerchant,
        handleChange,
        cancel,
        filterMerchants,
        numbers,
        setCurrentPage,
        lstStates, setStates
        // filtereDiscs,numbers
    }
    
}