import React from "react";
import AsyncSelect from "react-select/async";
import { CreatePurchaseViewModel } from "./Createpurchaseinvoicemodel";
import AddProduct from "../AddProduct/AddProduct";
import { AddProductViewModel } from "../AddProduct/AddProductmodel";

const CreatePurchaseInvoiceView: React.FC<{ details: any }> = ({ details }) => {
    const purchaseViewModel = CreatePurchaseViewModel();
    //  const addProductModel = AddProductViewModel();
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">Create Purchase Invoice</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Purchase Invoice </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        {/* <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                                <label>Search:<input type="search" className="form-control form-control-sm"
                                                    placeholder="" aria-controls="products-datatable" /></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#standard-modal">
                                                <i className="mdi mdi-plus-circle me-2"></i> Add Brands
                                            </a>
                                        </div> */}
                                        <div className="mb-2 col-md-12">
                                            <div className="modal-body">
                                                <form onSubmit={purchaseViewModel.savePurchaseInvoice}>
                                                    <div className="row">
                                                        <div className="col-md-2 mb-2">
                                                            <label className="form-label">Merchant</label>
                                                            <AsyncSelect
                                                                isClearable
                                                                isSearchable
                                                                cacheOptions
                                                                defaultOptions={purchaseViewModel.lstMerchantDrp}
                                                                onChange={purchaseViewModel.merchantChange}
                                                                value={purchaseViewModel.lstMerchantDrp.length > 0 ? (purchaseViewModel.lstMerchantDrp.find((option: any) => option.value == purchaseViewModel.purchaseInvoice.merchant_id) || null) : null}
                                                                loadOptions={purchaseViewModel.loadMerchant}
                                                            />
                                                        </div>
                                                        <div className="col-md-2 mb-2">
                                                            <label className="form-label">Invoice No:</label>
                                                            <input className="form-control" id="invoice_no" placeholder="Invoice no" name="invoice_no" value={purchaseViewModel.purchaseInvoice.invoice_no} onChange={purchaseViewModel.invoiceChange} />
                                                        </div>
                                                        <div className="col-md-2 mb-3">
                                                            <label className="form-label">Bill Referance: </label>
                                                            <input className="form-control" id="bill_ref" placeholder="Bill ref" name="bill_ref" value={purchaseViewModel.purchaseInvoice.bill_ref} onChange={purchaseViewModel.invoiceChange} />
                                                        </div>
                                                        <div className="col-md-2 mb-2">
                                                            <label className="form-label">Date:</label>
                                                            <input className="form-control" type="date" placeholder="date" name="date"
                                                                value={purchaseViewModel.purchaseInvoice.date} onChange={purchaseViewModel.invoiceChange} />
                                                        </div>

                                                        <div className="col-md-4 text-end">
                                                          
                                                            <button className="btn btn-primary mx-2" type="submit" onClick={purchaseViewModel.getinvoice}>View Invoice</button>
                                                           
                                                            <button className="btn btn-primary mx-2" type="submit">Save</button>
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <h3 className="d-flex">Products</h3>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <button className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={purchaseViewModel.addNew}> + Add </button>
                                                    </div>

                                                </div>

                                                {/* <div className="row">
                                                    <div className="col-md-2 mb-2">
                                                        <label className="form-label">Product</label>
                                                        <AsyncSelect
                                                            isClearable
                                                            isSearchable
                                                            cacheOptions
                                                            defaultOptions={purchaseViewModel.lstProductsDrp}
                                                            onChange={purchaseViewModel.productChange}
                                                            value={purchaseViewModel.lstProductsDrp.length > 0 ? (purchaseViewModel.lstProductsDrp.find((option: any) => option.value == purchaseViewModel.pIDetails.product_details_id) || null) : null}
                                                            loadOptions={purchaseViewModel.loadProduct}
                                                        />
                                                        {<div className="text-danger">{purchaseViewModel.pIDetailsValidate.product_details_id}</div>}
                                                    </div>
                                                    <div className="col-md-1 mb-2">
                                                        <label className="form-label">Price</label>
                                                        <input type="number" className="form-control" id="inputprice" name="price" value={purchaseViewModel.pIDetails.price} onChange={purchaseViewModel.detailsChange} />
                                                        {<div className="text-danger">{purchaseViewModel.pIDetailsValidate.price}</div>}
                                                    </div>

                                                    <div className="col-md-1 mb-2">
                                                        <label className="form-label">Quantity</label>
                                                        <input type="number" className="form-control" id="inputquantity" name="qty" value={purchaseViewModel.pIDetails.qty} onChange={purchaseViewModel.detailsChange} />
                                                        {<div className="text-danger">{purchaseViewModel.pIDetailsValidate.qty}</div>}
                                                    </div>

                                                    <div className="col-md-1 mb-2">
                                                        <label className="form-label">Discount</label>
                                                        <input type="number" className="form-control" id="inputdiscount" name="discount" value={purchaseViewModel.pIDetails.discount} onChange={purchaseViewModel.detailsChange} />
                                                    </div>

                                                    <div className="col-md-1 mb-2">
                                                        <label className="form-label">Gross Amount</label>
                                                        <input type="number" className="form-control" id="inputgrossamount" name="gross" value={purchaseViewModel.pIDetails.gross} onChange={purchaseViewModel.detailsChange} />
                                                    </div>

                                                    <div className="col-md-1 mb-2">
                                                        <label className="form-label">Tax %</label>
                                                        <input type="number" className="form-control" id="inputtax" name="tax_per" value={purchaseViewModel.pIDetails.tax_per} onChange={purchaseViewModel.detailsChange} />
                                                    </div>

                                                    <div className="col-md-1 mb-2">
                                                        <label className="form-label">Tax amount</label>
                                                        <input type="number" className="form-control" id="inputtaxamount" name="tax" value={purchaseViewModel.pIDetails.tax} onChange={purchaseViewModel.detailsChange} />
                                                    </div>

                                                    <div className="col-md-1 mb-2">
                                                        <label style={{ 'visibility': 'hidden' }} className="form-label">aa</label><br />
                                                        <a className="btn btn-danger mb-2" onClick={purchaseViewModel.addDetails}>
                                                            Add <i className="mdi mdi-plus-circle mx-1"></i>
                                                        </a>
                                                    </div>
                                                </div> */}
                                                <div className="mb-2 col-md-12 mt-3">
                                                    <div className="table-responsive-sm">
                                                        <table className="table table-centered mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th>S.No</th>
                                                                    <th>Product</th>
                                                                    <th>Price</th>
                                                                    <th>Quantity</th>
                                                                    <th>Discount</th>
                                                                    <th>Tax</th>
                                                                    <th>Gross Amount</th>
                                                                    <th>Total</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    purchaseViewModel.purchaseInvoiceForPopupDetails && purchaseViewModel.purchaseInvoiceForPopupDetails.map((d: any, index: number) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{d.product_name}</td>
                                                                            <td>{d.price}</td>
                                                                            <td>{d.qty}</td>
                                                                            <td>{d.discount}</td>
                                                                            <td>{d.tax}</td>
                                                                            <td>{d.gross}</td>
                                                                            <td>{d.net}</td>
                                                                            <td>
                                                                                <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => purchaseViewModel.edit(d)}></i>
                                                                                <i className="mx-2 uil uil-trash-alt" onClick={() => purchaseViewModel.remove(d.product_details_id)}></i>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-10 mb-2">

                                                    </div>
                                                    <div className="col-md-2 mb-2">
                                                        <div>
                                                            <label className="form-label">Gross Amount :</label>
                                                            {purchaseViewModel.purchaseInvoice.gross}
                                                        </div>
                                                        <div>
                                                            <label className="form-label">Discount:</label>
                                                            {purchaseViewModel.purchaseInvoice.discount}
                                                        </div>
                                                        <div>
                                                            <label className="form-label">Total :</label>
                                                            {purchaseViewModel.purchaseInvoice.net_total}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="standard-modal" className="modal fade " tabIndex={-1} aria-labelledby="standard-modalLabel" aria-modal="true" role="dialog">
                <div className="modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="standard-modalLabel">Add Product</h4>
                            <button type="button" id="addProductModelClose" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <AddProduct onProductAdd={purchaseViewModel.handleAddButtonClick }
                                data={purchaseViewModel.purchaseInvoiceDetails}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CreatePurchaseInvoiceView