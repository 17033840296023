import React from "react";
import AsyncSelect from "react-select/async";
import { PurchaseViewModel } from "./Purchaseinvoicemodel";

const PurchaseView: React.FC = () => {
    const purchaseViewModel = PurchaseViewModel();
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">Purchase Invoice</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Purchase Invoice </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                            <label>Search:<input type="search" className="form-control form-control-sm" value={purchaseViewModel.searchTerm} onChange={purchaseViewModel.handleSearch} /></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2" onClick={purchaseViewModel.newInvoice}>
                                                <i className="mdi mdi-plus-circle me-2"></i> Add Purchase Invoice
                                            </a>
                                        </div>
                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Invoice No</th>
                                                            <th>Date</th>
                                                            <th>Tax Amount</th>
                                                            <th>Discount</th>
                                                            <th>Total</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            purchaseViewModel.filteredProductInv && purchaseViewModel.filteredProductInv.map((d: any, index: number) => (
                                                                <tr key={index}>
                                                                    <td>{index+1}</td>
                                                                    <td>{d.invoice_no}</td>
                                                                    <td>{d.date}</td>
                                                                    <td>{d.tax}</td>
                                                                    <td>{d.discount}</td>
                                                                    <td>{d.net_total}</td>
                                                                    <td>
                                                                        <i onClick={(e)=>purchaseViewModel.edit(d)} className="uil uil-edit-alt"></i>
                                                                        <i onClick={(e)=>purchaseViewModel.deleteproduct(d)} className="mx-2 uil uil-trash-alt"></i>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default PurchaseView;