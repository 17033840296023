import React from "react";
import AsyncSelect from "react-select/async";
import { AddProductViewModel } from "./AddProductmodel";

const AddProduct: React.FC<{onProductAdd:(productDetails:any)=>void,data:any}> = ({onProductAdd,data}) => {
    const addProductModel = AddProductViewModel(onProductAdd,data);
    return (
        <div className="row">
            <div className="col-md-12 mb-2">
                <label className="form-label">Product</label>
                <AsyncSelect
                    isClearable
                    isSearchable
                    cacheOptions
                    defaultOptions={addProductModel.lstProductsDrp}
                    onChange={addProductModel.productChange}
                    value={addProductModel.lstProductsDrp.length > 0 ? (addProductModel.lstProductsDrp.find((option: any) => option.value == addProductModel.pIDetails.product_details_id) || null) : null}
                    loadOptions={addProductModel.loadProduct}
                />
                {<div className="text-danger">{addProductModel.pIDetailsValidate.product_details_id}</div>}
            </div>
            <div className="col-md-6 mb-2">
                <label className="form-label">Price</label>
                <input type="number" className="form-control" id="inputprice" name="price" value={addProductModel.pIDetails.price} onChange={addProductModel.detailsChange} />
                {<div className="text-danger">{addProductModel.pIDetailsValidate.price}</div>}
            </div>

            <div className="col-md-6 mb-2">
                <label className="form-label">Quantity</label>
                <input type="number" className="form-control" id="inputquantity" name="qty" value={addProductModel.pIDetails.qty} onChange={addProductModel.detailsChange} />
                {<div className="text-danger">{addProductModel.pIDetailsValidate.qty}</div>}
            </div>

            <div className="col-md-6 mb-2">
                <label className="form-label">Gross Amount</label>
                <input type="number" className="form-control" id="inputgrossamount" 
                name="gross" value={addProductModel.pIDetails.gross} onChange={addProductModel.detailsChange} disabled/>
            </div>

            <div className="col-md-6 mb-2">
                <label className="form-label">Discount</label>
                <input type="number" className="form-control" id="inputdiscount" name="discount" value={addProductModel.pIDetails.discount} onChange={addProductModel.detailsChange} />
            </div>

            

            <div className="col-md-6 mb-2">
                <label className="form-label">Tax %</label>
                <input type="number" className="form-control" id="inputtax" name="tax_per" value={addProductModel.pIDetails.tax_per} onChange={addProductModel.detailsChange} />
            </div>

           

            <div className="col-md-6 mb-2">
                <label className="form-label">Tax amount</label>
                <input type="number" className="form-control" id="inputtaxamount" name="tax" value={addProductModel.pIDetails.tax} onChange={addProductModel.detailsChange} />
            </div>

            <div className="col-md-6 mb-2">
                <label className="form-label">Net amount</label>
                <input type="number" className="form-control" id="inputtaxamount" name="net" value={addProductModel.pIDetails.net} onChange={addProductModel.detailsChange} />
            </div>



            <div className="col-md-12 mt-2 text-end">
               
                <a className="btn btn-danger mb-2" onClick={(e)=>addProductModel.addDetails('add')}>
                    Add <i className="mdi mdi-check mx-1"></i>
                </a>

                <a className="btn btn-danger mb-2 mx-2" onClick={(e)=>addProductModel.addDetails('addPlus')}>
                    Add <i className="mdi mdi-plus mx-1"></i>
                </a>
            </div>
        </div>
    )
}

export default AddProduct;