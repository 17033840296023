import { useEffect, useState } from "react";
import { EcommerceRepository } from "../../repository/EcommerceRepository";
import Swal from "sweetalert2";

export function DiscountViewModel() {

    const ecommerceRepository = EcommerceRepository();

    // const [lstDiscount, setDiscounts] = useState({
    //     data: []
    // }) 

    const [lstDiscount, setDiscounts] = useState(
        [{
            discount_id: 0,
            discount_name: '',
            discount_type: '',
            start_date: '',
            end_date: '',
            max_order_value: 0,
            max_discount_amount: 0,
            discount_percentage: 0,
            coupon_code: '',
            no_of_applicables: 0,
            updated_date: ''
        }]
    )
    const [lstLoadProdDrp, setLoadProdDrp] = useState<{ label: string; value: string }[] | []>([]);
    const [lstProductsDrp, setProductsDrp] = useState<{ label: string; value: string }[] | []>([]);

    const [products, setproducts] = useState([{

        product_id: 0,
        title: '',
        discount_id: 0,

    }]);

    const [product, setproduct] = useState({
        product_discount_id:0,
        product_id: 0,
        title: '',
        discount_id: 0,
    })

    const [index, setIndex] = useState(-1);
    const [productFeilds, setproductFeilds] = useState([{
        product_discount_id: 0, // PK
        product_id: 0,
        discount_id: 0,
        title: '',
    }]);

    const [newProduct, setNewProduct] = useState({
        product_id: 0,
        title: '',
        discount_id: 0,
    })



    const handleProductsChange = (selectedOption: any | null) => {
        debugger;
        setproduct(prevsetsetproducts => ({
            ...prevsetsetproducts,
            product_id: selectedOption ? selectedOption.value : '',
            title: selectedOption ? selectedOption.label : '',
        }));
        console.log(products);
    };

    const [discount, setDiscount] = useState({
        discount_id: 0,
        discount_name: '',
        discount_type: '',
        start_date: '',
        end_date: '',
        max_order_value: 0,
        max_order_value_validate: '',
        max_discount_amount: 0,
        discount_percentage: 0,
        coupon_code: '',
        no_of_applicables: 0,
        product_discount_id: 0,
        updated_date: '',
       // lstProducts: [] as any
    })

    const [validate, setValidate] = useState({
        discount_id: 0,
        discount_name: '',
        discount_type: '',
        start_date: '',
        end_date: '',
        max_order_value: 0,
        max_order_value_validate : '',
        max_discount_amount: '',
        discount_percentage: '',
        coupon_code: '',
        no_of_applicables: '',
        product_discount_id: 0,
        updated_date: ''
    })

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: any) => {
        debugger;
        let filtereDiscs = lstDiscount.filter((discount: any) =>
            discount.discount_name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setDiscounts(filtereDiscs);
        setSearchTerm(e.target.value);

        if (e.target.value == '') {
            viewDiscount();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setDiscount((prev) => ({ ...prev, [name]: value }));
        } else {
            debugger;
            const { name, value } = e.target as HTMLSelectElement;
            setDiscount((prev) => ({ ...prev, [name]: value }));
        }
    }




    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filtereDiscs = lstDiscount.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstDiscount.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            debugger;
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    }  

    const loadProducts = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterproducts(inputValue);
        callback(filteredOptions);
    };

    const filterproducts = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstLoadProdDrp.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstLoadProdDrp;
        }
    };

    const addProducts = () => {

        debugger;
        if (index > -1) {
            productFeilds[index].title = product.title;
            setproductFeilds([...productFeilds]);
            setIndex(-1)
        } else {
            let checkFeild = productFeilds.filter((x: any) => x.title === product.title);

            // if(categoryFeild.category_field_name != ''){
            if (checkFeild.length == 0) {
                debugger;
                // varient.variant_name = newvarient.variant_name;
                setproductFeilds((prevRecords: any) => [...prevRecords, product]);
                //setvarientFeildSubmit(false);

                setproduct({
                    product_discount_id:0,
                    product_id: 0,
                    title: '',
                    discount_id: 0,
                })

            } else {

                for (let i = 0; i < productFeilds.length; i++) {
                    debugger;
                    if (productFeilds[i].product_id == product.product_id) {
                        debugger;
                        // categoryFeilds[i].is_required = categoryFeild.is_required;
                        productFeilds[i].title = product.title;
                        break;
                    }
                }

                setproductFeilds([...productFeilds]);

            }
        }

    }





    const saveDiscount = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const newValidate = { ...validate };

        let productFeildind = productFeilds.findIndex(x => (x.title == ''));
        if (productFeildind > -1) {
            productFeilds.splice(productFeildind, 1);
            // varientGroup.varient_fields = varientFeilds

        }

        if(!discount.discount_name.trim()){
            newValidate.discount_name = "Discount name required!"; 
        }else{
            newValidate.discount_name = ""; 
        }

        if(!discount.discount_type.trim()){
            newValidate.discount_type = "Discount type required!"; 
        }
        else{
            newValidate.discount_type = ""; 

        }

        if(!discount.start_date.trim()){
            newValidate.start_date = "Start date required!"; 
        }
        else{
            newValidate.start_date = ""; 
        }

        if(!discount.end_date.trim()){
            newValidate.end_date = "End date required!"; 
        }else{
            newValidate.end_date = ""; 
        }


        if(!discount.coupon_code.trim()){
            newValidate.coupon_code = "Coupon code required!"; 
        }else{
            newValidate.coupon_code = ""; 
        }

        if(!(discount.no_of_applicables > 0)){
            newValidate.no_of_applicables = "Required!"; 
        } else{
            newValidate.no_of_applicables = ""; 
        }


        if(!(discount.max_order_value > 0)){
            newValidate.max_order_value_validate = "Required!"; 
            setValidate(newValidate);
        } else{
            newValidate.max_order_value_validate = ""; 
        }

        if(!(discount.max_discount_amount > 0)){
            newValidate.max_discount_amount = "Required!"; 
        } else{
            newValidate.max_discount_amount = ""; 
        }

        if(!(discount.discount_percentage > 0)){
            newValidate.no_of_applicables = "Required!"; 
        }else{
            newValidate.no_of_applicables = '';
        }
       
       
        setValidate(newValidate);

        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger;
              
                const response: any = await ecommerceRepository.saveDiscount(discount,productFeilds);
                debugger;
                if (response[0].db_result > 0) {

                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;
                    if (buttonElement) {
                        buttonElement.click();
                    }
                    cancel();
                    viewDiscount();
                }
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            }
            catch (error) {

            }
        } else {
            setValidate(newValidate);
        }

    }

    const viewDiscount = async () => {
        debugger;
        try {
            debugger;
            const data: any = await ecommerceRepository.viewDiscount('');
            debugger;
            setDiscounts(data)
        }
        catch (error) {
            setDiscounts(
                []
            )
        }
    }


    const viewProducts = async () => {
        debugger;
        try {
            debugger;
            const data: any = await ecommerceRepository.ViewProducts('');

            debugger;

            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.title,
                value: item.product_details_id.toString(),
            }));

            setProductsDrp(options)
        }
        catch (error) {
            setProductsDrp(
                []
            )
        }
    }


    const editDiscount = (e: any) => { 
        debugger; 

        let productFeilds = JSON.parse( ((e.Products.replace(/\n/g, "")).replace(/'/g,"\"")));
        setproductFeilds([...productFeilds])
        setDiscount(e);
    } 

    const removeProductFeild = (e: any) => {
        debugger;
        let productFeildind = productFeilds.findIndex(x => (x.title == e.title));
        productFeilds.splice(productFeildind, 1);
      // varientGroup.variant_id.toString() = [varientFeilds]
      setproductFeilds([...productFeilds])

    }


    const deleteDiscount = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
            const responseData: any = await ecommerceRepository.deleteDiscount({ 'discount_id': e });
            if (responseData[0].db_result > 0) {
                debugger;
                Swal.fire("Record Deleted successfully", "", "success");
                viewDiscount();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }

    const cancel = () => {
        debugger;

        setDiscount({
            discount_id: 0,
            discount_name: '',
            discount_type: '',
            start_date: '',
            end_date: '',
            max_order_value: 0,
            max_discount_amount: 0,
            max_order_value_validate:'',
            discount_percentage: 0,
            coupon_code: '',
            no_of_applicables: 0,
            product_discount_id: 0,
            updated_date: '',
           // lstProducts: [] 
        }) 
        setproductFeilds([{
            product_discount_id: 0, 
            product_id: 0,
            discount_id: 0,
            title: '',
        }])

        setValidate({
            discount_id: 0,
            discount_name: '',
            discount_type: '',
            start_date: '',
            end_date: '',
            max_order_value: 0,
            max_order_value_validate:'',
            max_discount_amount: '',
            discount_percentage: '',
            coupon_code: '',
            no_of_applicables: '',
            product_discount_id: 0,
            updated_date: ''
        })

    }


    const newdisountclose = () => {
        debugger;
        cancel();

    }


    useEffect(() => {
        viewDiscount();
        viewProducts();

    }, [alert]);

    return {
        lstDiscount,
        // lstFilterdDiscount,
        discount, validate, searchTerm,
        // prePage,nextPage,changePage,
        handleSearch, saveDiscount,
        deleteDiscount,
        editDiscount,
        handleChange,
        filtereDiscs,cancel,
        numbers, lstProductsDrp, products, handleProductsChange, loadProducts, addProducts, viewProducts,
         product, productFeilds,removeProductFeild,newdisountclose,prePage,nextPage,changePage,currentPage
    }
}