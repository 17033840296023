import { useEffect, useState } from "react"
import { EcommerceRepository } from "../../repository/EcommerceRepository"

export function  RaisePickupsModel()
{
const  repository=EcommerceRepository()


useEffect(()=>
{
 
    getRaisePickupList();
},[alert])
const getCurrentDate=()=> {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let month:any = currentDate.getMonth() + 1;
    let day:any= currentDate.getDate();
  
    // Add leading zero to single-digit months and days
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;
  
    return `${year}-${month}-${day}`;
  }
const [package_count,setTotalOrders]=useState(0);
const [pickup_date,setPackageDate]=useState(getCurrentDate());
const [pickup_time,setPackageTime]=useState();

const getRaisePickupList=async ()=>
{
    debugger;
    const data=  await repository.getRaisePickupList();
    debugger;
    if(data.length>0)
    {
        setTotalOrders(data[0].TotalOrders);
    }
}
const onPickupDateChange=(e:any)=>
{
    setPackageDate(e.target.value);

}
const onPickupTimeChange=(e:any)=>
{

    setPackageTime(e.target.value);

}


const onRaisePickupClick=async ()=>
{
if((+package_count)>0 && pickup_date!='' && typeof( pickup_time)!='undefined' &&  pickup_time!='')
{
   const result= await repository.raisePickup({package_count:package_count,pickup_date:pickup_date,pickup_time:pickup_time});
 
    if (result.pickup_id != '' && result.pickup_date != '' && typeof (result.pickup_date) != 'undefined') {
        (window as any).swal({
            type: 'success',
            title: 'Information!',
            text: 'Raised pickup successfully.',
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-lg btn-success'
          });
        } else if (typeof (result.pickup_time) != 'undefined') {
  
            (window as any).swal({
              type: 'warning',
              title: 'Information',
              text: `${result.pickup_time}!..`,
              confirmButtonText: 'Dismiss',
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-lg btn-warning'
            });
          }
          else if (typeof (result.data) != 'undefined') {

            (window as any).swal({
              type: 'warning',
              title: 'Information',
              text: `${result.data.message}!..`,
              confirmButtonText: 'Dismiss',
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-lg btn-warning'
            });

          }
          else if (typeof (result.prepaid) != 'undefined') {

            (window as any).swal({
              type: 'warning',
              title: 'Information',
              text: `${result.prepaid}!..`,
              confirmButtonText: 'Dismiss',
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-lg btn-warning'
            });
          }


}

    debugger;

}

    return{onRaisePickupClick,onPickupDateChange,onPickupTimeChange,package_count,pickup_date,pickup_time,getCurrentDate}
}