import React from "react";
import AsyncSelect from "react-select/async";
 
import { KitModel } from "./KitModel";

const CreateKit: React.FC<{ details: any }> = ({ details }) => {
    const kitViewModel = KitModel();
    //  const addProductModel = AddProductViewModel();
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">Create Kit</li>
                                    </ol>
                                </div>
                                <h3 className="page-title">Kit </h3>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        
                                        <div className="mb-2 col-md-12">
                                            <div className="modal-body">
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-2 mb-2">
                                                            <label className="form-label">Kit Product</label>
                                                            <AsyncSelect
                                                                isClearable
                                                                isSearchable
                                                                cacheOptions
                                                                defaultOptions={kitViewModel.lstKitProducts}
                                                                onChange={kitViewModel.onKitProductChange}
                                                                value={kitViewModel.lstKitProducts.length > 0 ? (kitViewModel.lstKitProducts.find((option: any) => option.value == kitViewModel.selectedKit.product_details_id) || null) : null}
                                                                loadOptions={kitViewModel.loadKit}
                                                            />
                                                        </div>
                                                        <div className="col-md-2 mb-2">
                                                            <label className="form-label">Qty:</label>
                                                            <input className="form-control" id="qty" placeholder="Qty" name="qty"
                                                             value={kitViewModel.selectedKit.qty} onChange={kitViewModel.onQtyChange} />
                                                        </div>
                                                        <div className="col-md-2 mb-2"></div>

                                                        
                                                        

                                                        <div className="col-md-4 text-end">
                                                          
                                                            <button className="btn btn-primary mx-2" type="submit" onClick={kitViewModel.getKit}>View Kit</button>
                                                           
                                                            <button className="btn btn-primary mx-2" type="submit" onClick={kitViewModel.saveKit}>Save</button>
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">Notes: </label>

                                                            <textarea className="form-control"  id="notes" value={kitViewModel.selectedKit.notes} onChange={kitViewModel.onNotesChange}></textarea>
                                                             
                                                            
                                                            
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <h4 className="d-flex">Products</h4>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <button className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={kitViewModel.addProduct}> + Add </button>
                                                    </div>

                                                </div>
                                                <div className="mb-2 col-md-12 mt-3">
                                                    <div className="table-responsive-sm">
                                                        <table className="table table-centered mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th>S.No</th>
                                                                    <th>Product</th>
                                                                    
                                                                    <th>Quantity</th>
                                                                    
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                     kitViewModel.lstKitChildProducts.map((d: any, index: number) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{d.title}</td>
                                                                            
                                                                            <td>{d.qty}</td>
                                                                            
                                                                            <td>
                                                                                <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => kitViewModel.kitChildEdit(d,index)}></i>
                                                                                <i className="mx-2 uil uil-trash-alt" onClick={() => kitViewModel.removeKitProduct(index)}></i>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                              
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="standard-modal" className="modal fade " tabIndex={-1} aria-labelledby="standard-modalLabel" aria-modal="true" role="dialog">
                <div className="modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="standard-modalLabel">Add Product</h4>
                            <button type="button" id="addProductModelClose" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {/* <AddProduct onProductAdd={kitViewModel.handleAddButtonClick }
                                data={kitViewModel.purchaseInvoiceDetails}
                            /> */}

<div className="row">
                                                        <div className="col-md-6 mb-2">
                                                            <label className="form-label">Product<span style={{color:"red"}}>*</span></label>
                                                            <AsyncSelect
                                                                isClearable
                                                                isSearchable
                                                                cacheOptions
                                                                defaultOptions={kitViewModel.lstProducts}
                                                                onChange={kitViewModel.onProductChange}
                                                                value={kitViewModel.lstProducts.length > 0 ? (kitViewModel.lstProducts.find((option: any) => option.value == (kitViewModel.selectedProduct as any).product_details_id) || null) : null}
                                                                loadOptions={kitViewModel.loadProduct}
                                                            />
                                                        </div>
                                                        <div className="col-md-6"></div>
                                                        <div className="col-md-6 mb-2">
                                                            <label className="form-label">Qty<span style={{color:"red"}}>*</span></label>
                                                            <input className="form-control" id="productQty" placeholder="Qty" name="productQty"
                                                             value={kitViewModel.selectedProduct.qty} onChange={(e)=>kitViewModel.onProductQtyChange(e)} />
                                                        </div>

                                                        <div className="col-md-12 mt-2 text-end">
               
               <a className="btn btn-danger mb-2" onClick={(e)=>kitViewModel.addProductDetails('add')}>
                   Add <i className="mdi mdi-check mx-1"></i>
               </a>

               <a className="btn btn-danger mb-2 mx-2" onClick={(e)=>kitViewModel.addProductDetails('addPlus')}>
                   Add <i className="mdi mdi-plus mx-1"></i>
               </a>
           </div>
                                                        </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CreateKit