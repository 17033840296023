
import Users from "../../school-components/Users/Usersview";
import States from "../../school-components/State/StateView";
import ChangePassword from "../../school-components/ChangePassword/ChangePasswordView";
import City from "../../school-components/City/CityView";
import Cluster from "../../school-components/Cluster/ClusterView";
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import SchoolApprove from "../../school-components/SchoolApprove/SchoolApprove";
import Schools from "../../school-components/Schools/Schoolview"
import District from "../../school-components/District/DistrictView";
import { SideMenuViewModel } from "./SideMenuViewModel";
import BrandView from "../../ecommerce-components/Brand/BrandView";
import VarientgroupView from "../../ecommerce-components/VariantGroup/VarientgroupView";
import VarientView from "../../ecommerce-components/Variant/VarientView";
import HSNView from "../../ecommerce-components/HSN/HSNView";
import CategoryView from "../../ecommerce-components/Category/CategoryView";
import DiscountView from "../../ecommerce-components/Discount/DiscountView";
import ListingView from "../../ecommerce-components/Listing/ListingView";
import ProductView from "../../ecommerce-components/Products/ProductView";
import MerchantView from "../../ecommerce-components/Merchant/MerchantView";
import BannerView from "../../ecommerce-components/Banner/BannerView";
import PurchaseView from "../../ecommerce-components/PurchaseInvoice/PurchaseInvoiceView";
import CreatePurchaseInvoiceView from "../../ecommerce-components/CreatePurchaseInvoice/CreatePurchaseInvoiceView";
import orderconformationview from "../../ecommerce-components/OrderConformation/OrderconformationView";
import OrderProductDetailsview from "../../ecommerce-components/OrderProductDetails/OrderProductDetailsview";
import RaisePickups from "../../ecommerce-components/RaisePickups/RaisePickups";
import CreateKit from "../../ecommerce-components/Kit/CreateKit";
import KitView from "../../ecommerce-components/Kit/KitView";
import StockAdjustmentsView from "../../StockAdjustments/StockAdjustmentsView";
import CreateStockAdjustments from "../../StockAdjustments/CreateStockAdjustments";
import ReturnConformationView from "../../ecommerce-components/ReturnsConformation/ReturnConformationView";
const SideMenuView: React.FC = () => {

    const viewMode = SideMenuViewModel();
    return (
        <>
            <div className="navbar-custom">
                <div className="topbar container-fluid">
                    <div className="d-flex align-items-center gap-lg-2 gap-1">
                        <div className="logo-topbar">
                            <a href="javascript:void(0);" className="logo-light">
                                <span className="logo-lg">
                                    <img src="assets/images/logo.png" alt="logo" />
                                </span>
                                <span className="logo-sm text-light">
                                    <link rel="icon" href="assets/images/fav-icon.png" />  <b>ETI</b>
                                </span>
                            </a>

                            <a href="javascript:void(0);" className="logo-dark">
                                <span className="logo-lg">
                                    <img src="assets/images/logo-dark.png" alt="dark logo" />
                                </span>
                                <span className="logo-sm text-light">
                                    <link rel="icon" href="assets/images/fav-icon.png" />  <b>ETI</b>
                                </span>
                            </a>
                        </div>

                        <button className="button-toggle-menu pc-button" data-bs-target="#mySidenav" onClick={(viewMode.sidenavFunction)}>
                            <i className="mdi mdi-menu"></i>
                        </button>

                        <button className="button-toggle-menu mobile-button" data-bs-target="#mySidenav" onClick={(viewMode.sidenavmobileFunction)}>
                            <i className="mdi mdi-menu"></i>
                        </button>
                    </div>
                    <ul className="topbar-menu d-flex align-items-center gap-3">
                        <li className="dropdown">
                            <a className="nav-link dropdown-toggle arrow-none nav-user px-2" data-bs-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                                <span className="account-user-avatar">
                                    <img src="assets/images/users/avatar-1.jpg" alt="user-image" width="32" className="rounded-circle" />
                                </span>
                                <span className="d-lg-flex flex-column gap-1 d-none">
                                    <h5 className="my-0">{viewMode.userName} </h5>
                                    {/* <h6 className="my-0 fw-normal">{viewMode.role} </h6> */}
                                    <a href="javascript:void(0);" className="dropdown-item" onClick={viewMode.logoutClick}>
                                        <i className="mdi mdi-logout me-1 "></i>
                                        <span>Logout </span>
                                    </a>
                                </span>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown">
                                <div className=" dropdown-header noti-title">
                                    <h6 className="text-overflow m-0">Welcome {viewMode.userName} ! </h6>
                                </div>


                                <a href="javascript:void(0);" className="dropdown-item" onClick={viewMode.logoutClick}>
                                    <i className="mdi mdi-logout me-1"></i>
                                    <span>Logout </span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <Router>
                <div className="leftside-menu" id="mySidenav">
                    {/* <a href="javascript:void(0);" className="logo logo-light">
                    <span className="logo-lg">
                        <img src="assets/images/fav-icon.png" alt="logo" /> <b>Education Today India</b>
                    </span>
                    
                </a>

                <a href="javascript:void(0);" className="logo logo-dark">
                    <span className="logo-lg">
                        <img src="assets/images/fav-icon.png" alt="dark logo" /><b>Education Today India</b>
                    </span>
                    
                </a> */}

                    <div className="button-sm-hover" data-bs-toggle="tooltip" data-bs-placement="right" title="Show Full Sidebar">
                        <i className="ri-checkbox-blank-circle-line align-middle"></i>
                    </div>

                    <div className="button-close-fullsidebar">
                        <i className="ri-close-fill align-middle" onClick={viewMode.sidenavmobileFunction}></i>
                    </div>

                    <div className="h-100" id="leftside-menu-container" data-simplebar="">
                        <div className="leftbar-user">
                            <a href="pages-profile.html">
                                <img src="assets/images/users/avatar-1.jpg" alt="user-image" height="42" className="rounded-circle shadow-sm" />
                                <span className="leftbar-user-name mt-2">{viewMode.userName} </span>
                            </a>

                        </div>
                        <style>
                            {`
          html[data-sidenav-size=condensed]:not([data-layout=topnav]) .wrapper .leftside-menu .side-nav .side-nav-item .side-nav-link span {
            visibility: ${viewMode.isVisibilityHidden ? 'hidden' : 'visible'};
          }

          .content{
            margin-left:  ${viewMode.isVisibilityHidden ? '0px' : '170px'};
          }
          .navbar-custom
          {

            margin-left:  ${viewMode.isVisibilityHidden ? '0px' : '250px !important'};
          }

          html[data-sidenav-size=full]:not([data-layout=topnav]) .leftside-menu {
            border-color: transparent;
            opacity:${viewMode.isMobileVisibilityHidden ? '1' : '0 !important'};;
        }
        .leftside-menu {
            
            display:${viewMode.isMobileVisibilityHidden ? '' : 'none !important'};;
        }
          

        @media (max-width: 767px) {
            .mobile-button {
              display: block !important; ; /* Show the button on mobile views */
            }
            .pc-button {
                display: none !important; ; /* Show the button on mobile views */
              }
              
          }

          .mobile-button {
            display: none ; /* Initially hide the button */
          }
          .pc-button {
            display: block ; /* Show the button on mobile views */
          }

        `}
                        </style>
                        <ul className="side-nav ">
                            {/* <li className="side-nav-title">Navigation </li> */}
                            <li>
                                <span className="logo-lg logo-span" >
                                    <img src="assets/images/fav-icon.png" alt="logo" style={{
                                        width: '40px', height: '40px'
                                    }}
                                    /><br></br>   <span className="side-nav-link"> <b>School</b> </span>
                                </span>
                            </li>
                            {/* <li className="side-nav-item">
                            <Link to='/' className="side-nav-link">
                                <i className="uil-home-alt"></i>
                                <span> Dashboards  </span>
                            </Link>
                        </li> */}
                            <li className="side-nav-item">
                                <Link to="/schools" className="side-nav-link">
                                    <i className="uil uil-building"></i>
                                    <span>Schools</span>
                                </Link>
                            </li>
                            <li className="side-nav-title"> </li>
                            <li className="side-nav-item">
                                <Link to='/users' className="side-nav-link">
                                    <i className="uil uil-users-alt"></i>
                                    <span> Users  </span>
                                </Link>
                            </li>
                            <li className="side-nav-item">
                                <Link to='/state' className="side-nav-link">
                                    <i className="uil uil-archway"></i>
                                    <span> State  </span>
                                </Link>
                            </li>
                            <li className="side-nav-item">
                                <Link to="/district" className="side-nav-link">
                                    <i className="uil uil-map"></i>
                                    <span>District</span>
                                </Link>
                            </li>
                            <li className="side-nav-item">
                                <Link to="/cluster" className="side-nav-link">
                                    <i className="uil-servers"></i>
                                    <span>Cluster</span>
                                </Link>
                            </li>
                            <li className="side-nav-item">
                                <Link to='/city' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> City </span>
                                </Link>
                            </li>

                            {/* <li className="side-nav-item">
                                <Link to='/brand' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Brand </span>
                                </Link>
                            </li> */}

                            <li className="side-nav-item">
                            <span className="side-nav-link"> <b>Ecommerce</b> </span>
                               
                            </li>
                            <li className="side-nav-item">
                                <Link to='/brand' className="side-nav-link">
                                    <i className="uil-dropbox"></i>
                                    <span> Brand </span>
                                </Link>
                            </li>
                            <li className="side-nav-item">
                                <Link to='/variant-group' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Varient Group</span>
                                </Link>
                            </li>
                            <li className="side-nav-item">
                                <Link to='/HSN' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> HSN </span>
                                </Link>
                            </li>
                            <li className="side-nav-item">
                                <Link to='/category' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Category </span>
                                </Link>
                            </li>
                            <li className="side-nav-item">
                                <Link to='/discount' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Discount </span>
                                </Link>
                            </li>
                            {/* <li className="side-nav-item">
                                <Link to='/listings' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Listings </span>
                                </Link>
                            </li> */}
                            <li className="side-nav-item">
                                <Link to='/products' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Products </span>
                                </Link>
                            </li>
                            <li className="side-nav-item">
                                <Link to='/kit' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Kit </span>
                                </Link>
                            </li>
                            

                            <li className="side-nav-item">
                                <Link to='/merchant' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Merchant </span>
                                </Link>
                            </li>
                            <li className="side-nav-item">
                                <Link to='/banner' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Banners </span>
                                </Link>
                            </li>
                            <li className="side-nav-item">
                                <Link to='/purchaseinvoice' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Purchase Invoice </span>
                                </Link>
                            </li>

                            <li className="side-nav-item">
                                <Link to='/stockadjustments' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Stock Adjustment </span>
                                </Link>
                            </li>


                            {/* <li className="side-nav-item">
                                <Link to='/createpurchaseinvoice' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Create Purchaseinvoice </span>
                                </Link>
                            </li> */}
                            <li className="side-nav-item">
                                <Link to='/orderconformation' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Order Confirm </span>
                                </Link>
                            </li>
                            <li className="side-nav-item">
                                <Link to='/returns' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Returns Confirm </span>
                                </Link>
                            </li>

                            
                            <li className="side-nav-item">
                                <Link to='/raisepickup' className="side-nav-link">
                                    <i className="uil-server-connection"></i>
                                    <span> Raise Pickup </span>
                                </Link>
                            </li>
                        </ul>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <Switch>
                    <Route path="/" exact component={Schools} />
                    <Route path="/state" component={States} />
                    <Route path="/district" component={District} />
                    <Route path="/users" component={Users} />
                    <Route path="/schools" component={Schools} />
                    <Route path="/city" component={City} />
                    <Route path="/cluster" component={Cluster} />
                    <Route path="/brand" component={BrandView} />
                    <Route path="/variant-group" component={VarientgroupView} /> 
                    <Route path="/variant" component={VarientView} /> 
                    <Route path="/HSN" component={HSNView} /> 
                    <Route path="/category" component={CategoryView} /> 
                    <Route path="/discount" component={DiscountView} /> 
                    <Route path="/listings" component={ListingView} />
                        <Route path="/products" component={ProductView} /> 
                        <Route path="/merchant" component={MerchantView} />  
                        <Route path="/banner" component={BannerView} />  
                        <Route path="/purchaseinvoice" component={PurchaseView} /> 
                        <Route path="/createpurchaseinvoice" component={CreatePurchaseInvoiceView} /> 
                        <Route path="/orderconformation" component={orderconformationview} /> 
                        <Route path="/createkit" component={CreateKit} /> 

                        <Route path="/kit" component={KitView} /> 
                        <Route path="/raisepickup" component={RaisePickups} /> 
                        
                        <Route path="/stockadjustments" component={StockAdjustmentsView} /> 
                        <Route path="/createstockadjustments" component={CreateStockAdjustments} /> 
                        <Route path="/returns" component={ReturnConformationView} /> 

                        {/* <Route path="/orderproductdetailsview" component={OrderProductDetailsview} /> */}
                        
                    {/* <Route path="/changepassword" component={ChangePassword} />   */}
                    {/* <Route path="/schoolapproval" component={SchoolApprove} />  */}
                </Switch>
            </Router>
        </>
    );

}
export default SideMenuView;