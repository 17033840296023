import ReactQuill from "react-quill";
import AsyncSelect from "react-select/async";

import SchoolApprove from "../SchoolApprove/SchoolApprove";
import { Schoolviewmodel } from "./Schoolviewmodel";

const Schoolview: React.FC = () => {

    const schoolViewModel = Schoolviewmodel();
    return (


        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">School's </li>
                                    </ol>
                                </div>
                                <h4 className="page-title">School's </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                                <label>Search:<input type="search" className="form-control form-control-sm" value={schoolViewModel.searchTerm} onChange={schoolViewModel.handleSearch} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a href="javascript:void(0);" className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#standard-modal">
                                                <i className="mdi mdi-plus-circle me-2"></i> Add Schools
                                            </a>
                                        </div>




                                        <ul className="nav nav-pills nav-justified form-wizard-header" role="tablist">

                                            <li className="nav-item" role="presentation">
                                                {/* href="#schoolInfo" */}
                                                <a onClick={() => schoolViewModel.statusTabClick('All')} className={schoolViewModel.activeMainTab == 'All' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="true" role="tab">
                                                    {/* <i className="uil uil-building font-18 align-middle me-1"></i> */}
                                                    <span className="d-none d-sm-inline">All</span>
                                                </a>
                                            </li>


                                            <li className="nav-item" role="presentation">
                                                {/* href="#schoolInfo" */}
                                                <a onClick={() => schoolViewModel.statusTabClick('Pendings')} className={schoolViewModel.activeMainTab == 'Pendings' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="true" role="tab">
                                                    {/* <i className="uil uil-building font-18 align-middle me-1"></i> */}
                                                    <span className="d-none d-sm-inline">Pendings</span>
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                {/* href="#schoolViewModel.addressInfo" */}
                                                <a onClick={() => schoolViewModel.statusTabClick('Approved')} className={schoolViewModel.activeMainTab == 'Approved' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="false" role="tab">
                                                    {/* <i className="uil uil-location-point font-18 align-middle me-1"></i> */}
                                                    <span className="d-none d-sm-inline">Approved</span>
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                {/* href="#resourceInfo"  */}
                                                <a onClick={() => schoolViewModel.statusTabClick('Rejected')} className={schoolViewModel.activeMainTab == 'Rejected' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="false" role="tab">
                                                    {/* <i className="uil uil-sync font-18 align-middle me-1"></i> */}
                                                    <span className="d-none d-sm-inline">Rejected</span>
                                                </a>
                                            </li>

                                            <li className="nav-item" role="presentation">
                                                {/* href="#schoolViewModel.facilityInfo"  */}
                                                <a onClick={() => schoolViewModel.statusTabClick('Verified')} className={schoolViewModel.activeMainTab == 'Verified' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="false" role="tab">
                                                    {/* <i className="uil uil-game-structure font-18 align-middle me-1"></i> */}
                                                    <span className="d-none d-sm-inline">Verified</span>
                                                </a>
                                            </li>
                                        </ul>


                                        <div className="mb-2 col-md-12 mt-1">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>School Name</th>
                                                            <th>School Type</th>
                                                            <th>Year of Establishment</th>
                                                            <th>Board for Class 10th</th>

                                                            {/* <th>About Us</th> */}
                                                            <th>Action</th>
                                                            <th className="text-center">Status</th>
                                                            <th className="text-center">Verified</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {schoolViewModel.filterListSchools.map((d: any, index: number) => (
                                                            <tr>
                                                                <td>{index + 1}</td>
                                                                <td style={{'width':'500px'}}>{d.school_name}</td>
                                                                <td>{d.school_type}</td>
                                                                <td>{d.year_of_establishment}</td>
                                                                <td>{d.board_for_class_10th}</td>
                                                                {/* <td>
                                                                    <span className={`badge ${schoolViewModel.getBadgeClass(d.status)}`}>{d.status}</span>
                                                                </td> */}
                                                                {/* <td>
                                                                    <div className="btn btn-light btn-sm" data-bs-toggle="modal" data-bs-target="#resource-modal" onClick={() => schoolViewModel.aboutUs(d)}>About Us</div>
                                                                </td> */}
                                                                <td>
                                                                    <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => schoolViewModel.editSchool(d)}></i>
                                                                    <i className="mx-2 uil uil-trash-alt" onClick={() => schoolViewModel.deleteSchool(d.school_id)}></i>
                                                                </td>
                                                                <td className="text-center">
                                                                    {d.approved_status == '' ?
                                                                        (

                                                                            <a href="javascript:void(0);" className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#approve-modal"
                                                                                onClick={(e) => schoolViewModel.btnApproveClick(d.school_id)}>
                                                                                Approve
                                                                            </a>) :
                                                                        (<span className={`badge ${schoolViewModel.getBadgeClass(d.approved_status)}`}>{d.approved_status}</span>)

                                                                    }
                                                                </td>
                                                                <td className="text-center">
                                                                    {

                                                                        (d.approved_status == 'Approved' && d.is_verified == '') ?

                                                                            (

                                                                                <a href="javascript:void(0);" className="btn btn-success mb-2"
                                                                                    onClick={(e) => schoolViewModel.btnVerifyClick(d.school_id)}>
                                                                                    Verified
                                                                                </a>) :

                                                                            ((d.is_verified == 'Verified') ?

                                                                                (<i className="uil uil-check-circle font-24 align-middle me-1 " style={{ color: 'green' }}></i>) :

                                                                                (<i className="uil uil-question-circle font-24 align-middle me-1 " style={{ color: 'red' }} ></i>)

                                                                            )
                                                                    }




                                                                    {/* <i className="uil uil-check-circle font-24 align-middle me-1 " style={{color:'green'}}></i>
                                                                <i className="uil uil-question-circle font-24 align-middle me-1 " style={{color:'red'}} ></i> */}


                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <nav className="text-right mt-3" >

                                                    <ul className='pagination float-end' >
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={schoolViewModel.prePage}>prev</a>
                                                        </li>
                                                        {

                                                            schoolViewModel.numbers.map((n, i) =>
                                                            (

                                                                <li key={i} className={`page-item ${schoolViewModel.currentPage === n ? 'active' : ''}`} >
                                                                    <a className='page-link' onClick={() => schoolViewModel.changePage(n)}>{n}</a>
                                                                </li>
                                                            ))
                                                        }
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={schoolViewModel.nextPage}>next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div id="approve-modal" className="modal fade " aria-labelledby="approve-modalLabel" data-bs-backdrop="static" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-full-width" style={{ maxWidth: '900px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="approve-modalLabel" >School Approvel </h4>
                            <button type="button" className="btn-close float-end" id="myModal" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="modal-body">
                            {schoolViewModel.school_id > 0 ? <SchoolApprove school_id={schoolViewModel.school_id} /> : ''}

                        </div>
                    </div></div>
            </div>

            <div id="standard-modal" className="modal fade " aria-labelledby="standard-modalLabel" data-bs-backdrop="static" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-full-width" style={schoolViewModel.popupWidth}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="standard-modalLabel" >Add schools </h4>
                            <button type="button" className="btn-close float-end" id="myModal" data-bs-dismiss="modal" aria-label="Close" onClick={schoolViewModel.cancel}></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div id="basicwizard">
                                        <ul className="nav nav-pills nav-justified form-wizard-header mb-4" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                {/* href="#schoolInfo" */}
                                                <a className={schoolViewModel.activeTab == 'schoolInfo' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="true" role="tab">
                                                    <i className="uil uil-building font-18 align-middle me-1"></i>
                                                    <span className="d-none d-sm-inline">School information</span>
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                {/* href="#addressInfo" */}
                                                <a className={schoolViewModel.activeTab == 'addressInfo' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="false" role="tab">
                                                    <i className="uil uil-location-point font-18 align-middle me-1"></i>
                                                    <span className="d-none d-sm-inline">Address / Contact information</span>
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                {/* href="#resourceInfo"  */}
                                                <a className={schoolViewModel.activeTab == 'resourceInfo' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="false" role="tab">
                                                    <i className="uil uil-sync font-18 align-middle me-1"></i>
                                                    <span className="d-none d-sm-inline">Resource details</span>
                                                </a>
                                            </li>

                                            <li className="nav-item" role="presentation">
                                                {/* href="#facilityInfo"  */}
                                                <a className={schoolViewModel.activeTab == 'facilityInfo' ? 'nav-link rounded-0 py-2 active' : 'nav-link rounded-0 py-2'} aria-selected="false" role="tab">
                                                    <i className="uil uil-game-structure font-18 align-middle me-1"></i>
                                                    <span className="d-none d-sm-inline">Facility details</span>
                                                </a>
                                            </li>
                                        </ul>

                                        <div className="tab-content b-0 mb-0">
                                            <div className={schoolViewModel.activeTab == 'schoolInfo' ? 'tab-pane active show' : 'tab-pane'} id="schoolInfo" role="tabpanel">
                                                <div className="row">

                                                    <div className="col-md-3 mb-2">
                                                        <label >UDISE Code</label>
                                                        <input type="text" name="udise_code" value={schoolViewModel.schoolInfo.udise_code} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.schoolInfo.title)} />
                                                        {(schoolViewModel.schoolInfoSubmit == true && schoolViewModel.schoolInfo.udise_code == '') ? (<div className="text-danger">UDISE code required </div>) : ''}
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >School Name</label>
                                                        <input type="text" name="school_name" value={schoolViewModel.schoolInfo.school_name} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.schoolInfo.title)} />
                                                        {(schoolViewModel.schoolInfoSubmit == true && schoolViewModel.schoolInfo.school_name == '') ? (<div className="text-danger">School name required </div>) : ''}
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Instruction Medium</label>
                                                        <input type="text" name="instruction_medium" value={schoolViewModel.schoolInfo.instruction_medium} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.schoolInfo.title)} />
                                                        {(schoolViewModel.schoolInfoSubmit == true && schoolViewModel.schoolInfo.instruction_medium == '') ? (<div className="text-danger">Instruction required </div>) : ''}
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Status</label>
                                                        <select name="status" value={schoolViewModel.schoolInfo.status} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Active">Approve</option>
                                                            <option value="Blocked">Block</option>
                                                            <option value="Deleted">Inactive</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >School Type</label>

                                                        <select name="school_type" value={schoolViewModel.schoolInfo.school_type} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Co-educational">Co-educational</option>
                                                            <option value="Girls Only">Girls Only</option>
                                                            <option value="Boys Only">Boys Only</option>
                                                        </select>
                                                        {(schoolViewModel.schoolInfoSubmit == true && schoolViewModel.schoolInfo.school_type == '') ? (<div className="text-danger">School type required </div>) : ''}
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >Classes</label>

                                                        <AsyncSelect
                                                            isClearable
                                                            isSearchable
                                                            cacheOptions
                                                            defaultOptions={schoolViewModel.schoolClasses}
                                                            onChange={schoolViewModel.handleSelectChange}
                                                            value={schoolViewModel.schoolClasses.find((option: any) => option.value === schoolViewModel.schoolInfo.classes) || null}
                                                            loadOptions={schoolViewModel.loadSchoolClasses}
                                                        />
                                                        {(schoolViewModel.schoolInfoSubmit == true && schoolViewModel.schoolInfo.classes == '') ? (<div className="text-danger">School type required </div>) : ''}
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >Year of Establishment</label>
                                                        <input type="number" name="year_of_establishment" value={schoolViewModel.schoolInfo.year_of_establishment} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.schoolInfo.title)} />
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >School Area</label>
                                                        <select name="school_area" value={schoolViewModel.schoolInfo.school_area} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Urban">Urban</option>
                                                            <option value="Semi-Urban">Semi-Urban</option>
                                                            <option value="Rural">Rural</option>
                                                        </select>
                                                        {(schoolViewModel.schoolInfoSubmit == true && schoolViewModel.schoolInfo.school_area == '') ? (<div className="text-danger">School area required </div>) : ''}
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >School Shifted to New Place</label>
                                                        <select name="school_shifted_to_new_place" value={schoolViewModel.schoolInfo.school_shifted_to_new_place} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>

                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Management</label>
                                                        <select name="management" value={schoolViewModel.schoolInfo.management} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="PVT">PVT</option>
                                                            <option value="Unaided">Unaided</option>
                                                            <option value="Aided">Aided</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Building</label>
                                                        <select name="building" value={schoolViewModel.schoolInfo.building} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Owned">Owned</option>
                                                            <option value="Government">Government</option>
                                                            <option value="Rented">Rented</option>
                                                        </select>
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >Pre Primary Section Available </label>
                                                        <select name="pre_primary_sectin_avilable" value={schoolViewModel.schoolInfo.pre_primary_sectin_avilable} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>



                                                    <div className="col-md-3 mb-2">
                                                        <label >Board for Class 10th</label>
                                                        <select name="board_for_class_10th" value={schoolViewModel.schoolInfo.board_for_class_10th} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.schoolInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Others">Others</option>
                                                            <option value="State">State</option>
                                                            <option value="CBSE">CBSE</option>
                                                            <option value="ICSE">ICSE</option>
                                                        </select>
                                                        {(schoolViewModel.schoolInfoSubmit == true && schoolViewModel.schoolInfo.board_for_class_10th == '') ? (<div className="text-danger">Board required </div>) : ''}
                                                    </div>
                                                </div>

                                                <ul className="list-inline wizard mb-0">
                                                    <li className="next list-inline-item float-end">
                                                        <a className="btn btn-secondary" onClick={schoolViewModel.validateSchoolInfo}>Next <i className="mdi mdi-arrow-right ms-1"></i></a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={schoolViewModel.activeTab == 'addressInfo' ? 'tab-pane active show' : 'tab-pane'} id="addressInfo" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-3 mb-2">
                                                        <label >State </label>


                                                        <AsyncSelect
                                                            isClearable
                                                            isSearchable
                                                            cacheOptions
                                                            defaultOptions={schoolViewModel.lstStates}
                                                            onChange={schoolViewModel.handleStateChange}
                                                            value={schoolViewModel.lstStates.length > 0 ? (schoolViewModel.lstStates.find((option: any) => option.value == schoolViewModel.addressInfo.state_id) || null) : null}
                                                            loadOptions={schoolViewModel.loadState}
                                                        />

                                                        {(schoolViewModel.addressInfoSubmit == true && schoolViewModel.addressInfo.state_id == 0) ? (<div className="text-danger">State required </div>) : ''}

                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >District</label>
                                                        <AsyncSelect
                                                            isClearable
                                                            isSearchable
                                                            cacheOptions
                                                            defaultOptions={schoolViewModel.lstDistricts}
                                                            onChange={schoolViewModel.handleDistrictChange}
                                                            value={schoolViewModel.lstDistricts.find((option: any) => option.value == schoolViewModel.addressInfo.district_id) || null}
                                                            loadOptions={schoolViewModel.loadDistrict}
                                                        />


                                                        {(schoolViewModel.addressInfoSubmit == true && schoolViewModel.addressInfo.district_id == 0) ? (<div className="text-danger">District required </div>) : ''}
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >City(Blocks)</label>
                                                        <AsyncSelect
                                                            isClearable
                                                            isSearchable
                                                            cacheOptions
                                                            defaultOptions={schoolViewModel.lstCities}
                                                            onChange={schoolViewModel.handleCityChange}
                                                            value={schoolViewModel.lstCities.find((option: any) => option.value == schoolViewModel.addressInfo.city_id) || null}
                                                            loadOptions={schoolViewModel.loadCity}
                                                        />
                                                        {(schoolViewModel.addressInfoSubmit == true && schoolViewModel.addressInfo.city_id == 0) ? (<div className="text-danger">City required </div>) : ''}
                                                    </div>



                                                    <div className="col-md-3 mb-2">
                                                        <label >Cluster</label>

                                                        {/* <select name="cluster_id" value={addressInfo.cluster_id} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, addressInfo.title)}>
                                                        <option value="" selected aria-readonly disabled>Select</option>
                                                        <option value="Urban">Urban</option>
                                                        <option value="Semi-Urban">Semi-Urban</option>
                                                        <option value="Rural">Rural</option>
                                                    </select> */}
                                                        <AsyncSelect
                                                            isClearable
                                                            isSearchable
                                                            cacheOptions
                                                            defaultOptions={schoolViewModel.lstCluster}
                                                            onChange={schoolViewModel.handleClusterChange}
                                                            value={schoolViewModel.lstCluster.find((option: any) => option.value == schoolViewModel.addressInfo.cluster_id) || null}
                                                            loadOptions={schoolViewModel.loadCluster}
                                                        />

                                                        {(schoolViewModel.addressInfoSubmit == true && schoolViewModel.addressInfo.cluster_id == 0) ? (<div className="text-danger">Cluster required </div>) : ''}

                                                    </div>



                                                    <div className="col-md-3 mb-2">
                                                        <label >Location</label>
                                                        <select name="location" value={schoolViewModel.addressInfo.location} className="form-control" onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.addressInfo.title)}>
                                                            <option value="Latitude">Latitude</option>
                                                            <option value="Longitude">Longitude</option>
                                                        </select>
                                                    </div>



                                                    <div className="col-md-3 mb-2">
                                                        <label >Contact No</label>
                                                        <input type="text" name="contact_no" className="form-control" value={schoolViewModel.addressInfo.contact_no} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.addressInfo.title)} />
                                                        {(schoolViewModel.addressInfoSubmit == true && schoolViewModel.addressInfo.contact_no == '') ? (<div className="text-danger">Contact required </div>) : ''}
                                                    </div>
                                                    <div className="col-md-6 mb-4">
                                                        <label >Address</label>
                                                        <input type="text" name="address" className="form-control" value={schoolViewModel.addressInfo.address} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.addressInfo.title)} />
                                                        {(schoolViewModel.addressInfoSubmit == true && schoolViewModel.addressInfo.address == '') ? (<div className="text-danger">Address required </div>) : ''}
                                                    </div>

                                                    <div className="col-md-12">
                                                        <h4 >Login Details</h4>
                                                    </div>


                                                    <div className="col-md-3 mb-2">
                                                        <label >Email </label>
                                                        <input type="email" name="email" className="form-control" value={schoolViewModel.addressInfo.email} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.addressInfo.title)} />
                                                        {(schoolViewModel.addressInfoSubmit == true && schoolViewModel.addressInfo.email == '') ? (<div className="text-danger">Email required </div>) : ''}
                                                        {((schoolViewModel.addressInfo.email == '' || !(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/).test(schoolViewModel.addressInfo.email)) && schoolViewModel.addressInfoSubmit == true) ? (<label className="m-0 text-danger">Invalid email.</label>) : ''}


                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label htmlFor="">Passowrd</label>
                                                        <input type="text" className="form-control" name="password" value={schoolViewModel.addressInfo.password} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.addressInfo.title)} />
                                                        {(schoolViewModel.addressInfoSubmit == true && schoolViewModel.addressInfo.password == '') ? (<div className="text-danger">Password required </div>) : ''}
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label htmlFor="">Passowrd</label>
                                                        <input type="text" className="form-control" name="repassword" value={schoolViewModel.addressInfo.repassword} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.addressInfo.title)} />
                                                        {(schoolViewModel.addressInfoSubmit == true && schoolViewModel.addressInfo.repassword == '') ? (<div className="text-danger">Password required </div>) : ''}
                                                        {(schoolViewModel.addressInfoSubmit == true && schoolViewModel.addressInfo.password != schoolViewModel.addressInfo.repassword) ? (<div className="text-danger">Password mismatch</div>) : ''}
                                                    </div>
                                                </div>

                                                <ul className="pager wizard mb-0 list-inline">
                                                    <li className="next list-inline-item float-end">
                                                        <button type="button" className="btn btn-secondary" onClick={schoolViewModel.validateAddressInfo}>Next <i className="mdi mdi-arrow-right ms-1"></i></button>
                                                    </li>
                                                    <li className="previous list-inline-item disabled float-end">
                                                        <button type="button" className="btn btn-light" onClick={(e) => schoolViewModel.back('schoolInfo')}><i className="mdi mdi-arrow-left me-1"></i> Back</button>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={schoolViewModel.activeTab == 'resourceInfo' ? 'tab-pane active show' : 'tab-pane'} id="resourceInfo" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-3 mb-2">
                                                        <label >Head Teacher </label>
                                                        <input type="text" name="head_teacher" className="form-control" value={schoolViewModel.resourceInfo.head_teacher} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Total Teachers </label>
                                                        <input type="number" name="total_teachers" className="form-control" value={schoolViewModel.resourceInfo.total_teachers} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Pre Primary Teachers </label>
                                                        <input type="number" name="pre_primary_teachers" className="form-control" value={schoolViewModel.resourceInfo.pre_primary_teachers} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Primary Teachers </label>
                                                        <input type="text" name="primary_teachers" className="form-control" value={schoolViewModel.resourceInfo.primary_teachers} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Middle School Teachers </label>
                                                        <input type="text" name="middle_school_teachers" className="form-control" value={schoolViewModel.resourceInfo.middle_school_teachers} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >High SchoolTeachers </label>
                                                        <input type="text" name="high_school_teachers" className="form-control" value={schoolViewModel.resourceInfo.high_school_teachers} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Number of Female Teacher</label>
                                                        <input type="number" name="female_teachers" className="form-control" value={schoolViewModel.resourceInfo.female_teachers} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Number of Male Teacher </label>
                                                        <input type="number" name="male_teachers" className="form-control" value={schoolViewModel.resourceInfo.male_teachers} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.resourceInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Number of Contract Teachers </label>
                                                        <input type="number" name="contract_teachers" className="form-control" value={schoolViewModel.resourceInfo.contract_teachers} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.resourceInfo.title)} />
                                                    </div>
                                                </div>

                                                <ul className="pager wizard mb-0 list-inline mt-1">
                                                    <li className="next list-inline-item float-end">
                                                        <button type="button" className="btn btn-secondary" onClick={schoolViewModel.validateResourceInfo}>Next <i className="mdi mdi-arrow-right ms-1"></i></button>
                                                    </li>
                                                    <li className="previous list-inline-item disabled float-end">
                                                        <button type="button" className="btn btn-light" onClick={(e) => schoolViewModel.back('addressInfo')}><i className="mdi mdi-arrow-left me-1"></i> Back </button>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className={schoolViewModel.activeTab == 'facilityInfo' ? 'tab-pane active show' : 'tab-pane'} id="facilityInfo" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-3 mb-2">
                                                        <label >Total number of Class Rooms </label>
                                                        <input type="number" name="class_rooms" className="form-control" value={schoolViewModel.facilityInfo.class_rooms} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Number of Boys Toilet</label>
                                                        <input type="number" name="boys_toilet" className="form-control" value={schoolViewModel.facilityInfo.boys_toilet} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Number of Girls Toilet</label>
                                                        <input type="number" name="girls_toilet" className="form-control" value={schoolViewModel.facilityInfo.girls_toilet} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Mid-day Meal Status / Info</label>
                                                        <select name="mid_day_meal" className="form-control" value={schoolViewModel.facilityInfo.mid_day_meal} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Is School Residential</label>
                                                        <select name="is_school_residential" className="form-control" value={schoolViewModel.facilityInfo.is_school_residential} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Residential Type</label>
                                                        <select name="residential_type" className="form-control" value={schoolViewModel.facilityInfo.residential_type} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Not Applicable">Not Applicable</option>
                                                            <option value="Applicable">Applicable</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Electricity</label>
                                                        <select name="electricity" className="form-control" value={schoolViewModel.facilityInfo.electricity} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)}>
                                                            {/* <option value="" selected aria-readonly disabled>Select</option> */}
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Wall</label>
                                                        <select name="wall" className="form-control" value={schoolViewModel.facilityInfo.wall} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Pucca">Pucca</option>
                                                            <option value="Thatched">Thatched</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Library</label>
                                                        <select name="library" className="form-control" value={schoolViewModel.facilityInfo.library} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Playground</label>
                                                        <select name="playground" className="form-control" value={schoolViewModel.facilityInfo.playground} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Total Books in Library</label>
                                                        <input type="number" name="books_in_library" className="form-control" value={schoolViewModel.facilityInfo.books_in_library} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)} />
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Drinking Water</label>
                                                        <select name="drinking_water" className="form-control" value={schoolViewModel.facilityInfo.drinking_water} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Tap Water">Tap Water</option>
                                                            <option value="Boar Water">Boar Water</option>
                                                            <option value="Filter Water">Filter Water</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Ramps for Disabled</label>
                                                        <select name="ramps_for_disable" className="form-control" value={schoolViewModel.facilityInfo.ramps_for_disable} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Canteen</label>
                                                        <select name="canteen" className="form-control" value={schoolViewModel.facilityInfo.canteen} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Transport Facility</label>
                                                        <select name="transport_facility" className="form-control" value={schoolViewModel.facilityInfo.transport_facility} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Available">Available</option>
                                                            <option value="Not Available">Not Available</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >Computer Aided Learning Status</label>
                                                        <select name="computer_aided_learning" className="form-control" value={schoolViewModel.facilityInfo.computer_aided_learning} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)}>
                                                            <option value="" selected aria-readonly disabled>Select</option>
                                                            <option value="Available">Available</option>
                                                            <option value="Not Available">Not Available</option>
                                                        </select>
                                                    </div>

                                                    <div className="col-md-3 mb-2">
                                                        <label >If Available Number of Computers available</label>
                                                        <input type="number" name="computers" className="form-control" value={schoolViewModel.facilityInfo.computers} onChange={(e) => schoolViewModel.handleChange(e, schoolViewModel.facilityInfo.title)} />
                                                    </div>
                                                </div>

                                                <ul className="pager wizard mb-0 list-inline mt-1">
                                                    <li className="next list-inline-item ">
                                                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" aria-label="Close" onClick={schoolViewModel.cancel}>Cancel</button>
                                                    </li>
                                                    <li className="next list-inline-item float-end">
                                                        <button type="button" className="btn btn-success" onClick={schoolViewModel.saveSchool}>Save <i className="mdi mdi-check me-1"></i></button>
                                                    </li>
                                                    <li className="previous list-inline-item disabled float-end">
                                                        <button type="button" className="btn btn-light" onClick={(e) => schoolViewModel.back('resourceInfo')}><i className="mdi mdi-arrow-left me-1"></i> Back </button>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close </button>
                        <button type="button" className="btn btn-primary">Save </button>
                    </div> */}
                    </div>
                </div>
            </div>

            <div id="resource-modal" className="modal fade" aria-labelledby="fullWidthModalLabel" data-bs-backdrop="static" aria-hidden="true" >
                <div className="modal-dialog modal-full-width">
                    <div className="modal-content">
                        <form action="" >
                            <div className="modal-header">
                                <h4 className="modal-title" id="fullWidthModalLabel">About Us</h4>
                                <button type="button" className="btn-close" id="aboutModal" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-4">
                                        {/* <div >{aboutus.about} </div> */}
                                        <ReactQuill
                                            theme="snow"
                                            modules={schoolViewModel.modules}
                                            formats={schoolViewModel.formats}
                                            placeholder="write your content ...."
                                            onChange={schoolViewModel.handleProcedureContentChange}
                                            style={{ height: "520px" }}
                                            value={schoolViewModel.aboutus.about}
                                        />
                                    </div>

                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" onClick={schoolViewModel.saveAboutus}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


    )



}
export default Schoolview;