import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { EcommerceRepository } from "../../repository/EcommerceRepository";
import { useHistory, useLocation } from "react-router-dom";

export function CreatePurchaseViewModel() {

    const search = useLocation().search;
    const invoice = new URLSearchParams(search).get("invoice");
    let invoice_id = invoice;
    const navigate = useHistory();

    const ecommerceRepository = EcommerceRepository();
    // const imagePath = ecommerceRepository.imagePath;
    const [lstMerchantDrp, setMerchantDrp] = useState<{ label: string; value: string }[] | []>([]);

    const [purchaseInvoice, setPurchaseInvoice] = useState({
        purchase_invoice_id: 0,
        invoice_no: '',
        bill_ref: '',
        date: '',
        merchant_id: 0,
        gross: 0,
        tax: 0,
        discount: 0,
        net_total: 0,
        updated_date: '',
        purchase_invoice_details: [] as any
    });

    const [validate, setValidate] = useState({
        purchase_invoice_id: '',
        invoice_no: '',
        bill_ref: '',
        date: '',
        merchant_id: '',
        gross: '',
        tax: '',
        discount: '',
        net_total: '',
        updated_date: '',
    });

    const [purchaseInvoiceDetails, setPurchaseInvoiceDetails] = useState({
        purchase_invoice_product_id: 0,
        purchase_invoice_id: 0,
        product_details_id: 0,
        product_name: '',
        hsn_no: '',
        qty: 0,
        price: 0,
        gross: 0,
        discount: 0,
        tax_per: 0,
        tax: 0,
        net: 0,
    });

    const [purchaseInvoiceForPopupDetails, setPurchaseInvoicePopUpDetails] = useState([{
        purchase_invoice_product_id: 0,
        purchase_invoice_id: 0,
        product_details_id: 0,
        product_name: '',
        hsn_no: '',
        qty: 0,
        price: 0,
        gross: 0,
        discount: 0,
        tax_per: 0,
        tax: 0,
        net: 0,
    }]);

    useEffect(() => {
        let pastPurchaseInvoice: any = { ...purchaseInvoice };
        pastPurchaseInvoice.gross = 0;
        pastPurchaseInvoice.tax = 0;
        pastPurchaseInvoice.discount = 0;
        pastPurchaseInvoice.net_total = 0;
        for (let i = 0; i < purchaseInvoiceForPopupDetails.length; i++) {
            debugger;
            pastPurchaseInvoice.gross += (+purchaseInvoiceForPopupDetails[i].gross);
            pastPurchaseInvoice.tax += (+purchaseInvoiceForPopupDetails[i].tax);
            pastPurchaseInvoice.discount += (+purchaseInvoiceForPopupDetails[i].discount);
            pastPurchaseInvoice.net_total += (+purchaseInvoiceForPopupDetails[i].net);
        }

        setPurchaseInvoice(pastPurchaseInvoice);

    }, [purchaseInvoiceForPopupDetails])

    const savePurchaseInvoice = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();
        const newValidate = { ...validate };
        if (purchaseInvoiceForPopupDetails.length > 0) {
            if (!(purchaseInvoice.merchant_id > 0)) {
                newValidate.merchant_id = "Required!";
            } else {
                newValidate.merchant_id = "";
            }

            if (!purchaseInvoice.date.trim()) {
                newValidate.date = "Required!";
            } else {
                newValidate.date = "";
            }
            debugger;

            purchaseInvoice.purchase_invoice_details = (purchaseInvoiceForPopupDetails);
            if (Object.values(newValidate).every((error) => !error)) {
                debugger;
                try {
                    debugger;
                    const response: any = await ecommerceRepository.savePurchaseInvoice(purchaseInvoice);
                    debugger;
                    if (response[0].db_result > 0) {
                        debugger;
                        if (purchaseInvoice.purchase_invoice_id > 0) {
                            Swal.fire("Invoice created successfully", "", "success");

                            navigate.push('/purchaseinvoice');
                        }
                        else {
                            Swal.fire("Invoice updated successfully", "", "success");

                            navigate.push('/purchaseinvoice');
                        }
                    }



                } catch (error) {

                }
            } else {
                setValidate(newValidate)
            }
        } else {
            Swal.fire("Add Products", "", "warning");
        }



    }

    const invoiceChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setPurchaseInvoice((prev) => ({ ...prev, [name]: value }));
        } else {
            debugger;
            const { name, value } = e.target as HTMLSelectElement;
            setPurchaseInvoice((prev) => ({ ...prev, [name]: value }));
        }
    }

    const merchantChange = (selectedOption: any | null) => {
        debugger;
        setPurchaseInvoice(prevsetCategory => ({
            ...prevsetCategory,
            merchant_id: selectedOption ? selectedOption.value : '',
        }));
    };

    const loadMerchant = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterMerchant(inputValue);
        callback(filteredOptions);
    };

    const filterMerchant = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstMerchantDrp.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstMerchantDrp;
        }
    };

    useEffect(() => {
        viewMerchant();
        viewInvoices();
        for (let i = 0; i < purchaseInvoiceForPopupDetails.length; i++) {
            if (purchaseInvoiceForPopupDetails[i].product_name == '') {
                purchaseInvoiceForPopupDetails.splice(i, 1);
            }
        }
    }, [alert])

    const viewInvoices = async () => {
        debugger;
        try {
            debugger;
            const data: any = await ecommerceRepository.viewPurchaseInvoice({ "invoice_no": invoice_id });
            // data[0].date = new Date(data[0].date);
            debugger;
            // data[0].date = datePipe.transform(data[0].date, 'yyyy-MM-dd HH:mm:ss');;
            // data[0].date = '2024-02-22';
            // console.log('date',data[0].date )
            setPurchaseInvoice(data[0]);
            // let details = JSON.parse(data[0].purchase_invoice_details);
            let details = (JSON.parse(((data[0].purchase_invoice_details.replace(/\n/g, "")).replace(/'/g, "\""))))
            setPurchaseInvoicePopUpDetails(details)
        }
        catch (error) {
            // setPurchaseInvoice([]);
        }
    }

    const viewMerchant = async () => {
        debugger;
        try {
            debugger;
            const data: any = await ecommerceRepository.viewMerchant();
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.merchant_legal_name,
                value: item.merchant_id.toString(),
            }));
            setMerchantDrp(options);
        }
        catch (error) {
            setMerchantDrp([]);
        }
    }

    const handleAddButtonClick = (productDetails: any) => {
        debugger;

        if (productDetails.popup == 'add') {
            $('#addProductModelClose').click();
        }

        // setPurchaseInvoicePopUpDetails([productDetails]);
        for (let i = 0; i < purchaseInvoiceForPopupDetails.length; i++) {
            if (purchaseInvoiceForPopupDetails[i].product_name == '') {
                purchaseInvoiceForPopupDetails.splice(i, 1);
            }
        }

        let product = purchaseInvoiceForPopupDetails.filter((x: any) => x.product_name == productDetails.product_name)
        if (product.length > 0) {
            debugger;
            for (let j = 0; j < purchaseInvoiceForPopupDetails.length; j++) {
                debugger;
                if (purchaseInvoiceForPopupDetails[j].product_name == productDetails.product_name) {
                    purchaseInvoiceForPopupDetails[j].purchase_invoice_product_id = productDetails.purchase_invoice_product_id;
                    purchaseInvoiceForPopupDetails[j].purchase_invoice_id = productDetails.purchase_invoice_id;
                    purchaseInvoiceForPopupDetails[j].product_details_id = productDetails.product_details_id;
                    purchaseInvoiceForPopupDetails[j].product_name = productDetails.product_name;
                    purchaseInvoiceForPopupDetails[j].hsn_no = productDetails.hsn_no;
                    purchaseInvoiceForPopupDetails[j].qty = productDetails.qty;
                    purchaseInvoiceForPopupDetails[j].price = productDetails.price;
                    purchaseInvoiceForPopupDetails[j].gross = productDetails.gross;
                    purchaseInvoiceForPopupDetails[j].discount = productDetails.discount;
                    purchaseInvoiceForPopupDetails[j].tax_per = productDetails.tax_per;
                    purchaseInvoiceForPopupDetails[j].tax = productDetails.tax;
                    purchaseInvoiceForPopupDetails[j].net = productDetails.net;
                }
            }
            setPurchaseInvoicePopUpDetails([...purchaseInvoiceForPopupDetails]);
        }
        else {
            debugger;
            setPurchaseInvoicePopUpDetails((prevPurchaseInvoicePopUpDetails: any) => [...prevPurchaseInvoicePopUpDetails, productDetails]);
            // setPurchaseInvoicePopUpDetails([...productDetails]);
        }

        debugger;



    }

    const edit = async (d: any) => {
        debugger;
        setPurchaseInvoiceDetails(d)
    }

    const remove = async (d: any) => {
        debugger;

        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            let index = purchaseInvoiceForPopupDetails.findIndex((x: any) => x.product_details_id == d);
            if (index >= 0) {
                purchaseInvoiceForPopupDetails.splice(index, 1);
            }
            setPurchaseInvoicePopUpDetails([...purchaseInvoiceForPopupDetails]);
            Swal.fire("Record Deleted successfully", "", "success");
        } else {
            Swal.fire("Your record is safe!", "", "info");
        }

    }

    const addNew = async () => {
        debugger;

        setPurchaseInvoiceDetails({
            purchase_invoice_product_id: 0,
            purchase_invoice_id: 0,
            product_details_id: 0,
            product_name: '',
            hsn_no: '',
            qty: 0,
            price: 0,
            gross: 0,
            discount: 0,
            tax_per: 0,
            tax: 0,
            net: 0,
        })
    }

    const getinvoice = async (params: any) => {
        debugger;
        const queryParams = {
            id: params.product_details_id,
            title: params.title,
            skuid: params.sku_id,
            product_id: params.product_id,
        };

        const queryString = new URLSearchParams(queryParams).toString();



        navigate.push(`/purchaseinvoice?${queryString}`);

    }



    return {
        purchaseInvoiceForPopupDetails,
        purchaseInvoice, purchaseInvoiceDetails, validate, lstMerchantDrp, addNew,
        // pIDetails,lstProductsDrp,pIDetailsValidate,
        savePurchaseInvoice, invoiceChange, merchantChange, loadMerchant, setPurchaseInvoiceDetails, handleAddButtonClick,
        edit, remove, getinvoice
        //productChange, loadProduct ,detailsChange ,addDetails
    }

}