import React from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './master-components/navbar';

import Footer from './master-components/footer';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Login from './school-components/Login/LoginView';
import SideMenuView from './master-components/SideMenu/SideMenuView';
import '../node_modules/rsuite/dist/rsuite.css';
// import Users from './components/users';
// import States from './components/state';
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// import District from './components/district';
function App() {
  return (
    <div className="App">
     <div className="wrapper">
     <Router>
        
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/">
     <html lang="en" data-sidenav-size='condensed'>
        {/* ... other attributes */}
      </html>

      {/* Header starts here... */}
        {/* <Navbar/> */}
        <SideMenuView/>
      {/* Header ends here... */}
      

      {/* Footer starts here... */}
        
        <Footer/>
      {/* Footer ends here... */}
      </Route>
        </Switch>
      </Router>
      </div>
    </div>
  );
}

export default App;
