import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { EcommerceRepository } from "../../repository/EcommerceRepository";

export function BrandViewModel() {


    const ecommerceRepository = EcommerceRepository();
    const imagePath = ecommerceRepository.imagePath;

    const [lstBrand, setBrands] = useState({
        data: []
    })

    const [lstFilterBrand, setFilterBrand] = useState(
        []
    )




    const [validate, setValidate] = useState({
        brand_id: 0,
        brand_name: '',
        label_name: '',
        brand_image: '',
        notes: '',
        updated_date: '',
    })


    const [brand, setBrand] = useState({
        brand_id: 0,
        brand_name: '',
        label_name: '',
        brand_image: '',
        notes: '',
        updated_date: ''


    })

    useEffect(() => {

        getBrands();

    }, [alert]);




    const [currentPage, setCurrentPage] = useState(1);
    debugger;
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filteredBrands = lstFilterBrand.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstFilterBrand.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            debugger;
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    }

    const [file, setFile] = useState<File | undefined>(undefined);
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    const fileInputRef = useRef<HTMLInputElement | null>(null);


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setBrand((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setBrand((prev) => ({ ...prev, [name]: value }));
        }
    }


    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        debugger;
        if (e.target instanceof HTMLInputElement && e.target.files && e.target.files.length > 0) {
            debugger;
            const selectedFile = e.target.files[0];

            if (selectedFile.size > ((1024 * 1024) * 3)) {
                Swal.fire("Opps", "Max Image size is 3MB ", "error");
            }
            else {
                setFile(selectedFile);
                // Read the file and convert it to a data URL
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImageSrc(reader.result as string);
                };
                reader.readAsDataURL(selectedFile);
                debugger;
            }
        }
    };



    const newAddBrandclose = () => {
        cancel();

    }





    const [searchTerm, setSearchTerm] = useState('');


    const handleSearch = (e: any) => {
        debugger;
        let filteredBrands = lstBrand.data.filter((brand: any) =>
            brand.brand_name.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFilterBrand(filteredBrands);
        setSearchTerm(e.target.value);
    };



    const cancel = () => {
        debugger;

        setBrand({
            brand_id: 0,
            brand_name: '',
            label_name: '',
            brand_image: '',
            notes: '',
            updated_date: '',
        })

        setValidate({
            brand_id: 0,
            brand_name: '',
            label_name: '',
            brand_image: '',
            notes: '',
            updated_date: '',
        })


    }




    const saveBrands = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const newValidate = { ...validate };

        if (!brand.brand_name.trim()) {
            newValidate.brand_name = "Brand Name  required"
            setValidate(newValidate);
        }

        if (!brand.label_name.trim()) {
            newValidate.label_name = "Type required"
            setValidate(newValidate);
        }
        // if (!varient.type.trim()) {
        //     newValidate.type = "Type required"
        // }
        else {

            newValidate.brand_name = "";
            newValidate.label_name = "";
            //newValidate.notes ="";

            setValidate(newValidate);
        }

        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger;

                let date = new Date();
                let imageName = (brand.brand_id) + date.getTime().toString();
                brand.brand_image = imageName;


                const responseData: any = await ecommerceRepository.saveBrand(brand)

                if (responseData[0].db_result > 0) {
                    debugger;
                    cancel();

                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;
                    debugger;
                    if (buttonElement) {
                        buttonElement.click();
                    }
                    debugger;
                    if(file){
                        await uploadImage();
                    }
                  
                     getBrands();

                }
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            } catch (error) {
            }
        } else {
            setValidate(newValidate);
        }
    }


    const getBrands = async () => {
        try {
            debugger;
            const data: any = await ecommerceRepository.getBrands();

            setBrands({
                data
            })

            setFilterBrand(data);

        }
        catch (error) {
            setBrands({
                data: []
            })

        }
    }

    const editBrands = (e: any) => {
        debugger;
        setBrand(e);
        // console.log(brand)

    }


    const uploadImage = async () => {
        debugger;
        if (file) {
            debugger;
            const formData = new FormData(); 
          
            formData.append('file', file);
            formData.append('imageToSave', brand.brand_image);
            try {
                debugger;
                const responseData: any = await ecommerceRepository.uploadImage(formData);
                // window.location.reload();
                debugger;
                console.log('Image uploaded successfully:', responseData.imageUrl);
                debugger;
            }
            catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    }


    const deleteBrands = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
            const responseData: any = await ecommerceRepository.deleteBrand({ 'brand_id': e });
            debugger;
            if (responseData[0].db_result > 0) {

                Swal.fire("Record Deleted successfully", "", "success");
                getBrands();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }


    return {
        setBrand, brand, setValidate, validate, setSearchTerm, searchTerm, ecommerceRepository, saveBrands, setBrands,newAddBrandclose,
        imageSrc,fileInputRef,imagePath,
        lstBrand, handleChange, cancel, getBrands, filteredBrands, numbers, prePage, nextPage, changePage, currentPage, editBrands, deleteBrands, handleSearch, handleImageChange,
        uploadImage
    }
} 