import { data } from "jquery";
import { useEffect, useState } from "react"
import Swal from "sweetalert2";
import { EcommerceRepository } from "../../repository/EcommerceRepository";

export function OrderConfirmationmodel() {  
    const ecommerceRepository = EcommerceRepository();
    const imagePath = ecommerceRepository.imagePath

    const [lstvieworders, setvieworders] = useState([{
        order_id: 0,
        order_datetime: '',
        order_status: '',
        customer_id:'',
        ship_addres_type:'',
        ship_customer_full_name:'',
       ship_phone_number:'',
        ship_alternate_phone_number:'',
        ship_pincode:'',
        state_name:'',
        ship_city:'',
        ship_house_no_bulding_name:'',
        ship_road_area_colony:'',
        bill_customer_full_name:'',
        bill_phone_number:'',
        bill_alternate_phone_number:'',
        bill_pincode:'',
        bill_state_id:'',
        bill_city:'',
        bill_house_no_bulding_name:'',
        bill_road_area_colony:'',
        gross_amount:'',
        waybill_no:'',
        coupon_discount:'',
        order_discount:'',
        order_tax:'',
        delivery_charges:'',
        net_total:'',
        payment_mode:'',
        email_id:'',
        _order_date:'', 
        title:'',
        qty:0,
        selling_price:0,
        delivery_status:'',
        image:'', 
        variants:'',
        orderDetails:[] as any

    }])    


    const [lstOrderDetails, setOrderDetails] = useState({
        order_id: 0,
        order_datetime: '',
        order_status: '',
        customer_id:'',
        ship_addres_type:'',
        ship_customer_full_name:'',
       ship_phone_number:'',
        ship_alternate_phone_number:'',
        ship_pincode:'',
        state_name:'',
        ship_city:'',
        ship_house_no_bulding_name:'',
        ship_road_area_colony:'',
        bill_customer_full_name:'',
        bill_phone_number:'',
        bill_alternate_phone_number:'',
        bill_pincode:'',
        bill_state_id:'',
        bill_city:'',
        bill_house_no_bulding_name:'',
        bill_road_area_colony:'',
        gross_amount:'',
        waybill_no:'',
        coupon_discount:'',
        order_discount:'',
        order_tax:'',
        delivery_charges:'',
        net_total:'',
        payment_mode:'',
        email_id:'',
        _order_date:'', 
        qty:0,
        selling_price:0,
        title:'',
        delivery_status:'',
        image:'', 
        variants:'',
        orderDetails : [] as any

    }) 



    const getCurrentDate=()=> {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        let month:any = currentDate.getMonth() + 1;
        let day:any= currentDate.getDate();
      
        // Add leading zero to single-digit months and days
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;
      
        return `${year}-${month}-${day}`;
      }
      const [selectedDate, setSelectedDate] = useState({
        _order_date :getCurrentDate()
    }); 
    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setSelectedDate((prev) => ({ ...prev, [name]: value }));
        } 
    }

    const viewOrders = async () => {
        debugger;
        try {
            debugger;
            const data: any = await ecommerceRepository.vieworders(selectedDate._order_date);
            debugger;
            let filterorders = data.filter((orderStatus: any) => orderStatus.order_status == 'Created' )

            setvieworders(data);

            setlstOrderstatus(filterorders);
        }
        catch (error) {
            setvieworders(
                []
            )
        }
    } 

    const viewOrderDetails = (e: React.FormEvent) => {
        debugger;
        e.preventDefault();
    }




    const editOrders = (e: any) => {
        debugger; 
        
       // setOrderDetails(e)
        let details = {...lstOrderDetails};
        details = e;
        // details.orderDetails = JSON.parse(e.orderDetails)
        details.orderDetails = JSON.parse( ((e.orderDetails.replace(/\n/g, "")).replace(/'/g,"\"")));
        debugger;
        setOrderDetails(details); 
      
   
    } 
   const prepareForwardShipment=async ()=>
   {

   let shipmentDetails:any=[{UserName:lstOrderDetails.bill_customer_full_name,DeliveryAddress:lstOrderDetails.ship_house_no_bulding_name,Zip:lstOrderDetails.ship_pincode,
    City:lstOrderDetails.ship_city,State:lstOrderDetails.state_name,Country:'India',PhoneNo:lstOrderDetails.ship_phone_number,OrderNo:lstOrderDetails.order_id,PaymentMode:''}]
    const responseData:any = await  ecommerceRepository.prepareForwardShipment(shipmentDetails);

    if (responseData.length > 0 && responseData.packages.length>0) {
        window.location.reload();
       //  responseData.packages[0].waybill;

    }

   }



    const orderConfirmStatusUpdate=async (order_status:any,id:any)=>
{
    debugger;
    Swal.fire({
        title: 'Do you want to '+(order_status=='Confirm'?'Confirm':'Cancel')+'?',
        text: "This action cannot be undone!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, continue!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          // User clicked "Yes, continue!" button
          const responseData:any = await  ecommerceRepository.orderConfirmStatusUpdate({order_status:order_status,order_id:id});

          if (responseData[0].db_result > 0) {
            Swal.fire(order_status+" successfully", order_status+" successfully", "success").then((action)=>
            {
                if (action.isConfirmed) {
if(order_status=='Confirm')
{
    prepareForwardShipment();
}else{
    window.location.reload();
}
              



                }
            })
             ;
        
          }else{

          }


        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // User clicked the cancel button
          Swal.fire('Cancelled', 'Your action has been cancelled.', 'info');
        }
      });
}


const [lstOrderstatus, setlstOrderstatus] = useState([{
    order_id: 0,
    order_datetime: '',
    order_status: '',
    customer_id:'',
    ship_addres_type:'',
    ship_customer_full_name:'',
   ship_phone_number:'',
    ship_alternate_phone_number:'',
    ship_pincode:'',
    state_name:'',
    ship_city:'',
    ship_house_no_bulding_name:'',
    ship_road_area_colony:'',
    bill_customer_full_name:'',
    bill_phone_number:'',
    bill_alternate_phone_number:'',
    bill_pincode:'',
    bill_state_id:'',
    bill_city:'',
    bill_house_no_bulding_name:'',
    bill_road_area_colony:'',
    gross_amount:'',
    waybill_no:'',
    coupon_discount:'',
    order_discount:'',
    order_tax:'',
    delivery_charges:'',
    net_total:'',
    payment_mode:'',
    email_id:'',
    _order_date:'', 
    title:'',
    qty:0,
    selling_price:0,
    delivery_status:'',
    image:'', 
    variants:'',
    orderDetails:[] as any

}])  


const viewfillterorders = (e: any) => {
    debugger;
    // if(e=='Created'){
    //     let filterorders = lstvieworders.filter((orderStatus: any) => orderStatus.order_status ==e)
    //     setlstOrderstatus(filterorders)
    // }else if(e=='Confirmed'){
    //     let filterorders = lstvieworders.filter((orderStatus: any) => orderStatus.order_status ==e  )
    //     setlstOrderstatus(filterorders)

    // }
   
    // else if(e=='Canceled'){
    //     let filterorders = lstvieworders.filter((orderStatus: any) => orderStatus.order_status ==e )

    //     console.log(filterorders)
    //     setlstOrderstatus(filterorders)

    // }
    let filterorders = lstvieworders.filter((orderStatus: any) => orderStatus.order_status ==e )

    console.log(filterorders)
    setlstOrderstatus(filterorders)
};







    useEffect(() => {
        viewOrders();


    }, [alert]);



return{getCurrentDate, lstvieworders,viewOrders,handleDateChange,selectedDate,editOrders,viewOrderDetails,lstOrderDetails,imagePath,orderConfirmStatusUpdate,lstOrderstatus,viewfillterorders

}

}