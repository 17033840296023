import { useEffect, useState } from "react";
import { EcommerceRepository } from "../../repository/EcommerceRepository";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";


export function ProductViewModel() {



    const ecommerceRepository = EcommerceRepository();
    const imagePath = ecommerceRepository.imagePath;
    const history = useHistory();

    const [lstProducts, setProducts] = useState([{
        product_details_id: 0,
        product_id: 0,
        title: '',
        category_name:'',
        sku_id: '',
        status: '',
        mrp: 0,
        selling_price: 0,
        dispatched_days: 0,
        stock: 0,
        notify_qty: 0,
        height: 0,
        length: 0,
        bradth: 0,
        weight: 0,
        pack_qty: 0,
        local_delivery_charges: 0,
        zonal_delivery_charges: 0,
        national_delivery_charges: 0,
        notes: '',
        updated_date: '',
        product_variant_id: 0,
        product_variants_title: '',
        product_variant_value: '',
        category_id: 0,
        brand_id: 0,
        hsn_code: '',
        cess: 0,
        manufacturer: '',
        packer: '',
      
    }]) 


    const [lstFilterProduct, setFilterProduct] = useState([{
        product_details_id: 0,
        product_id: 0,
        title: '', 
        category_name:'',
        sku_id: '',
        status: '',
        mrp: 0,
        selling_price: 0,
        dispatched_days: 0,
        stock: 0,
        notify_qty: 0,
        height: 0,
        length: 0,
        bradth: 0,
        weight: 0,
        pack_qty: 0,
        local_delivery_charges: 0,
        zonal_delivery_charges: 0,
        national_delivery_charges: 0,
        notes: '',
        updated_date: '',
        product_variant_id: 0,
        product_variants_title: '',
        product_variant_value: '',
        category_id: 0,
        brand_id: 0,
        hsn_code: '',
        cess: 0,
        manufacturer: '',
        packer: '',
    }]) 

    const [product, setProduct] = useState({
        product_details_id: 0,
        product_id: 0,
        title: '', 
        category_name:'',
        sku_id: '',
        status: '',
        mrp: 0,
        selling_price: 0,
        dispatched_days: 0,
        stock: 0,
        notify_qty: 0,
        height: 0,
        length: 0,
        bradth: 0,
        weight: 0,
        pack_qty: 0,
        local_delivery_charges: 0,
        zonal_delivery_charges: 0,
        national_delivery_charges: 0,
        notes: '',
        updated_date: '',
        product_variant_id: 0,
        product_variants_title: '',
        product_variant_value: '',
        category_id: 0,
        brand_id: 0,
        hsn_code: '',
        cess: 0,
        manufacturer: '',
        packer: '',

    })  


    const viewProducts = async () => {
        debugger;
        const data: any = await ecommerceRepository.ViewProducts('');
        debugger;
        if (data.length > 0) {
            debugger;
            setProducts(data)
        } 
        setFilterProduct(data)
    }


    const editProduct = async (params: any) => {
        debugger;
        const queryParams = {
            id: params.product_details_id,
            title: params.title,
            skuid: params.sku_id,
            product_id: params.product_id,
        };

        const queryString = new URLSearchParams(queryParams).toString();

        history.push(`/listings?${queryString}`);

    }


    const deleteProduct = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
            const responseData: any = await ecommerceRepository.deleteProduct({ 'product_details_id': e });
            if (responseData[0].db_result > 0) {
                debugger;
                Swal.fire("Record Deleted successfully", "", "success");
                viewProducts();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }


    const addListing = (newparams: any) => {
        debugger;

        const queryParams = {
            id: "",
            title: "",
            skuid: '',
            product_id: "",
        };

        const queryString = new URLSearchParams(queryParams).toString();

        history.push(`/listings?${queryString}`);


    } 

    const [searchTerm, setSearchTerm] = useState('');   

    const handleSearch = (e: any) => {
        debugger;
        let filteredProducts = lstProducts.filter((product: any) => 
         product.title.toLowerCase().includes(e.target.value.toLowerCase()) || product.category_name.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFilterProduct(filteredProducts);
       // setFilterProduct({...filteredProducts});
        setSearchTerm(e.target.value);
    };


    useEffect(() => {
        viewProducts();
    }, [alert]); 

  

    const [currentPage, setCurrentPage] = useState(1);
    debugger;
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filteredProducts = lstFilterProduct.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstFilterProduct.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            debugger;
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    }



    return { viewProducts, lstProducts, imagePath, deleteProduct, editProduct, addListing,searchTerm,handleSearch,prePage, nextPage, changePage, currentPage,numbers,filteredProducts }

}