import React from "react";
import TreePicker from 'rsuite/TreePicker';
import '../../../node_modules/rsuite/TreePicker/styles/index.less';
import { ListingViewModel } from "./Listingmodel";
import AsyncSelect from "react-select/async";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ProductViewModel } from "../Products/Productmodel";




const ListingView: React.FC = () => {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const param1 = queryParams.get('id');
    const param2 = queryParams.get('title');
    const param3 = queryParams.get('skuid');
    const param4 = queryParams.get('product_id');

    const listingmodel = ListingViewModel(param3, param1, param4);



    return (
        <div className="content-page">
            <div className="content">

                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">Listings </li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Product Listing</h4>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="card mb-3">
                            <div className="row m1 pt-2 pb-2">

                                {/* <div className="col-md-10">
                                    {listingmodel.lstVariants && listingmodel.lstVariants.map((item:any)=>(
                                        <button className={listingmodel.listingDetail.product_details_id == item.product_details_id ? 'btn btn-secondary mx-2' : 'btn btn-outline-secondary mx-2'} onClick={(e)=>listingmodel.selectVariant(item)}>{item.title}</button>
                                    ))}
                                </div> */}

                                <div className="col-md-8">
                                    {listingmodel.lstVariants && listingmodel.lstVariants.map((item: any) => (
                                        <button className={listingmodel.listingDetail.product_details_id == item.product_details_id ? 'btn btn-secondary mx-2' : 'btn btn-outline-secondary mx-2'}
                                            onClick={(e) => listingmodel.selectVariant(item, 'getVariants')}>{item.title}</button>
                                    ))}
                                </div>
                                <div className="col-md-4 text-end" >
                                    <button className="btn btn-primary mx-2" onClick={listingmodel.getProducts}>View Products</button>
                                    <button className="btn btn-primary mx-2" onClick={listingmodel.newVariant}>Add Variant</button>
                                </div>
                            </div>
                        </div>

                        <div className="card mb-3">
                            <form onSubmit={listingmodel.saveProduct}>
                                <div className="row m-1 pt-2 pb-2">
                                    <div className="col-8">
                                        <div className="row">
                                            <div className="col-3">
                                                <label className="form-label">Category</label>
                                                <TreePicker
                                                    defaultExpandAll
                                                    data={listingmodel.lstCategoryDrp}
                                                    appearance="default"
                                                    onChange={listingmodel.handleCategoryChange}
                                                    placeholder="Default"
                                                    value={
                                                        listingmodel.lstCategoryDrp.length > 0
                                                            ? (listingmodel.lstCategoryDrp.find((option: any) => option.value == listingmodel.product.category_id) || {}).value
                                                            : undefined
                                                    }
                                                    style={{ width: 246, zIndex: 9999 }}
                                                />
                                                {<div className="text-danger">{listingmodel.productValidate.category_name}</div>}
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">Brand</label>
                                                <AsyncSelect
                                                    isClearable
                                                    isSearchable
                                                    cacheOptions
                                                    defaultOptions={listingmodel.lstBrandDrp}
                                                    onChange={listingmodel.brandChange}
                                                    value={listingmodel.lstBrandDrp.length > 0 ? (listingmodel.lstBrandDrp.find((option: any) => option.value == listingmodel.product.brand_id) || null) : null}
                                                    loadOptions={listingmodel.loadBrand}
                                                />
                                                {<div className="text-danger">{listingmodel.productValidate.brand_name}</div>}
                                            </div>
                                            <div className="col-md-3">
                                                <label className="form-label">HSN</label>
                                                {/* <input type="text" name="hsn_code" className="form-control" placeholder="HSN code" value={listingmodel.product.hsn_code} onChange={listingmodel.handleProductChange} /> */}
                                                <AsyncSelect
                                                    isClearable
                                                    isSearchable
                                                    cacheOptions
                                                    defaultOptions={listingmodel.lstHSNDrp}
                                                    onChange={listingmodel.hsnChange}
                                                    value={listingmodel.lstHSNDrp.length > 0 ? (listingmodel.lstHSNDrp.find((option: any) => option.label == listingmodel.product.hsn_code) || null) : null}
                                                    loadOptions={listingmodel.loadHSN}
                                                />
                                                {<div className="text-danger">{listingmodel.productValidate.hsn_code}</div>}
                                            </div>

                                            <div className="col-md-3">
                                                <label className="form-label">Manufacturer</label>
                                                <input type="text" name="manufacturer" className="form-control" placeholder="Manufacturer" value={listingmodel.product.manufacturer} onChange={listingmodel.handleProductChange} />
                                            </div>

                                            <div className="col-3">
                                                <label className="form-label">Merchant</label>
                                                <AsyncSelect
                                                    isClearable
                                                    isSearchable
                                                    cacheOptions
                                                    defaultOptions={listingmodel.lstMerchantDrp}
                                                    onChange={listingmodel.merchantChange}
                                                    value={listingmodel.lstMerchantDrp.length > 0 ? (listingmodel.lstMerchantDrp.find((option: any) => option.value == listingmodel.product.merchant_id) || null) : null}
                                                    loadOptions={listingmodel.loadMerchant}
                                                />
                                                {<div className="text-danger">{listingmodel.productValidate.merchant_name}</div>}
                                            </div>

                                            <div className="col-md-3">
                                                <label className="form-label">Type</label>
                                                <select name="ProductType" className="form-control" value={listingmodel.product.ProductType} onChange={listingmodel.handleProductChange}>
                                                <option value="" selected aria-readonly disabled>Select...</option>
                                                    <option value="Kit" >Kit</option>
                                                    <option value="Item" >Item</option>
                                                    <option value="Service">Service</option>
                                                </select>
                                                {<div className="text-danger">{listingmodel.productValidate.ProductType}</div>}
                                            </div>


                                            {/* <div className="col-md-3" >

                                                <label className="form-label">Type</label>
                                                    <select name="ProductType"  className="form-control" value={listingmodel.product.ProductType} onChange={listingmodel.handleProductChange}>
                                                    <option  selected aria-readonly disabled>Select</option>
                                                        <option value="Kit">Kit</option>
                                                        <option value="Item">Item</option>
                                                        <option value="Service">Service</option>
                                                    </select>
                                                    {<div className="text-danger">{listingmodel.productValidate.ProductType}</div>}
                                            </div> */}

                                        </div>
                                    </div>
                                    <div className="col-4 my-auto">
                                        <div className="row">


                                            <div className="mb-0 col-md-12 text-end my-auto">

                                                <button type="button" className="btn btn-danger mx-2" onClick={listingmodel.cancelProduct}>Cancel</button>
                                                <button type="submit" className="btn btn-success"> Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-10">
                                <div className="card">
                                    <div className="row m-1">
                                        <div className="col-md-12 mt-2 mb-2">
                                            <div className="accordion" id="accordionExample1">
                                                <div className="accordion-item">

                                                    <h2 className="accordion-header" id="headingOne">
                                                        <button className="accordion-button" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                            aria-expanded="true" aria-controls="collapseOne">
                                                            Listing Information:
                                                            {(+listingmodel.productDetails.product_details_id) > 0 ? <i className="uil uil-check-circle  align-middle me-1 " style={{ color: 'green', fontSize: '35px', width: "89%", textAlign: "end" }}></i> : ''}

                                                        </button>


                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <form onSubmit={listingmodel.saveProductDetails}>

                                                                <div className="row">

                                                                    <div className="col-md-6" >
                                                                        <div className="row mb-2">

                                                                            <label className="col-3 col-form-label">Sku id<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="text" name="sku_id" value={listingmodel.productDetails.sku_id} onChange={listingmodel.handleProductDetailsChange} className="form-control" placeholder="SKU id" />
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.sku_id}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Title<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="text" name="title" value={listingmodel.productDetails.title} onChange={listingmodel.handleProductDetailsChange} className="form-control" placeholder="Title" />
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.title}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Status<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <select name="status" className="form-control"
                                                                                    value={listingmodel.productDetails.status} onChange={listingmodel.statusChange}>
                                                                                    <option selected disabled>Select</option>
                                                                                    <option value="Active">Active</option>
                                                                                    <option value="Inactive">Inactive</option>
                                                                                </select>

                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.status}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Mrp<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="mrp" value={listingmodel.productDetails.mrp} onChange={listingmodel.handleProductDetailsChange} placeholder="Mrp" />
                                                                                {/* {listingmodel.productDetailsValidate.mrp == 404 ? <div className="text-danger">Required!</div> : ''}  */}
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.mrp_validate}</div>}
                                                                            </div>

                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Selling price<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="selling_price" value={listingmodel.productDetails.selling_price} onChange={listingmodel.handleProductDetailsChange} placeholder="Selling price" />
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.selling_price_validate}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Dispatched days<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="dispatched_days" value={listingmodel.productDetails.dispatched_days} onChange={listingmodel.handleProductDetailsChange} placeholder="Dispatch days" />
                                                                                {/* {listingmodel.productDetailsValidate.dispatched_days == 404 ? <div className="text-danger">Required!</div> : ''} */}
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.dispatched_days_validate}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Stock<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="stock" value={listingmodel.productDetails.stock} onChange={listingmodel.handleProductDetailsChange} placeholder="Type Here..." />
                                                                                {/* {listingmodel.productDetailsValidate.stock == 404 ? <div className="text-danger">Required!</div> : ''} */}
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.stock_validate}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Notify qty<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="notify_qty" value={listingmodel.productDetails.notify_qty} onChange={listingmodel.handleProductDetailsChange} placeholder="Type Here..." />
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.notify_qty_validate}</div>}

                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Height<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="height" value={listingmodel.productDetails.height} onChange={listingmodel.handleProductDetailsChange} placeholder="Height" />
                                                                                {/* {listingmodel.productDetailsValidate.height == 404 ? <div className="text-danger">Required!</div> : ''} */}
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.height_validate}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Weight<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="weight" value={listingmodel.productDetails.weight} onChange={listingmodel.handleProductDetailsChange} placeholder="Weight" />
                                                                                {/* {listingmodel.productDetailsValidate.weight == 404 ? <div className="text-danger">Required!</div> : ''} */}
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.weight_validate}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Length<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="length" value={listingmodel.productDetails.length} onChange={listingmodel.handleProductDetailsChange} placeholder="Length" />
                                                                                {/* {listingmodel.productDetailsValidate.length == 404 ? <div className="text-danger">Required!</div> : ''} */}
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.length_validate}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Breadth<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="bradth" value={listingmodel.productDetails.bradth} onChange={listingmodel.handleProductDetailsChange} placeholder="Breadth" />
                                                                                {/* {listingmodel.productDetailsValidate.bradth == 404 ? <div className="text-danger">Required!</div> : ''} */}
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.bradth_validate}</div>}
                                                                            </div>
                                                                        </div>

                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Pack qty<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="pack_qty" value={listingmodel.productDetails.pack_qty} onChange={listingmodel.handleProductDetailsChange} placeholder="Pack qty" />
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.pack_qty_validate}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Local delivery charges<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="local_delivery_charges" value={listingmodel.productDetails.local_delivery_charges} onChange={listingmodel.handleProductDetailsChange} placeholder="Local delivery charges" />
                                                                                {/* {listingmodel.productDetailsValidate.local_delivery_charges == 404 ? <div className="text-danger">Required!</div> : ''}  */}
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.local_delivery_charges_validate}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Zonal delivery charges<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="zonal_delivery_charges" value={listingmodel.productDetails.zonal_delivery_charges} onChange={listingmodel.handleProductDetailsChange} placeholder="Zonal delivery charges" />
                                                                                {/* {listingmodel.productDetailsValidate.zonal_delivery_charges == 404 ? <div className="text-danger">Required!</div> : ''} */}
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.zonal_delivery_charges_validate}</div>}

                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">National delivery charges<span className="required-star">*</span></label>
                                                                            <div className="col-6">
                                                                                <input type="number" className="form-control" name="national_delivery_charges" value={listingmodel.productDetails.national_delivery_charges} onChange={listingmodel.handleProductDetailsChange} placeholder="National delivery charges" />
                                                                                {/* {listingmodel.productDetailsValidate.national_delivery_charges == 404 ? <div className="text-danger">Required!</div> : ''} */}
                                                                                {<div className="text-danger">{listingmodel.productDetailsValidate.national_delivery_charges_validate}</div>}
                                                                            </div>
                                                                        </div>
                                                                        <div className="row mb-2">
                                                                            <label className="col-3 col-form-label">Notes</label>
                                                                            <div className="col-6">
                                                                                <textarea name="notes" className="form-control" value={listingmodel.productDetails.notes} onChange={listingmodel.handleProductDetailsChange} placeholder="Notes" cols={30} rows={3}></textarea>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-md-9" style={{ textAlign: "end" }}>
                                                                                <button type="button" className="btn btn-secondary mx-2" onClick={listingmodel.cancelProductDetails}>Cancel</button>
                                                                                <button type="submit" className="btn btn-success">Save</button>
                                                                            </div>
                                                                        </div>


                                                                    </div>



                                                                    <div className="col-md-6">
                                                                        <div className="card">
                                                                            <div className="card-header px-3 py-2 mb-2" style={{ 'borderBottom': '1px solid #f1f2fe' }}>
                                                                                <h4 className="mb-0">Images</h4>
                                                                            </div>
                                                                            {/* <hr /> */}
                                                                            <div className="row m-1">
                                                                                {listingmodel.lstResource.length == 0 && listingmodel.resourceSubmit == true ?
                                                                                    <div className="col-md-12">
                                                                                        <h4 className="m-0 text-danger mb-3">Upload image here *  </h4>
                                                                                    </div>
                                                                                    : ''
                                                                                }
                                                                                {
                                                                                    listingmodel.lstResource && listingmodel.lstResource.map((item: any, index: number) => (
                                                                                        item.resource_name &&
                                                                                        <div className="col-md-3 mb-2">
                                                                                            <img src={(typeof (item.image) == 'undefined' || item.image == '' || item.image == null) ? (listingmodel.imagePath + item.resource_name + '.webp') : (item.image)} className="mb-1 product-image" style={{ 'width': '100%' }} />
                                                                                            <center>
                                                                                                <h3 className="m-0 text-danger">
                                                                                                    <i className="mdi mdi-close-circle text-danger" onClick={(e) => listingmodel.deleteImage(item, index)}></i>
                                                                                                </h3>
                                                                                                {/* {item.resource_name} */}
                                                                                            </center>
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                                <div className="col-md-12 mb-3">
                                                                                    <input type="file" name="product_image" id="" className="form-control" onChange={listingmodel.handleImageChange} />
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </form>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingfour">
                                                        <button className="accordion-button" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                            aria-expanded="true" aria-controls="collapseTwo">
                                                            Details :
                                                            {listingmodel.showCheckmark.Details == 'true' ? <i className="uil uil-check-circle  align-middle me-1 " style={{ color: 'green', fontSize: '35px', width: "94%", textAlign: "end" }}></i> : ''}

                                                        </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse "
                                                        aria-labelledby="headingfour" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <form onSubmit={listingmodel.saveFeatureDetails}>
                                                                {
                                                                    listingmodel.lstCategoryFeilds && listingmodel.lstCategoryFeilds.map((item: any, index: number) => (
                                                                        <div className="row mb-2" >
                                                                            <label className="col-3 col-form-label">{item.product_feild_title}<span className="required-star">*</span></label>
                                                                            <div className="col-3">
                                                                                {/* <input type="text" name="product_feild_value" value={item.product_feild_value} onChange={(e)=>listingmodel.handleFeildsChange(item.category_fields_id,e)} className="form-control" placeholder={'Enter' + ' ' + item.product_variants_title} /> */}

                                                                                <div className="input-group">
                                                                                    <input type="text" name="product_feild_value"
                                                                                        value={item.product_feild_value}
                                                                                        onChange={(e) => listingmodel.handleFeildsChange(item.category_fields_id, e)}
                                                                                        className="form-control" placeholder={'Enter' + ' ' + item.product_feild_title} />
                                                                                    {
                                                                                        item.is_required > 0 ? <div className="input-group-prepend"><span className="input-group-text text-danger" id="basic-addon1" ><i className="mdi mdi-alert-circle"></i></span></div> : ''
                                                                                    }
                                                                                </div>
                                                                                {/* {<div className="text-danger">{listingmodel.productFeatureDetailsValidate.product_feild_title}</div>} */}
                                                                                {<div className="text-danger">{item.value}</div>}
                                                                            </div>
                                                                        </div>
                                                                    ))}

                                                                <div className="row">
                                                                    <div className="col-md-6" style={{ textAlign: "end" }}>
                                                                        <button type="button" className="btn btn-secondary mx-2" onClick={listingmodel.cancelProductDetails}>Cancel</button>
                                                                        <button type="submit" className="btn btn-success" >Save</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingThree">
                                                        <button className="accordion-button" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseThree"
                                                            aria-expanded="true" aria-controls="collapseThree">
                                                            variants:
                                                            {listingmodel.showCheckmark.variants == 'true' ? <i className="uil uil-check-circle  align-middle me-1 " style={{ color: 'green', fontSize: '35px', width: "94%", textAlign: "end" }}></i> : ''}
                                                        </button>
                                                    </h2>
                                                    <div id="collapseThree" className="accordion-collapse collapse "
                                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            <form onSubmit={listingmodel.saveVariant}>
                                                                {listingmodel.lstVariantGroups && listingmodel.lstVariantGroups.map((item: any, index: number) => (
                                                                    <div className="row mb-2">
                                                                        <label className="col-3 col-form-label">{item.product_variants_title}<span className="required-star">*</span></label>


                                                                        {/* {listingmodel.lstVariantGroups[index].product_variant_value} */}
                                                                        <div className="col-3">
                                                                            <AsyncSelect
                                                                                isClearable
                                                                                isSearchable
                                                                                cacheOptions
                                                                                defaultOptions={item.variants}
                                                                                onChange={(e: any) => listingmodel.handleGroupChange(item.category_variant_id, e)}
                                                                                value={item.variants.length > 0 ? (item.variants.find((option: any) => option.value == (listingmodel.lstVariantGroups[index].product_variant_value)) || null) : null}
                                                                            // value={item.variants.length > 0 ? (item.variants.find((option: any) => option.value == (item.product_variant_value)) || null) : null}
                                                                            // loadOptions={listingmodel.loadGroups}
                                                                            />
                                                                            {<div className="text-danger">{item.validate}</div>}
                                                                        </div>
                                                                    </div>
                                                                ))
                                                                }
                                                                <div className="row">
                                                                    <div className="col-md-6" style={{ textAlign: "end" }}>
                                                                        <button type="button" className="btn btn-secondary mx-2" onClick={listingmodel.cancelProductDetails}>Cancel</button>
                                                                        <button type="submit" className="btn btn-success" >Save</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingFour">
                                                        <button className="accordion-button" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseFour"
                                                            aria-expanded="true" aria-controls="collapseFour">
                                                            Keywords:
                                                            {listingmodel.showCheckmark.keywords == 'true' ? <i className="uil uil-check-circle  align-middle me-1 " style={{ color: 'green', fontSize: '35px', width: "93%", textAlign: "end" }}></i> : ''}
                                                        </button>
                                                    </h2>
                                                    <div id="collapseFour" className="accordion-collapse collapse "
                                                        aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">

                                                            <div className="row mb-3">
                                                                <div className="col-md-12">
                                                                    <button className="btn btn-primary" onClick={listingmodel.addNewKeyword}>Add +</button>
                                                                </div>
                                                            </div>
                                                            <form onSubmit={listingmodel.saveKeywords}>
                                                                {
                                                                    listingmodel.lstKeywords && listingmodel.lstKeywords.map((item: any, index: number) => (
                                                                        <div className="row mb-2" >

                                                                            <div className="col-md-10">
                                                                                <input type="text" name="keyword"
                                                                                    value={item.keyword}
                                                                                    onChange={(e) => listingmodel.handleKeywordChange(index, e)}
                                                                                    className="form-control" placeholder="Enter keyword" />
                                                                                {<div className="text-danger">{item.validate}</div>}
                                                                            </div>
                                                                            <div className="col-md-2 my-auto">
                                                                                <h3 className="m-0 text-denger">
                                                                                    <i className="mdi mdi-close-circle text-danger" onClick={(e) => listingmodel.removeKeyword(index)}></i>
                                                                                </h3>
                                                                            </div>
                                                                        </div>
                                                                    ))}

                                                                <div className="row">
                                                                    <div className="col-md-6" style={{ textAlign: "end" }}>
                                                                        <button type="button" className="btn btn-secondary mx-2" onClick={listingmodel.cancelProductDetails}>Cancel</button>
                                                                        <button type="submit" className="btn btn-success" >Save</button>
                                                                    </div>
                                                                </div>
                                                            </form>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ListingView;