import { ReturnConformationModel } from "./ReturnConformationModel";

 


const ReturnConformationView: React.FC  = () => {
    const viewModel = ReturnConformationModel();

    return (

        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">Returns</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Returns </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div onSubmit={viewModel.viewReturns}>
                                        <div className="row">
                                            <div className="col-3">
                                                <label className="form-label">Return Date</label>
                                                <input className="form-control" id="example-date" type="date" value={viewModel.selectedDate._return_date || viewModel.getCurrentDate()} name="_return_date" onChange={viewModel.handleDateChange} />
                                            </div>
                                            <div className="col-3 mt-auto">
                                                <button className="btn btn-primary " onClick={viewModel.viewReturns}>GO </button>
                                            </div>
                                            {/* <div className="col-3 mt-auto">
                                                <a className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#user-modal" >
                                                    Order Details
                                                </a>
                                            </div> */}

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mt-3 mb-2">
                                            <div className="tab-pane show active" id="justified-tabs-preview" role="tabpanel">
                                                <ul className="nav nav-pills bg-nav-pills nav-justified mb-3" role="tablist">
                                                    <li className="nav-item" role="presentation"> 
                                                 
                                                        <a  data-bs-toggle="tab" aria-expanded="true"   className="nav-link rounded-0 active" aria-selected="false" role="tab" onClick={() => viewModel.viewFillterOrders('Created')}>
                                                            <i className="mdi mdi-home-variant d-md-none d-block"></i>
                                                            <span className="d-none d-md-block">Open</span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a  data-bs-toggle="tab" aria-expanded="true" className="nav-link rounded-0" aria-selected="false" role="tab"  onClick={() => viewModel.viewFillterOrders('Canceled')}>
                                                            <i className="mdi mdi-account-circle d-md-none d-block"></i>
                                                            <span className="d-none d-md-block">Cancelled</span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                  
                                                        <a data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0" aria-selected="false" role="tab" onClick={() => viewModel.viewFillterOrders('Confirmed')} >
                                                            <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                                                            <span className="d-none d-md-block">Confirmed</span>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                  
                                                  <a data-bs-toggle="tab" aria-expanded="false" className="nav-link rounded-0" aria-selected="false" role="tab" onClick={() => viewModel.viewFillterOrders('Received')} >
                                                      <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                                                      <span className="d-none d-md-block">Received</span>
                                                  </a>
                                              </li>
                                                

                                                    

                                                </ul>

                                                <div className="tab-content">
                                                    <div className="tab-pane active show" id="home1" role="tabpanel">
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="container-fluid">
                                                                    <div className="col-md-12">
                                                                        <div className="panel panel-default">

                                                                            <div className="panel-body table-responsive-sm">
                                                                                <table className="table table-condensed table-striped">
                                                                                    <thead className="table-dark">
                                                                                        <tr>
                                                                                            <th></th>
                                                                                            <th className="text-light">#</th>
                                                                                            <th className="text-light">Return date</th>
                                                                                            <th className="text-light">Return No </th>
                                                                                            <th className="text-light">Contact No</th>
                                                                                            <th className="text-light">Return Status</th>
                                                                                            {/* <th className="text-light">Delivery Status</th>
                                                                                            <th className="text-light">WayBill</th> */}
                                                                                            <th className="text-light">Action</th>

                                                                                        </tr>
                                                                                    </thead>

                                                                                    <tbody>

                                                                                        {
                                                                                            viewModel.lstOrderstatus && viewModel.lstOrderstatus.map((item: any, index: number) => (
                                                                                                <>
                                                                                                    <tr data-bs-toggle="collapse" data-bs-target={'#' + item.order_return_id} className="accordion-toggle">
                                                                                                        <td>
                                                                                                            {/* <button className="btn btn-default btn-xs p-0">
                                                                                                                <span className="uil uil-plus-square"></span>
                                                                                                            </button> */}
                                                                                                        </td>
                                                                                                        <td>{index + 1}</td>
                                                                                                        <td>{item.return_date}</td>
                                                                                                        <td>{item.order_return_id}</td>
                                                                                                        <td>{item.ship_phone_number}</td>
                                                                                                        <td>{item.return_status}</td>
                                                                                                        <td> <div className="col-3 mt-auto">
                                                                                                            <a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#user-modal" onClick={() => viewModel.editOrders(item)} >
                                                                                                                Action
                                                                                                            </a>
                                                                                                        </div></td>
                                                                                                    </tr>

                                                                                                    <tr>

                                                                                                    </tr>
                                                                                                </>
                                                                                            ))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="user-modal" className="modal fade" aria-labelledby="standard-modalLabel" data-bs-backdrop="static" aria-modal="true" role="dialog">
                <div className="modal-dialog modal-full-width">
                    <div className="modal-content">
                        <form >
                            <div className="modal-header">
                                <h4 className="modal-title" id="user-modalLabel">Return Details</h4>
                                <button type="button" className="btn-close" id="myModal" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="mb-2 col-md-12 mt-3" style={{ 'border': '1px solid #d1d1d1' }}>
                                        <div className="row">
                                            <div className="col-md-12 border-bottom">
                                                <h3>Return Details</h3>
                                            </div>
                                            <div className="col-md-3">
                                                <p><b>Return Date:</b>
                                                    {viewModel.lstReturnDetails.return_date}</p>
                                            </div>
                                            <div className="col-md-3">
                                                <p><b>Return No:</b>
                                                    {viewModel.lstReturnDetails.order_return_id}</p>
                                              
                                            </div>
                                            <div className="col-md-3">
                                                <p><b>Order No:</b>
                                                    {viewModel.lstReturnDetails.order_id}</p>
                                              
                                            </div>
                                            <div className="col-md-3">
                                                <p><b>Status:</b>
                                                    {viewModel.lstReturnDetails.return_status}</p>
                                               
                                            </div>
                                           
                                        </div>
                                    </div>

                                    <div className="mb-2 col-md-6 mt-3" style={{ 'border': '1px solid #d1d1d1' }}>
                                        <div className="row">
                                            <div className="col-md-12 border-bottom">
                                                <h3>Return Products</h3>
                                            </div>
                                        </div>
                                    
                                        {viewModel.lstReturnDetails.return_details && viewModel.lstReturnDetails.return_details.map((d:any,index:number)=>(
                                             <div className="row" key={index} style={{borderBottom: "1px solid rgb(209, 209, 209)"}}>
                                             <div className="col-md-4">
                                                 <img src={viewModel.imagePath + d.image + '.webp'} style={{ 'width': '100px', 'height': '100%', 'aspectRatio': '1/1', 'objectFit': 'contain' }} />
                                             </div>
                                             <div className="col-md-4">
                                                 <p><b>Product:</b>
                                                     {d.title}</p>
                                                     {
  d.variants.split(',').map((data: string, index: number) => (


 
 

        <p> <b key={index}>{ (data.split(':'))[0]!=''?(data.split(':')[0]+':'):''}</b>  {data.split(':')[1]}  </p>




                                                  

  ))
}

                                                 {/* <p><b>Color:</b>
                                                 d.variants</p>
                                                 <p><b>Size:</b> d.variants</p> */}
                                                 <p><b>Qty:</b>
                                                     {d.qty}</p>
                                             </div>
                                            
                                         </div>
                                        ))}
                                       
                                    </div>


                                    <div className="mb-2 col-md-6 mt-3" style={viewModel.lstReturnDetails.orderDetails.length>0?  { 'border': '1px solid #d1d1d1' }:{display:"none"}}>
                                        <div className="row">
                                            <div className="col-md-12 border-bottom">
                                                <h3>Replace Products</h3>
                                            </div>
                                        </div>
                                    
                                        {viewModel.lstReturnDetails.orderDetails && viewModel.lstReturnDetails.orderDetails.map((d:any,index:number)=>(
                                             <div className="row" key={index} style={{borderBottom: "1px solid rgb(209, 209, 209)"}}>
                                             <div className="col-md-4">
                                                 <img src={viewModel.imagePath + d.image + '.webp'} style={{ 'width': '100px', 'height': '100%', 'aspectRatio': '1/1', 'objectFit': 'contain' }} />
                                             </div>
                                             <div className="col-md-4">
                                                 <p><b>Product:</b>
                                                     {d.title}</p>
                                                     {
  d.variants.split(',').map((data: string, index: number) => (


 
 

        <p> <b key={index}>{ (data.split(':'))[0]!=''?(data.split(':')[0]+':'):''}</b>  {data.split(':')[1]}  </p>




                                                  

  ))
}

                                                 {/* <p><b>Color:</b>
                                                 d.variants</p>
                                                 <p><b>Size:</b> d.variants</p> */}
                                                 <p><b>Qty:</b>
                                                     {d.qty}</p>
                                             </div>
                                            
                                         </div>
                                        ))}
                                       
                                    </div>




                                    <div className="mb-2 col-md-12 mt-3" style={{ 'border': '1px solid #d1d1d1' }}>
                                        <div className="row">
                                            <div className="col-md-12 border-bottom">
                                                <h3>Delivery Details</h3>
                                            </div>
                                            <div className="col-md-4">
                                                <p><b>Name:</b> {viewModel.lstReturnDetails.ship_customer_full_name}</p>
                                                <p><b>Contact No:</b>  {viewModel.lstReturnDetails.ship_phone_number}</p>
                                                <p><b>City:</b> {viewModel.lstReturnDetails.ship_city}</p>
                                            </div>
                                            <div className="col-md-4">
                                                <p><b>State:</b>{viewModel.lstReturnDetails.state_name} </p>
                                                <p><b>Zip :</b> {viewModel.lstReturnDetails.ship_pincode}</p>
                                                <p><b>Country:</b> India</p>
                                                
                                            </div>
                                            <div className="col-md-4">
                                                <p><b>Address:</b></p>
                                                <p>{viewModel.lstReturnDetails.ship_house_no_bulding_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* <div className="modal-footer">
                                        <button type="submit" className="btn btn-primary">Confirm </button> 

                                        <button type="submit" className="btn btn-primary">Cancelled </button>
                                    </div>  */}
                                        <div className="col-md-12 text-end mb-2" style={viewModel.lstReturnDetails.return_status=='Created'?{display:"block"}: {display:"none"}}>
                                            <a className="btn btn-danger mb-2 mt-3 accept" onClick={(e) => viewModel.orderReturnStatusUpdate('Confirmed',viewModel.lstReturnDetails.order_return_id)}> Confirm</a>
                                            <a className="btn btn-danger mb-2 mt-3 ms-2 reject" onClick={(e) => viewModel.orderReturnStatusUpdate('Canceled',viewModel.lstReturnDetails.order_return_id)}> Cancel</a>
                                        </div>


                                        <div className="col-md-12 text-end mb-2"  style={viewModel.lstReturnDetails.return_status=='Confirmed'?{display:"block"}: {display:"none"}}>
                                            <a className="btn btn-danger mb-2 mt-3 accept" onClick={(e) => viewModel.orderReturnStatusUpdate('Received',viewModel.lstReturnDetails.order_return_id)}> Received</a>
                                            <a className="btn btn-danger mb-2 mt-3 ms-2 reject" data-bs-dismiss="modal"  > close</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" >Close </button>
                                {/* <button type="submit" className="btn btn-primary">Save</button> */}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>


    )


}
export default ReturnConformationView;