import { useEffect, useState } from "react"
import Swal from "sweetalert2";
import { EcommerceRepository } from "../../repository/EcommerceRepository";

export function HSNViewmodel()
{

    const ecommerceRepository = EcommerceRepository();

    const [lstHsn, setHsn] = useState({
        data: []
    }) 

    const [lstFilterHsn, setFilterHsn] = useState(
        []
    )
 

    const [hsn, setHsns] = useState({
        hsn_id: 0,
        hsn_code: '',
        tax_per: '',
        notes: '',
        updated_date: '',

    })  
    useEffect(() => {

        getHsns();

    }, [alert]);




    const [currentPage, setCurrentPage] = useState(1);
    debugger;
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filteredHsn = lstFilterHsn.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstFilterHsn.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            debugger;
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    }


  

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setHsns((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setHsns((prev) => ({ ...prev, [name]: value }));
        }
    }




    const [validate, setValidate] = useState({
        hsn_id: 0,
        hsn_code: '',
        tax_per: '',
        notes: '',
        updated_date: '',
    })  

 
    const cancel = () => {
        debugger;

        setHsns({
            hsn_id: 0,
            hsn_code: '',
            tax_per: '',
            notes: '',
            updated_date: '',
        })

        setValidate({
            hsn_id: 0,
            hsn_code: '',
            tax_per: '',
            notes: '',
            updated_date: '',
        })


    }

    const saveHsns = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const newValidate = { ...validate };

        if (!hsn.hsn_code.trim()) {
            newValidate.hsn_code = "Hsn Code  required"
            setValidate(newValidate);
        }

        if (!hsn.tax_per.trim()) {
            newValidate.tax_per = "Tax% required"
            setValidate(newValidate);
        }
        // if (!varient.type.trim()) {
        //     newValidate.type = "Type required"
        // }
        else {

            newValidate.hsn_code = "";
            newValidate.tax_per = "";
            //newValidate.notes ="";

            setValidate(newValidate);
        }

      
        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger;
               
                const responseData: any = await ecommerceRepository.saveHsn(hsn)

                if (responseData[0].db_result > 0) {
                    cancel();

                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;

                    if (buttonElement) {
                        buttonElement.click();
                    }

                  getHsns();
                }
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            } catch (error) {
            }
        } else {
            setValidate(newValidate);
        }
    }

    const getHsns = async () => {
        try {
            debugger;
            const data: any = await ecommerceRepository.getHsn();

            setHsn({
                data
            })

            setFilterHsn(data);

        }
        catch (error) {
            setHsn({
                data: []
            })

        }
    }


    const editHsn = (e: any) => {
        debugger;
        setHsns(e);
        // console.log(brand)

    } 


    const deleteHSn = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
            const responseData: any = await ecommerceRepository.deleteHsn({ 'hsn_id': e });
            debugger;
            if (responseData[0].db_result > 0) {

                Swal.fire("Record Deleted successfully", "", "success");
               getHsns();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }



    const [searchTerm, setSearchTerm] = useState('');  

    const handleSearch = (e: any) => {
        debugger;
        let filteredHsn = lstHsn.data.filter((hsn: any) =>
            hsn.hsn_code.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFilterHsn(filteredHsn);
        setSearchTerm(e.target.value);
    };







    return {filteredHsn,saveHsns,handleChange,validate,cancel,hsn,editHsn,deleteHSn,searchTerm,handleSearch,prePage, nextPage, changePage, currentPage,numbers


        

    }
}