import React from "react";
import { VarientGroupViewmodel } from "../VariantGroup/Varientgroupviewmodel";
import { BrandViewModel } from "./Brandviewmodel";


const BrandView: React.FC = () => {

    const brandViewModel = BrandViewModel();
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">Brand</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Brand </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                                <label>Search:<input type="search" className="form-control form-control-sm" value={brandViewModel.searchTerm} onChange={brandViewModel.handleSearch} /></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                        <a className="btn btn-danger mb-2"
                                        data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={brandViewModel.newAddBrandclose}>
                                            <i className="mdi mdi-plus-circle me-2"></i> Add Brand
                                        </a>
                                        </div>
                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Brand Name </th>
                                                            <th>Brand Image</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {brandViewModel.filteredBrands.map((d: any, index: number) => (
                                                            <tr key={d.brand_name}>
                                                                <td>{index + 1}</td>
                                                                <td>{d.brand_name}</td> 
                                                                <td><img src={brandViewModel.imagePath+d.brand_image+'.webp'} alt="" className="table-img" /></td>

                                                                <td>
                                                                    <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => brandViewModel.editBrands(d)}></i>
                                                                    {/* <i className="uil uil-edit-alt" id="myModal" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => brandViewModel.editBrands(d)}></i> */}
                                                                    {<i className="mx-2 uil uil-trash-alt" onClick={() => brandViewModel.deleteBrands(d.brand_id)}></i>}
                                                                </td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                                <nav className="text-right mt-3" >

                                                    <ul className='pagination float-end' >
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={brandViewModel.prePage}>prev</a>
                                                        </li>
                                                        {

                                                            brandViewModel.numbers.map((n, i) =>
                                                            (

                                                                <li key={i} className={`page-item ${brandViewModel.currentPage === n ? 'active' : ''}`} >
                                                                    <a className='page-link' onClick={() => brandViewModel.changePage(n)}>{n}</a>
                                                                </li>
                                                            ))
                                                        }
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={brandViewModel.nextPage}>next</a>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="standard-modal" className="modal fade " tabIndex={-1} aria-labelledby="standard-modalLabel" aria-modal="true" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={brandViewModel.saveBrands}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="standard-modalLabel">Add Brand </h4>
                                <button type="button" id="myModal" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={brandViewModel.cancel}></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <label>Name</label>
                                        <input type="text" id="brand_name" name="brand_name" value={brandViewModel.brand.brand_name} className="form-control" onChange={brandViewModel.handleChange} />
                                        {<div className="text-danger">{brandViewModel.validate.brand_name}</div>}
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <label >Type</label>
                                        <select name="label_name" id="label_name" value={brandViewModel.brand.label_name} className="form-control" onChange={brandViewModel.handleChange}>
                                            <option value="" selected aria-readonly disabled>Select</option>
                                            <option value="Publisher">Publisher</option>
                                            <option value="Brand">Brand</option>
                                        </select> 
                                        {<div className="text-danger">{brandViewModel.validate.label_name}</div>}
                                    </div>
                                    <div className="col-md-10 mb-2">
                                        <label >UPload file:</label>
                                        <input type="file" id="avatar" name="brand_image"  onChange={brandViewModel.handleImageChange} className="form-control" accept="image/png," />
                                    </div>
                                    <div className="col-md-2 mb-2 my-auto">
                                        <img src={brandViewModel.imageSrc} alt="Selected File" className="w-100"></img>  

                                        {/* {brandViewModel.imageSrc   alt="Selected File" />} */}
                                        { brandViewModel.brand.brand_name!=''  && !brandViewModel.imageSrc?  <img src={brandViewModel.imagePath+brandViewModel.brand.brand_image+'.webp'} className="w-100" alt="Selected File" />:''}
                                       
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Notes</label>
                                        <textarea name="notes" id="notes" className="form-control" value={brandViewModel.brand.notes} onChange={brandViewModel.handleChange}></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default BrandView;