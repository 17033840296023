import React from "react";
import { BannerViewModel } from "./Bannerviewmodel";
import AsyncSelect from "react-select/async";

const BannerView: React.FC = () => {
    const bannerViewModel = BannerViewModel();
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">Banner</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Banner </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                            <label>Search:<input type="search" className="form-control form-control-sm"
                                                    placeholder="" aria-controls="products-datatable" 
                                                    value={bannerViewModel.searchTerm} onChange={bannerViewModel.handleSearch}/></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a  className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#standard-modal">
                                                <i className="mdi mdi-plus-circle me-2"></i> Add Banners
                                            </a>
                                        </div>
                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Banner Name </th>
                                                            <th>Banner Image</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            bannerViewModel.lstBanners && bannerViewModel.lstBanners.map((item: any, index: number) => (
                                                                <tr>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.BannersName}</td>
                                                                    <td>
                                                                        <img src={(typeof (item.Image) == 'undefined' || item.Image == '' || item.Image == null) ?
                                                                            ('./assets/images/no-image.png') : (bannerViewModel.imagePath + item.Image + '.webp')}
                                                                            style={{ 'width': '40px' }} />
                                                                    </td>
                                                                    <td>
                                                                        <i onClick={(e)=>bannerViewModel.editBanner(item)} data-bs-toggle="modal" data-bs-target="#standard-modal" className="uil uil-edit-alt"></i>
                                                                        <i onClick={(e)=>bannerViewModel.deleteBanner(item.BannersID)} className="mx-2 uil uil-trash-alt"></i>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="standard-modal" className="modal fade " tabIndex={-1} aria-labelledby="standard-modalLabel" aria-modal="true" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={bannerViewModel.SaveBanner}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="standard-modalLabel">Add Brand </h4>
                                <button type="button" id='myModal' className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-md-12 mb-2">
                                        <label >Banner Name</label>
                                        <input type="text" name="BannersName" value={bannerViewModel.Banner.BannersName} className="form-control" onChange={bannerViewModel.handleChange} />
                                        {<div className="text-danger">{bannerViewModel.validate.BannersName}</div>}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label className="form-label">Banner Type</label>
                                        <select className="form-select" id="example-select" name="BannerType" value={bannerViewModel.Banner.BannerType} onChange={bannerViewModel.handleChange}>
                                            <option selected disabled>Select</option>
                                            <option value="Offer">Offer</option>
                                            <option value="Product">Product</option>
                                            <option value="Category">Category</option>
                                        </select>
                                        {<div className="text-danger">{bannerViewModel.validate.BannerType}</div>}
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <label style={{ 'visibility': 'hidden' }} className="form-label">dd</label>
                                        <AsyncSelect
                                            isClearable
                                            isSearchable
                                            cacheOptions
                                            defaultOptions={bannerViewModel.lstData}
                                            onChange={bannerViewModel.handleSelectChange}
                                            value={bannerViewModel.lstData.length > 0 ? (bannerViewModel.lstData.find((option: any) => option.value == bannerViewModel.Banner.BannerTypeId) || null) : null}
                                            loadOptions={bannerViewModel.loadBanner}
                                        />
                                        {<div className="text-danger">{bannerViewModel.validate.BannerTypeId_validate}</div>}
                                    </div>
                                    <div className="col-md-10 mb-2">
                                        <label >Image</label>
                                        <input type="file" name="Image" className="form-control" onChange={bannerViewModel.handleImageChange} />
                                    </div>
                                    <div className="col-md-2 mb-2">
                                        {bannerViewModel.imageSrc && <img src={bannerViewModel.imageSrc} className="w-100" />}
                                        {bannerViewModel.Banner.BannersName != '' && !bannerViewModel.imageSrc ? <img src={bannerViewModel.imagePath + bannerViewModel.Banner.Image + '.webp'} className="w-100" alt="Selected File" /> : ''}

                                    </div>
                                    {/* <div className="mb-3">
                                        <label className="form-label">Description</label>
                                        <textarea className="form-control" id="example-Notes" name="UpdateLogicColumn" rows={5}  onChange={bannerViewModel.handleChange}></textarea>
                                    </div> */}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={bannerViewModel.cancel}>Close </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default BannerView;