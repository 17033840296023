import React from "react";
import { CategoryViewModel } from "./Categoryviewmodel";
import AsyncSelect from "react-select/async";

const CategoryView:React.FC = ()=>{

    const categoryViewModel = CategoryViewModel();
    return(
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">Hyper </a></li>
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ECommerce </a></li>
                                        <li className="breadcrumb-item active">Category </li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Category </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                                <label>Search:
                                                <input type="search" className="form-control form-control-sm" value={categoryViewModel.searchTerm} onChange={categoryViewModel.handleSearch}/>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2"
                                                data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={categoryViewModel.newCategory}>
                                                <i className="mdi mdi-plus-circle me-2"></i> Add Category
                                            </a>
                                        </div>

                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Category</th>
                                                            <th>Parent Category </th>
                                                            <th>Image</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { categoryViewModel.filteredCategory.map((item:any,index:number)=>(
                                                            <tr key={item.category_id}>
                                                                <td>{index+1}</td>
                                                                <td>{item.category_name}</td>
                                                                <td>{item.parent_category_name}</td>
                                                                {/* <td><img src={categoryViewModel.imagePath+item.category_image+'.webp'} alt="" className="table-img" /></td> */}
                                                                <td><img src={`${categoryViewModel.imagePath}${item.category_image}.webp?${new Date().getTime()}`} alt="" className="table-img" /></td>
                                                                <td>
                                                                    <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => categoryViewModel.editCategory(item)}></i>
                                                                    <i className="mx-2 uil uil-trash-alt" onClick={() => categoryViewModel.deleteCategory(item.category_id)}></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                                <nav className="text-right mt-3" >
                                                    <ul className='pagination float-end' >
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={categoryViewModel.prePage}>prev</a>
                                                        </li>
                                                        {
                                                            categoryViewModel.numbers.map((n, i) =>
                                                            (
                                                                <li key={i} className={`page-item ${categoryViewModel.currentPage === n ? 'active' : ''}`} >
                                                                    <a className='page-link' onClick={() => categoryViewModel.changePage(n)}>{n}</a>
                                                                </li>
                                                            ))
                                                        }
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={categoryViewModel.nextPage}>next</a>
                                                        </li>
                                                    </ul>
                                                </nav>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div id="standard-modal" className="modal fade" tabIndex={-1} aria-labelledby="standard-modalLabel"
                aria-modal="true" role="dialog">
                <div className="modal-dialog  modal-lg">
                    <div className="modal-content">
                    <form onSubmit={categoryViewModel.saveCategory}>

                        <div className="modal-header">
                            <h4 className="modal-title" id="standard-modalLabel">Add Category</h4>
                            <button type="button" className="btn-close" id="myModal" data-bs-dismiss="modal" aria-label="Close" onClick={categoryViewModel.cancel}></button>
                        </div>
                        <div className="modal-body">                                                                                                                                                     
                            <div className="row">
                                <div className="col-md-6 mb-2">
                                    <label>Category Name</label>
                                    <input type="text" id="category_name" value={categoryViewModel.category.category_name} name="category_name" className="form-control" onChange={categoryViewModel.handleChange}/>
                                    {<div className="text-danger">{categoryViewModel.validate.category_name}</div>}
                                </div>

                                <div className="col-md-6 mb-2">
                                    <label>Parent Category</label>
                                    <AsyncSelect
                                        isClearable
                                        isSearchable
                                        cacheOptions
                                        defaultOptions={categoryViewModel.lstCategoryDrp}
                                        onChange={categoryViewModel.handleSelectChange}
                                        value={categoryViewModel.lstCategoryDrp.length > 0 ? (categoryViewModel.lstCategoryDrp.find((option: any) => option.value == categoryViewModel.category.parent_category_id) || null) : null}
                                        loadOptions={categoryViewModel.loadCategory}
                                    />
                                </div>

                                <div className="col-md-6 mb-2">
                                    <label >Upload file:</label>
                                    <input type="file" id="avatar" name="category_image" onChange={categoryViewModel.handleImageChange} className="form-control" />
                                    
                                </div>
                                <div className="col-md-2 mb-2">
                                    <img src={categoryViewModel.imageSrc} alt="" className="w-100"></img>
                                </div>

                                <div className="col-md-4">
                                    <label style={{'visibility':'hidden'}}>Varient Group</label><br/>
                                    <label >Is product configurable ?</label>
                                    <input type="checkbox" id="myCheckbox" name="myCheckbox" className="mx-2"
                                    value={categoryViewModel.category.product_configurable}
                                    checked={categoryViewModel.category.product_configurable == 1 ? true:false}
                                    onChange={e => categoryViewModel.handleProductConfigurable(e.target.checked)} />
                                </div>

                                {  categoryViewModel.category.product_configurable==1 ?  <div className="col-md-6">
                                  
                                 
                                   <label >Is Class configurable ?</label>
                                      <input type="checkbox" id="myCheckbox" name="myCheckbox" className="mx-2"
                                      value={categoryViewModel.category.is_class_configurable}
                                      checked={categoryViewModel.category.is_class_configurable == 1 ? true:false}
                                      onChange={e => categoryViewModel.handlecategoryConfigurable(e.target.checked) } /> 
                                    
                                  
                                </div> :''
                                    
                                    

                                }  

                                <div className="col-md-12">
                                    <div className="tab-pane show active" id="justified-tabs-preview" role="tabpanel">
                                        <ul className="nav nav-pills bg-nav-pills nav-justified mb-3" role="tablist">
                                            <li className="nav-item" role="presentation">
                                                <a href="#home1" data-bs-toggle="tab" aria-expanded="false"
                                                    className="nav-link rounded-0 active" aria-selected="true" role="tab">
                                                    <i className="mdi mdi-home-variant d-md-none d-block"></i>
                                                    <span className="d-none d-md-block">Varients</span>
                                                </a>
                                            </li>
                                            <li className="nav-item" role="presentation">
                                                <a href="#profile1" data-bs-toggle="tab" aria-expanded="true"
                                                    className="nav-link rounded-0" aria-selected="false" role="tab"
                                                    tabIndex={-1}>
                                                    <i className="mdi mdi-account-circle d-md-none d-block"></i>
                                                    <span className="d-none d-md-block">Category fields</span>
                                                </a>
                                            </li>
                                        </ul>

                                        <div className="tab-content">
                                            <div className="tab-pane active show" id="home1" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-6 mb-2">
                                                        <label>Varient Group</label>
                                                        <AsyncSelect
                                                            isClearable
                                                            isSearchable
                                                            cacheOptions
                                                            defaultOptions={categoryViewModel.lstVariantGroupsDrp}
                                                            onChange={categoryViewModel.handleVariantChange}
                                                            value={categoryViewModel.lstVariantGroupsDrp.length > 0 ? (categoryViewModel.lstVariantGroupsDrp.find((option: any) => option.value == categoryViewModel.categoryVariant.variant_group_id) || null) : null}
                                                            loadOptions={categoryViewModel.loadCategory}
                                                            />
                                                            {(categoryViewModel.variantSubmit==true && categoryViewModel.categoryVariant.variant_group_id ==0) ? (<div className="text-danger">Required </div>) : ''}
                                                    </div>

                                                    <div className="col-md-4 mb-2">
                                                        <label style={{'visibility':'hidden'}}>Varient Group</label><br/>
                                                        <label >is Required: </label> &nbsp;
                                                        <input type="checkbox" id="myCheckbox" name="myCheckbox" 
                                                        value={categoryViewModel.categoryVariant.is_required}
                                                        checked={categoryViewModel.categoryVariant.is_required == 1 ? true:false}
                                                        onChange={e => categoryViewModel.handleVariantRequiredChange(e.target.checked)} />
                                                    </div>
                                                    <div className="col-md-2 mb-2">
                                                        <br/>
                                                        <button type="button" className="btn btn-primary" onClick={categoryViewModel.addVariant}>Add </button>
                                                    </div>
                                                </div>

                                                <div className="mb-2 col-md-12 mt-3">
                                                    <div className="table-responsive-sm">
                                                        <table className="table table-centered mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Varient Group</th>
                                                                    <th>Required?</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {categoryViewModel.categoryVariants.map((item,index)=>(
                                                                        item.variant_group_name &&
                                                                        <tr key={index}>
                                                                        <td>{index+1}</td>
                                                                        <td>{item.variant_group_name}</td>
                                                                        <td>{(item.is_required>0 ? 'Yes':'No')}</td>
                                                                        <td>
                                                                            <i className="uil uil-edit-alt" onClick={()=>categoryViewModel.editVariant(item)}></i>
                                                                            <i className="mx-2 uil uil-trash-alt" onClick={()=>categoryViewModel.removeVariant(item)}></i>
                                                                        </td>
                                                                    </tr>
                                                                    ))}
                                                            </tbody>

                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane" id="profile1" role="tabpanel">
                                                <div className="row">
                                                    <div className="col-md-4 mb-2">
                                                        <label>Category Fields</label>
                                                        <input type="text" id="category_field_name" name="category_field_name" value={categoryViewModel.categoryFeild.category_field_name} className="form-control" onChange={categoryViewModel.handleCategoryChange}/>
                                                        {(categoryViewModel.categoryFeildSubmit==true && categoryViewModel.categoryFeild.category_field_name =='') ? (<div className="text-danger">Required </div>) : ''}
                                                    </div>
                                                    <div className="col-md-4 mb-2">
                                                        <label style={{'visibility':'hidden'}}>Varient Group</label><br/>
                                                        <label >is Required:</label>
                                                        <input type="checkbox" id="myCheckbox" name="myCheckbox" 
                                                        checked={categoryViewModel.categoryFeild.is_required == 1 ? true:false}
                                                        onChange={e => categoryViewModel.handleCategoryRequiredChange(e.target.checked)} />
                                                    </div>

                                                    <div className="col-md-4 mb-2">
                                                        <br/>
                                                        <button type="button" className="btn btn-primary" onClick={categoryViewModel.addCategory}>Add </button>
                                                    </div>
                                                </div>
                                                <div className="mb-2 col-md-12 mt-3">
                                                    <div className="table-responsive-sm">
                                                        <table className="table table-centered mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Field Name</th>
                                                                    <th>Required?</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                { 
                                                                    categoryViewModel.categoryFeilds.map((item,index)=> (
                                                                        item.category_field_name &&
                                                                        <tr key={index}>
                                                                        <td>{index+1}</td>
                                                                        <td>{item.category_field_name}</td>
                                                                        <td>{(item.is_required>0 ? 'Yes':'No')}</td>
                                                                        <td>
                                                                            <i className="uil uil-edit-alt" onClick={()=>categoryViewModel.editCategoryFeild(item)}></i>
                                                                            <i className="mx-2 uil uil-trash-alt" onClick={()=>categoryViewModel.removeCategoryFeild(item)}></i>
                                                                        </td>
                                                                    </tr>
                                                                    ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={categoryViewModel.cancel}>Close </button>
                            <button type="submit" className="btn btn-primary">Save </button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CategoryView;