import { Stateviewmodel } from "./StateViewModel";

const stateview:React.FC=()=>
 {

    const stateViewModel = Stateviewmodel() 
    

    return (

        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        {/* <li className="breadcrumb-item"><a href="javascript: void(0);">eCommerce </a></li> */}
                                        <li className="breadcrumb-item active">State </li>
                                    </ol>
                                </div>
                                <h4 className="page-title">State </h4>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                                <label >Search:<input type="search" className="form-control form-control-sm" value={stateViewModel.searchTerm} onChange={stateViewModel.handleSearch} /></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#user-modal" onClick={stateViewModel.addState}>
                                                <i className="mdi mdi-plus-circle me-2"></i> Add State
                                            </a>
                                        </div>

                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>State Code </th>
                                                            <th>State Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {stateViewModel.filteredStates.map((d: any, index: number) => (
                                                            <tr key={d.state_id}>
                                                                <td>{index + 1}</td>
                                                                <td>{d.state_code}</td>
                                                                <td>{d.state_name}</td>
                                                                <td>
                                                                    <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#user-modal" onClick={() => stateViewModel.editState(d)}></i>
                                                                    <i className="mx-2 uil uil-trash-alt" onClick={() => stateViewModel.deleteState(d.state_id)}></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                                <nav className="text-right mt-3" >

                                                    <ul className='pagination float-end' >
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={stateViewModel.prePage}>prev</a>
                                                        </li>
                                                        {

                                                            stateViewModel.numbers.map((n, i) =>
                                                            (

                                                                <li key={i} className={`page-item ${stateViewModel.currentPage === n ? 'active' : ''}`} >
                                                                    <a className='page-link' onClick={() => stateViewModel.changePage(n)}>{n}</a>
                                                                </li>
                                                            ))
                                                        }
                                                        <li className='page-item'>
                                                            <a className='page-link' onClick={stateViewModel.nextPage}>next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="user-modal" className='modal fade' tabIndex={-1} data-bs-backdrop="static" aria-labelledby="user-modalLabel" aria-modal="true"
                role="dialog" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={stateViewModel.saveState}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="user-modalLabel">Add State</h4>
                                <button type="button" id="myModal" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={stateViewModel.cancel}></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">


                                    <div className="col-md-12 mb-2">
                                        <label>State Name</label>
                                        <input type="text" id="state_name" name="state_name" value={stateViewModel.state.state_name} className="form-control" onChange={stateViewModel.handleChange} />
                                        {<div className="text-danger">{stateViewModel.validate.state_name}</div>}
                                    </div>
                                    <div className="col-md-12 mb-2">
                                        <label >State Code</label>
                                        <input type="text" id="state_code" name="state_code" value={stateViewModel.state.state_code} className="form-control" onChange={stateViewModel.handleChange} />
                                        {<div className="text-danger">{stateViewModel.validate.state_code}</div>}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={stateViewModel.cancel}>Close </button>
                                <button type="submit" className="btn btn-primary">Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    )



} 
export default stateview;