import { useEffect, useState } from "react"
import Swal from "sweetalert2"
// import { EcommerceRepository } from "../repository/EcommerceRepository";
import { EcommerceRepository } from "../../repository/EcommerceRepository";


export function VarientViewModel() {


    const ecommerceRepository = EcommerceRepository();

    const [lstVarient, setVarient] = useState({
        data: []
    })

    const [lstVarientGroup, setVarientGroups] = useState({
        data: []
    })


    const [lstFilterVarient, setFilterVarient] = useState(
        []
    )

    const [lstVarients, setVarientGroupDrop] = useState({
        data: []
    })



    const [varient, setVarients] = useState({
        variant_id: 0,
        variant_name: '',
        variant_group_id: 0,
        variant_group_name: '',
        variant_field_name: '',
        varient_fields_id: 0,
        notes: '',
        varient_fields: [] as any,
        updated_date: ''
    })


    const [newvarient, setNewVarient] = useState({
        variant_id: 0,
        variant_name: '',
        variant_group_id: 0,
        variant_group_name: '',
        variant_field_name: '',
        varient_fields_id: 0,
        notes: '',
        varient_fields: [] as any,
        updated_date: ''
    })


    const [index, setIndex] = useState(-1);

    const [validate, setValidate] = useState({
        variant_id: 0,
        variant_name: '',
        variant_group_id: 0,
        variant_group_name: '',
        variant_field_name: '',
        varient_fields_id: 0,
        notes: '',
        varient_fields: [] as any,
        updated_date: ''
   

    })

    const [lstVariantGroupsDrp, setVariantGroupsDrp] = useState<{ label: string; value: string }[] | []>([]); 
    const [varientFeildSubmit, setvarientFeildSubmit] = useState(false); 
   const [variantSubmit, setVariantSubmit] = useState(false); 

    const [varientFeilds, setvarientFeilds] = useState([{

        variant_id: 0,
        variant_name: '',
        variant_group_id: 0,
        variant_group_name: '',
        variant_field_name: '',
        notes: '',
        varient_fields_id: 0,
      
        varient_fields: [] as any,
        updated_date: ''
   

    }]);


   



    useEffect(() => {

        getVarients();
        getVarientGroups();

    }, [alert]);


    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: any) => {
        debugger;
        let filteredVarient = lstVarient.data.filter((varient: any) =>
            varient.variant_group_name.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFilterVarient(filteredVarient);
        setSearchTerm(e.target.value);
    };



    const cancel = () => {
        debugger;
      
        setvarientFeildSubmit(false);
    
        setVarients({
            variant_id: 0,
            variant_name: '',
            variant_group_id: 0,
            variant_group_name: '',
            variant_field_name: '',
            varient_fields_id: 0,
            notes: '',
            varient_fields: [] as any,
            updated_date: '',
         
  
        }) 

        setvarientFeilds([{

            variant_id: 0,
            variant_name: '',
            variant_group_id: 0,
            variant_group_name: '',
            variant_field_name: '',
            notes: '',
            varient_fields_id: 0,
          
            varient_fields: [] as any,
            updated_date: ''  
        }])

      

        setValidate({
        variant_id: 0,
        variant_name: '',
        variant_group_id: 0,
        variant_group_name: '',
        variant_field_name: '',
        varient_fields_id: 0,
        notes: '',
        varient_fields: [] as any,
        updated_date: ''
    

        }) 

      


    }


    const [currentPage, setCurrentPage] = useState(1);
    //debugger;
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filteredVarient = lstFilterVarient.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstFilterVarient.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            debugger;
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    }


    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            console.log('Before',varient)
            setVarients((prev) => ({ ...prev, [name]: value }));
            console.log('After',varient)

            for (let i = 0; i < varientFeilds.length; i++) {
                varientFeilds[i].variant_group_id = (+value);
            }
            setvarientFeilds(varientFeilds);

        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setVarients((prev) => ({ ...prev, [name]: value }));
        }
    }


    const newVarient = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) =>{
        debugger;
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setNewVarient((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setNewVarient((prev) => ({ ...prev, [name]: value }));
        }


     }

    // const handleVarientChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    //     if ('value' in e.target) {
    //         const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
    //         setVarientField((prev) => ({ ...prev, [name]: value }));
    //     } else {
    //         const { name, value } = e.target as HTMLSelectElement;
    //         setVarientField((prev) => ({ ...prev, [name]: value }));
    //     }
    // }

    const loadVarients = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterVarientsgroup(inputValue);
        callback(filteredOptions);
    };

    const filterVarientsgroup = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstVariantGroupsDrp.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstVariantGroupsDrp;
        }
    };



    const getVarients = async () => {

        debugger;
        try {
            const data: any = await ecommerceRepository.getVarients();

            setVarient({
                data
            })

            setFilterVarient(data);
            // debugger;
        }
        catch (error) {
            setVarient({
                data: []
            })
        }
    }


    const addVariant = () => {

        debugger;
        if(index>-1){
            varientFeilds[index].variant_name=newvarient.variant_name;
            setvarientFeilds([...varientFeilds]);
            setIndex(-1)
        }else{
            let checkFeild = varientFeilds.filter((x: any) => x.variant_field_name === newvarient.variant_name);

            // if(categoryFeild.category_field_name != ''){
            if (checkFeild.length == 0) {
                debugger;
                // varient.variant_name = newvarient.variant_name;
                setvarientFeilds((prevRecords: any) => [...prevRecords, newvarient]);
                setvarientFeildSubmit(false);
    
                setNewVarient({
                    variant_id: 0,
                    variant_name: '',
                    variant_group_id: 0,
                    variant_group_name: '',
                    variant_field_name: '',
                    varient_fields_id: 0,
                    notes: '',
                    varient_fields: [] as any,
                    updated_date: ''
                })
             
            } else {
               
                for (let i = 0; i < varientFeilds.length; i++) {
                    debugger;
                    if (varientFeilds[i].varient_fields_id == varient.variant_id) {
                        debugger;
                        // categoryFeilds[i].is_required = categoryFeild.is_required;
                        varientFeilds[i].variant_name = newvarient.variant_name;
                        break;
                    }
                }
    
                setvarientFeilds([...varientFeilds]);
               
            }
        }
      



    }



    const saveVarients = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const newValidate = { ...validate }; 

       

        let varientFeildind = varientFeilds.findIndex(x => (x.variant_name == ''));
        if (varientFeildind > -1) {
            varientFeilds.splice(varientFeildind, 1);

             varient.varient_fields = [];
             varient.varient_fields = varientFeilds;
        }else{

            varient.varient_fields = [];
            varient.varient_fields = varientFeilds;
        } 
        
        for(let i=0;i<varientFeilds.length;i++){
            debugger;
            varientFeilds[i].variant_group_id = varient.variant_group_id
        }

        // if (!varient.variant_name.trim()) {
        //     newValidate.variant_name = "Varient  required"
        //     setValidate(newValidate);
        // }

        if (!(varientFeilds.length > 0)) {
            newValidate.varient_fields = "Add atleat 1 Varient feild";
        }
        if (varient.variant_group_id === 0) {
            newValidate.variant_group_name = "Group required"
            setValidate(newValidate);
        }
        if (!varient.notes.trim()) {
            newValidate.notes = "Notes required"
            setValidate(newValidate);
        }
        else {

            newValidate.variant_name = "";
            newValidate.variant_group_name = "";
            newValidate.notes = "";
            newValidate.varient_fields = "";

            setValidate(newValidate);
        }


        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger;

                const responseData: any = await ecommerceRepository.saveVarients(varientFeilds)
                debugger;
                if (responseData[0].db_result > 0) {
                   // cancel();
                    debugger;
                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;

                    if (buttonElement) {
                        buttonElement.click();
                    }

                    getVarients();
                }
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            } catch (error) {
            }
        } else {
            setValidate(newValidate);
        }
    }


    const newVarients = () =>{
        cancel();

    }


    const editVarients = (e: any) => {
        debugger; 

        let varientFeilds = JSON.parse( ((e.Variants.replace(/\n/g, "")).replace(/'/g,"\"")));
       
        setvarientFeilds(varientFeilds);

        setVarients(e);
    }

    const editVarientFeild = (e: any) => {
        debugger;
        // setVarients(e);
        let findrecordiNdex=varientFeilds.findIndex(x=>x.variant_name==e.variant_name);
        if(findrecordiNdex!=-1){
        setIndex(findrecordiNdex)
        }
        setNewVarient(e);

       
    }

    const removeVarientFeild = (e: any) => {
        debugger;
        let varientFeildind = varientFeilds.findIndex(x => (x.varient_fields_id == e.varient_fields_id));
        varientFeilds.splice(varientFeildind, 1);
        varient.varient_fields = [varientFeilds]
        setvarientFeilds([...varientFeilds])

    }



    const deleteVarients = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
            const responseData: any = await ecommerceRepository.deleteVarient({ 'variant_id': e });
            if (responseData[0].db_result > 0) {
                debugger;
                Swal.fire("Record Deleted successfully", "", "success");
                getVarients();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }



    const getVarientGroups = async () => {
        try {
            debugger;
            const data: any = await ecommerceRepository.getVarientGroup();

            setVarientGroups({
                data
            }) 

            // const options: { label: string; value: string }[] = data.map((item: any) => ({
            //     label: item.variant_group_name,
            //     value: item.variant_group_id.toString(),
            // }));
            // setVariantGroupsDrp(options)

        }
        catch (error) {
            setVarientGroups({
                data: []
            })

        }
    }





    return { searchTerm, validate, cancel, nextPage, prePage, changePage, numbers, getVarients, saveVarients, handleSearch, filteredVarient, editVarients, deleteVarients, varient, handleChange, lstVarient, getVarientGroups, lstVarients, currentPage, lstVarientGroup, addVariant,  varientFeilds, editVarientFeild, removeVarientFeild,newVarients,lstVariantGroupsDrp,loadVarients,
        newvarient , newVarient,}
}