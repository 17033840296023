import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { EcommerceRepository } from "../../repository/EcommerceRepository";


export function CategoryViewModel (){

    const ecommerceRepository=EcommerceRepository();
    const imagePath = ecommerceRepository.imagePath;
    const [lstCategory,setCategorys] = useState([{
        category_fields : '',
        category_id : 0,
        category_image : '',
        category_name : '',
        category_variants : '',
        parent_category_id : 0,
        parent_category_name : '',
        updated_date : '',
    }])

    const [lstCategoryDrp, setCategoryDrp] = useState<{ label: string; value: string }[] | []>([]);
    const [lstVariantGroupsDrp, setVariantGroupsDrp] = useState<{ label: string; value: string }[] | []>([]);
    const [categorySubmit, setCategorySubmit] = useState(false); 
    const [variantSubmit, setVariantSubmit] = useState(false);
    const [categoryVariants, setCategoryVariants] = useState([{
        category_variant_id : 0,
        variant_group_id : 0,
        variant_group_name : '',
        is_required : 0,
        category_id : 0,
    }]);
    const [categoryVariant, setcategoryVariant] = useState({
        category_variant_id : 0,
        variant_group_id : 0,
        variant_group_name : '',
        is_required : 0,
        category_id : 0,
    })

    const [categoryFeildSubmit, setCategoryFeildSubmit] = useState(false);
    const [categoryFeilds, setCategoryFeilds] = useState([{
        category_fields_id : 0,
        category_field_name : '',
        is_required : 0,
        category_id : 0,
    }]);

    const [categoryFeild, setCategoryFeild] = useState({
        category_fields_id : 0,
        category_field_name : '',
        is_required : 0,
        category_id : 0,
    })

    const [validate, setValidate] = useState({
        category_id : 0,
        parent_category_id : 0,
        category_name : '',
        category_image : '',
        category_fields :'',
        category_variants : '',
        updated_date : '',
    })

    const [category, setCategory] = useState({
        category_id : 0,
        parent_category_id : 0,
        category_name : '',
        category_image : '',
        category_fields : []  as any,
        category_variants : [] as any,
        product_configurable : 0,
        updated_date : '',
        is_class_configurable:0
    }) 


    const [lstFillterCategoryes,setFillterCategoryes] = useState([{
        category_fields : '',
        category_id : 0,
        category_image : '',
        category_name : '',
        category_variants : '',
        parent_category_id : 0,
        parent_category_name : '',
        updated_date : '',
    }])


   


    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: any) => {
        debugger;
        let filteredCategory = lstCategory.filter((category: any) =>
        category.category_name.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFillterCategoryes(filteredCategory)
        //setCategorys(filteredCategory);
        setSearchTerm(e.target.value);
    };
    

    useEffect(() => {
        getCategory();
        getVarientGroup();
    }, [alert]);


    const saveCategory = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();
        setCategorySubmit(true);

        const newValidate = {...validate};

        if(file){
            let date = new Date();
            let imageName = date.getTime().toString(); // (category.category_id) + 
            category.category_image = imageName;
        }

        let categoryFeildind = categoryFeilds.findIndex(x=>(x.category_field_name == ''));
        if(categoryFeildind > -1){
            categoryFeilds.splice(categoryFeildind,1);

            category.category_fields = [];
            category.category_fields= categoryFeilds;
        }else{
            category.category_fields = [];
            category.category_fields= categoryFeilds;
        }
        
        let variantFeildind = categoryVariants.findIndex(x=>(x.variant_group_name == ''));
        if(variantFeildind > -1){
            categoryVariants.splice(variantFeildind,1);
            category.category_variants=[];
            category.category_variants=  categoryVariants;
        }else{
            category.category_variants=[];
            category.category_variants= categoryVariants;
        }

        

        if(!category.category_name.trim()){
            newValidate.category_name = "Category name required!";
        }

        // if(!category.category_image.trim()){
        //     newValidate.category_image = "Image required!";
        // }

        if(!(categoryFeilds.length>0)){
            newValidate.category_fields = "Add atleat 1 category feild";
        }
        else
        {
            newValidate.category_fields = "";

        }

        if(!(categoryVariants.length>0)){
            newValidate.category_variants = "Add atleat 1 variant feild";
        }
        else
        {

            newValidate.category_variants = "";
        }

        if (Object.values(newValidate).every((error) => !error)) {
            debugger;
            try {
                debugger;
                newValidate.category_name = "";
                const responseData:any = await   ecommerceRepository.saveCategory(category);
                debugger;
                if (responseData[0].db_result > 0) {
                    debugger;
                    
                    const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;
                    if (buttonElement) {
                        buttonElement.click();
                    }
                    if(file){
                        await uploadImage();
                    }
                    
                    debugger;
                    getCategory();
                }
                Swal.fire("Record saved successfully", "Record saved successfully", "success");
            } catch (error) {
            }
        } else {
            setValidate(newValidate);
        }
    }

    const newCategory = () =>{
        cancel();

    }


    const getCategory = async()=>{
        debugger;
        try {
            debugger;
            const data:any = await  ecommerceRepository.getCategories();
            setCategorys(
                data
            )
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.category_name,
                value: item.category_id.toString(),
            }));
            setCategoryDrp(options)
            // setFiltervarientGroups(data); 
            setFillterCategoryes(data)
        }
        catch (error) {
            setCategorys(
                 []
            )
        }
    }

    const getVarientGroup = async()=>{
        try {
            const data:any = await  ecommerceRepository.getVarientGroup();
            // setCategorys({
            //     data
            // })
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.variant_group_name,
                value: item.variant_group_id.toString(),
            }));
            setVariantGroupsDrp(options)
        }
        catch (error) {
           console.log(error);
        }
    }

    const loadCategory = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterCategory(inputValue);
        callback(filteredOptions);
    };

    const filterCategory = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstCategoryDrp.filter((option: any) => { 
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstCategoryDrp;
        }
    };

    const handleSelectChange = (selectedOption: any | null) => {
        debugger;
        setCategory(prevsetCategory => ({
            ...prevsetCategory,
            parent_category_id: selectedOption ? selectedOption.value : '',
        }));
    };

    
    

    const cancel = () => {
        debugger;
        setCategorySubmit(false);
        setVariantSubmit(false);
        setCategoryFeildSubmit(false);
        setImageSrc('');
        
        setCategory({
            category_id : 0,
            parent_category_id : 0,
            category_name : '',
            category_image : '',
            updated_date : '',
            category_fields : [] as string[],
            category_variants : [] as string[],
            product_configurable : 0,
            is_class_configurable:0
        })

        setCategoryFeilds([{
            category_fields_id : 0,
            category_field_name : '',
            is_required : 0,
            category_id : 0,
            }
        ]);
        setCategoryVariants([{
            category_variant_id : 0,
            variant_group_id : 0,
            variant_group_name : '',
            is_required : 0,
            category_id : 0,
        }]);

        setValidate({
            category_id : 0,
            parent_category_id : 0,
            category_name : '',
            category_image : '',
            category_fields :'',
            category_variants : '',
            updated_date : '',
        })
    }
    
    const handleVariantChange = (selectedOption: any | null) => {
        debugger;
        setcategoryVariant(prevsetcategoryVariant => ({
            ...prevsetcategoryVariant,
            variant_group_id: selectedOption ? selectedOption.value : '',
            variant_group_name: selectedOption ? selectedOption.label : '',
        }));
        console.log(categoryVariant);
    };

    const handleVariantRequiredChange = (selectedOption: any | null) => {
        debugger;
        let checked : any;
        if(selectedOption == true){
            checked = 1;
        }else{
            checked = 0;
        }

        setcategoryVariant(prevCategoryVariant => ({
            ...prevCategoryVariant,
            is_required: checked ? checked : checked,
        }));
        console.log(categoryVariant);
    };


    const handleProductConfigurable = (selectedOption: any | null) => {
        debugger;
        let checked : any;
        if(selectedOption == true){
            checked = 1;
        }else{
            checked = 0;
        }

        setCategory(prevCategoryVariant => ({
            ...prevCategoryVariant,
            product_configurable: checked ? checked : checked,
        }));

        if(checked == 0){
            setCategory(prevCategoryVariant => ({
                ...prevCategoryVariant,
                is_class_configurable: checked ? checked : checked,
            }));
        }
        
    };
 
    const handlecategoryConfigurable = (selectedOption: any | null) => {
        debugger;
        let checked : any;
        if(selectedOption == true){
            checked = 1;
        }else{
            checked = 0;
        }

        setCategory(prevCategoryVariant => ({
            ...prevCategoryVariant,
            is_class_configurable: checked ? checked : checked,
        }));
    };



    const handleCategoryRequiredChange = (selectedOption: any | null) => {
        debugger;
        let checked : any;
        if(selectedOption == true){
            checked = 1;
        }else{
            checked = 0;
        }

        setCategoryFeild(prevCategoryVariant => ({
            ...prevCategoryVariant,
            is_required: checked ? checked : checked,
        }));
        console.log(categoryVariant);
    };

    const addVariant = () =>{
        debugger;
        setVariantSubmit(true);

        let checkVariant = categoryVariants.filter((x:any)=>x.variant_group_id == categoryVariant.variant_group_id);
        debugger;
        // if(categoryVariant.variant_group_id>0 && categoryVariant.category_variant_id == 0){
        if(checkVariant.length == 0){
            debugger;
            setCategoryVariants((prevRecords) => [...prevRecords, categoryVariant]);
            setVariantSubmit(false);
            setcategoryVariant({
                category_variant_id : 0,
                variant_group_id : 0,
                variant_group_name : '',
                is_required : 0,
                category_id : 0,
            });
        }else 
        // if(categoryVariant.category_variant_id > 0)
        {
            debugger;
            for(let i =0;i<categoryVariants.length;i++){
                debugger;
                if(categoryVariants[i].category_variant_id == categoryVariant.category_variant_id){
                    debugger;
                    categoryVariants[i].is_required = categoryVariant.is_required;
                    break;
                }
            }
            setCategoryVariants([...categoryVariants]);

            setcategoryVariant({
                category_variant_id : 0,
                variant_group_id : 0,
                variant_group_name : '',
                is_required : 0,
                category_id : 0,
            });
            setVariantSubmit(false);
        }
    }

    const addCategory = () =>{
        debugger;
        setCategoryFeildSubmit(true);

        let checkFeild = categoryFeilds.filter((x:any)=>x.category_field_name === categoryFeild.category_field_name);

        // if(categoryFeild.category_field_name != ''){
        if(checkFeild.length == 0){
            debugger;
            setCategoryFeilds((prevRecords: any) => [...prevRecords, categoryFeild]);
            setCategoryFeildSubmit(false);
            setCategoryFeild({
                category_fields_id : 0,
                category_field_name : '',
                is_required : 0,
                category_id : 0,
            });
        }else{
            for(let i =0;i<categoryFeilds.length;i++){
                debugger;
                if(categoryFeilds[i].category_fields_id == categoryFeild.category_fields_id){
                    debugger;
                    categoryFeilds[i].is_required = categoryFeild.is_required;
                    categoryFeilds[i].category_field_name = categoryFeild.category_field_name;
                    break;
                }
            }

            setCategoryFeilds([...categoryFeilds]);
            setCategoryFeild({
                category_fields_id : 0,
                category_field_name : '',
                is_required : 0,
                category_id : 0,
            });

            setCategoryFeildSubmit(false);
        }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setCategory((prev) => ({ ...prev, [name]: value }));
        } else {
            debugger;
            const { name, value } = e.target as HTMLSelectElement;
            setCategory((prev) => ({ ...prev, [name]: value }));
        }
    }

    const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setCategoryFeild((prev) => ({ ...prev, [name]: value }));
        } else {
            debugger;
            const { name, value } = e.target as HTMLSelectElement;
            setCategoryFeild((prev) => ({ ...prev, [name]: value }));
        }
    }

    const editCategory = (e:any) =>{
        debugger;

        if(e.category_variants !=''){
            e.category_variants=JSON.parse( ((e.category_variants.replace(/\n/g, "")).replace(/'/g,"\"")));
            e.category_fields= JSON.parse(((e.category_fields.replace(/\n/g, "")).replace(/'/g,"\"")));

             // let catVariants = ((e.category_variants.replace(/\n/g, "")).replace(/'/g,"\""));
            let categoryVariantsParsedata:any[] =  (e.category_variants);
            setCategoryVariants(categoryVariantsParsedata);

        //  let setCategoryFeild = ((e.category_fields.replace(/\n/g, "")).replace(/'/g,"\""));
            let setCategoryFeildsParsedata =    (e.category_fields);
            setCategoryFeilds(setCategoryFeildsParsedata);

        }

        setImageSrc(imagePath+e.category_image+'.webp');

        let categoryData = e;
        setCategory(categoryData);

      
        // let setCategoryFeilds = JSON.parse(e.category_fields);
        // setCategoryFeilds(setCategoryFeilds);
    }

    const deleteCategory = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
          
                const responseData: any = await ecommerceRepository.deleteCategory({'category_id': e });
               
                if (responseData[0].db_result > 0) {
                    debugger;
                   Swal.fire("Record Deleted successfully", "", "success");
                    getCategory();
                } 
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }

    const editVariant = (e:any) =>{
        debugger;
        setcategoryVariant(e)
    }

    const removeVariant = (e:any) =>{
        debugger;
        let variantFeildind = categoryVariants.findIndex(x=>(x.category_variant_id == e.category_variant_id));
        categoryVariants.splice(variantFeildind,1);
        category.category_variants= [categoryVariants];
        setCategoryVariants([...categoryVariants]);
    }



    const editCategoryFeild = (e:any) =>{
        debugger;
        setCategoryFeild(e);
    }

    const removeCategoryFeild = (e:any) =>{
        debugger;
        let categoryFeildind = categoryFeilds.findIndex(x=>(x.category_fields_id == e.category_fields_id));
        categoryFeilds.splice(categoryFeildind,1);
        category.category_fields= [categoryFeilds];
        setCategoryFeilds([...categoryFeilds]);
    }

    const uploadImage = async () => {
        debugger;
        if (file) {
            debugger;
            const formData = new FormData();
            formData.append('file', file);
            formData.append('imageToSave', category.category_image);
            try {
                debugger;
                const responseData: any = await ecommerceRepository.uploadImage(formData);
                return responseData;
            }
            catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    }

    const [currentPage, setCurrentPage] = useState(1);

    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filteredCategory = lstFillterCategoryes.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstFillterCategoryes.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            debugger;
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    }

    const [file, setFile] = useState<File | undefined>(undefined);
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        debugger;
        if (e.target instanceof HTMLInputElement && e.target.files && e.target.files.length > 0) {
            debugger;
            const selectedFile = e.target.files[0];

            if (selectedFile.size > ((1024 * 1024) * 3)) {
                Swal.fire("Opps", "Max Image size is 3MB ", "error");
            }
            else {
                debugger;
                setFile(selectedFile);
                // Read the file and convert it to a data URL
                const reader = new FileReader();
                reader.onloadend = () => {
                    debugger;
                    setImageSrc(reader.result as string);
                };
                reader.readAsDataURL(selectedFile);
                debugger;
            }
        }
    };

    return {ecommerceRepository,lstCategory,setCategorys,validate, setValidate,category, setCategory,searchTerm, setSearchTerm , saveCategory,cancel,
        lstCategoryDrp,setCategoryDrp,loadCategory,handleSelectChange,
        categorySubmit, setCategorySubmit,getVarientGroup,lstVariantGroupsDrp, handleVariantChange,
        categoryFeilds, setCategoryFeilds,
        categoryVariants, setCategoryVariants,categoryFeild, setCategoryFeild,
        categoryVariant, setcategoryVariant, handleVariantRequiredChange,addVariant,
        variantSubmit, setVariantSubmit,handleChange,deleteCategory,
        addCategory,handleCategoryChange,handleCategoryRequiredChange,
        categoryFeildSubmit, setCategoryFeildSubmit,editCategory,editVariant,removeVariant,filteredCategory,
        editCategoryFeild,removeCategoryFeild, handleSearch,prePage,nextPage,changePage,numbers,currentPage,
        file,imageSrc,fileInputRef,handleImageChange,imagePath,newCategory,handleProductConfigurable,handlecategoryConfigurable }
}