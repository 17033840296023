import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { EcommerceRepository } from "../../repository/EcommerceRepository";
import { CreatePurchaseViewModel } from "../CreatePurchaseInvoice/Createpurchaseinvoicemodel";

export function AddProductViewModel(onProductAdd: any, data: any) {

    const ecommerceRepository = EcommerceRepository();
    const purchaseInvoice = CreatePurchaseViewModel();

    const [lstProductsDrp, setProductsDrp] = useState<{ label: string; value: string }[] | []>([]);

    const [lstproducts,setProducts] = useState([{
            product_details_id: 0,
            product_id:0,
            title: "",
            sku_id: "",
            status: "",
            mrp: "",
            selling_price: "",
            dispatched_days: 0,
            stock: 0,
            notify_qty: "",
            length: "",
            height: "",
            bradth: "",
            weight: "",
            pack_qty: 0,
            local_delivery_charges: "",
            zonal_delivery_charges: "",
            national_delivery_charges: "",
            notes: "",
            updated_date: "",
            tax_per: "",
            product_variant_id: 0,
            product_variants_title: "",
            product_variant_value: "",
            category_id: 0,
            brand_id: 0,
            hsn_code: "",
            cess: "",
            manufacturer: "",
            packer: "",
            merchant_id: 0,
            merchant_legal_name555: "",
            image: ""
    }])

    const [pIDetails, setpIDetails] = useState({
        purchase_inovice_product_id: 0,
        purchase_inovice_id: 0,
        product_details_id: 0,
        product_name: '',
        hsn_no: '',
        qty: 0,
        price: 0,
        gross: 0,
        discount: 0,
        tax_per: 0,
        tax: 0,
        net: 0,
        popup: '',
    });

    useEffect(() => {
        debugger;
        setpIDetails(data);
     //   viewProducts()
    }, [data])

    const [pIDetailsValidate, setpIDetailsValidate] = useState({
        purchase_inovice_product_id: '',
        purchase_inovice_id: '',
        product_details_id: '',
        hsn_no: '',
        qty: '',
        price: '',
        gross: '',
        discount: '',
        tax_per: '',
        tax: '',
        net: '',
    });

    const detailsChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
           
            setpIDetails((prev) => {
                debugger;
                const updatedDetails = { ...prev, [name]: value };
                debugger;
                if ((+updatedDetails.price) > 0 && (+updatedDetails.qty) > 0) {
                    updatedDetails.gross = updatedDetails.price * updatedDetails.qty;
                }
                debugger;
                if ((+updatedDetails.tax_per > 0) && (+updatedDetails.tax_per < 100)) {
                    debugger;
                    let total = ((+updatedDetails.gross) -(+ updatedDetails.discount));
                    debugger;
                    updatedDetails.tax = (total * updatedDetails.tax_per / 100);
                } else if (updatedDetails.tax_per == 0) {
                    updatedDetails.tax = 0
                }
                debugger;
                updatedDetails.net = ((+updatedDetails.tax )+ (((+updatedDetails.gross) - (+updatedDetails.discount))))
                return { ...updatedDetails };
            });

        } else {
            debugger;
            const { name, value } = e.target as HTMLSelectElement;
            setpIDetails((prev) => ({ ...prev, [name]: value }));
        }
    }

    const viewProducts = async () => {
        debugger;
        try {
            debugger;
            const data: any = await ecommerceRepository.ViewProducts('');
            debugger;
            const options: { label: string; value: string }[] = data.map((item: any) => ({
                label: item.title,
                value: item.product_details_id?.toString(),
            }));
            setProductsDrp(options);
            setProducts(data);
        }
        catch (error) {
            setProductsDrp([]);
        }
    }

    const productChange = (selectedOption: any | null) => {
        debugger;
        setpIDetails(prevpIDetails => ({
            ...prevpIDetails,
            product_details_id: selectedOption ? selectedOption.value : '',
            product_name: selectedOption ? selectedOption.label : '',
        }));

        for(let i=0;i<lstproducts.length;i++){
            debugger;
            if(lstproducts[i].product_details_id == selectedOption.value){
                debugger; 
                // pIDetails.tax_per = (+lstproducts[i].tax_per)
                setpIDetails((prev) => ({ ...prev, ['tax_per']: (+lstproducts[i].tax_per) }));
            }
        }

        setpIDetails((prev) => {
            debugger;
            const updatedDetails = { ...prev };
            debugger;
            if ((+updatedDetails.price) > 0 && (+updatedDetails.qty) > 0) {
                updatedDetails.gross = updatedDetails.price * updatedDetails.qty;
            }
            debugger;
            if ((+updatedDetails.tax_per > 0) && (+updatedDetails.tax_per < 100)) {
                updatedDetails.tax = (((+updatedDetails.gross) -(+ updatedDetails.discount)) * updatedDetails.tax_per / 100);
            } else if (updatedDetails.tax_per == 0) {
                updatedDetails.tax = 0
            }
            debugger;
            updatedDetails.net = ((+updatedDetails.tax )+ (((+updatedDetails.gross) - (+updatedDetails.discount))))
            return { ...updatedDetails };
        });


    };

    const loadProduct = (inputValue: string, callback: (options: { label: string; value: string }[]) => void) => {
        debugger;
        const filteredOptions = filterProduct(inputValue);
        callback(filteredOptions);
    };

    const filterProduct = (inputValue: string) => {
        debugger;
        if (inputValue != '') {
            return lstProductsDrp.filter((option: any) => {
                return option.label.toLowerCase().includes(inputValue.toLowerCase());
            }
            );
        } else {
            return lstProductsDrp;
        }
    };

    const addDetails = async (e:any) => {
        debugger;
        const validateDetails = { ...pIDetailsValidate };

        if (!(pIDetails.product_details_id > 0)) {
            validateDetails.product_details_id = "Required!";
        } else {
            validateDetails.product_details_id = "";
        }

        if (!(pIDetails.price > 0)) {
            validateDetails.price = "Required!";
        } else {
            validateDetails.price = "";
        }

        if (!(pIDetails.qty > 0)) {
            validateDetails.qty = "Required!";
        } else {
            validateDetails.qty = "";
        }

        pIDetails.popup = e;

        if (Object.values(validateDetails).every((error) => !error)) {
            debugger;
            onProductAdd(pIDetails);
        }
        else {
            setpIDetailsValidate(validateDetails);
        }

        cancel();
    }



    const cancel = () =>{
        debugger;
        setpIDetails({
            purchase_inovice_product_id: 0,
            purchase_inovice_id: 0,
            product_details_id: 0,
            product_name: '',
            hsn_no: '',
            qty: 0,
            price: 0,
            gross: 0,
            discount: 0,
            tax_per: 0,
            tax: 0,
            net: 0,
            popup: '',
        })
    }


    useEffect(() => {
        viewProducts();
    }, [alert])

    return {
        lstProductsDrp, pIDetails, pIDetailsValidate,
        productChange, loadProduct, detailsChange, addDetails, viewProducts,
    }
}
