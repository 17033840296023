import { data } from "jquery";
import { useEffect, useState } from "react"
import Swal from "sweetalert2";
import { EcommerceRepository } from "../../repository/EcommerceRepository";

export function ReturnConformationModel() {  
    const repository = EcommerceRepository();
    const imagePath = repository.imagePath

    const [lstViewReturns, setViewReturns] = useState([{
        order_id: 0,
        order_return_id:0,
        return_date: '',
        return_status: '',
        customer_id:'',
        ship_addres_type:'',
        ship_customer_full_name:'',
       ship_phone_number:'',
        ship_alternate_phone_number:'',
        ship_pincode:'',
        state_name:'',
        ship_city:'',
        ship_house_no_bulding_name:'',
        ship_road_area_colony:'',
       
        waybill_no:'',
        
        email_id:'',
        orderDetails:[] as any,
       
        return_details:[] as any

    }])    


    const [lstReturnDetails, setReturnDetails] = useState({
        order_id: 0,
        order_return_id:0,
        return_date: '',
        return_status: '',
        customer_id:'',
        ship_addres_type:'',
        ship_customer_full_name:'',
       ship_phone_number:'',
        ship_alternate_phone_number:'',
        ship_pincode:'',
        state_name:'',
        ship_city:'',
        ship_house_no_bulding_name:'',
        ship_road_area_colony:'',
       
        waybill_no:'',
        
        email_id:'',
        orderDetails:[] as any,
       
        return_details:[] as any

    }) 



    const getCurrentDate=()=> {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        let month:any = currentDate.getMonth() + 1;
        let day:any= currentDate.getDate();
      
        // Add leading zero to single-digit months and days
        month = month < 10 ? `0${month}` : month;
        day = day < 10 ? `0${day}` : day;
      
        return `${year}-${month}-${day}`;
      }
      const [selectedDate, setSelectedDate] = useState({
        _return_date :getCurrentDate()
    }); 
    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            debugger;
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setSelectedDate((prev) => ({ ...prev, [name]: value }));
        } 
    }

    const viewReturns = async () => {
        debugger;
        try {
            debugger;
            const data: any = await repository.viewReurns(selectedDate._return_date);
            debugger;
            let filterOrders = data.filter((orders: any) => orders.return_status == 'Created' )

            setViewReturns(data);

            setlstOrderstatus(filterOrders);
        }
        catch (error) {
            setViewReturns(
                []
            )
        }
    } 

    const viewOrderDetails = (e: React.FormEvent) => {
        debugger;
        e.preventDefault();
    }




    const editOrders = (e: any) => {
        debugger; 
        
       // setReturnDetails(e)
        let details = {...lstReturnDetails};
        details = e;
        // details.orderDetails = JSON.parse(e.orderDetails)
        if(e.return_details!='')
        {
            details.return_details = JSON.parse( ((e.return_details.replace(/\n/g, "")).replace(/'/g,"\"")));

        }if(details.orderDetails!='')
        {
            details.orderDetails = JSON.parse( ((e.orderDetails.replace(/\n/g, "")).replace(/'/g,"\"")));

        }
        debugger;
        setReturnDetails(details); 
      
   
    } 
   const prepareForwardShipment=async ()=>
   {

   let shipmentDetails:any=[{UserName:lstReturnDetails.ship_customer_full_name,DeliveryAddress:lstReturnDetails.ship_house_no_bulding_name,Zip:lstReturnDetails.ship_pincode,
    City:lstReturnDetails.ship_city,State:lstReturnDetails.state_name,Country:'India',PhoneNo:lstReturnDetails.ship_phone_number,OrderNo:lstReturnDetails.order_id,PaymentMode:''}]
    const responseData:any = await  repository.prepareForwardShipment(shipmentDetails);

    if (responseData.length > 0 && responseData.packages.length>0) {
        window.location.reload();
       //  responseData.packages[0].waybill;

    }

   }



    const orderReturnStatusUpdate=async (return_status:any,id:any)=>
{
    debugger;
    Swal.fire({
        title: 'Do you want to '+(return_status=='Confirm'?'Confirm':'Cancel')+'?',
        text: "This action cannot be undone!",
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, continue!',
      }).then(async (result) => {
        if (result.isConfirmed) {
          // User clicked "Yes, continue!" button
          const responseData:any = await  repository.returnStatusUpdate({return_status:return_status,return_id:id});

          if (responseData[0].db_result > 0) {
            Swal.fire(return_status+" successfully", return_status+" successfully", "success").then((action)=>
            {
                if (action.isConfirmed) {
 
    window.location.reload();

              



                }
            })
             ;
        
          }else{

          }


        } else if (result.dismiss === Swal.DismissReason.cancel) {
          // User clicked the cancel button
          Swal.fire('Cancelled', 'Your action has been cancelled.', 'info');
        }
      });
}


const [lstOrderstatus, setlstOrderstatus] = useState([{
    order_id: 0,
    order_datetime: '',
    order_status: '',
    customer_id:'',
    ship_addres_type:'',
    ship_customer_full_name:'',
   ship_phone_number:'',
    ship_alternate_phone_number:'',
    ship_pincode:'',
    state_name:'',
    ship_city:'',
    ship_house_no_bulding_name:'',
    ship_road_area_colony:'',
    bill_customer_full_name:'',
    bill_phone_number:'',
    bill_alternate_phone_number:'',
    bill_pincode:'',
    bill_state_id:'',
    bill_city:'',
    bill_house_no_bulding_name:'',
    bill_road_area_colony:'',
    gross_amount:'',
    waybill_no:'',
    coupon_discount:'',
    order_discount:'',
    order_tax:'',
    delivery_charges:'',
    net_total:'',
    payment_mode:'',
    email_id:'',
    _return_date:'', 
    title:'',
    qty:0,
    selling_price:0,
    delivery_status:'',
    image:'', 
    variants:'',
    orderDetails:[] as any

}])  


const viewFillterOrders = (e: any) => {
    debugger;
    // if(e=='Created'){
    //     let filterOrders = lstViewReturns.filter((orderStatus: any) => orderStatus.order_status ==e)
    //     setlstOrderstatus(filterOrders)
    // }else if(e=='Confirmed'){
    //     let filterOrders = lstViewReturns.filter((orderStatus: any) => orderStatus.order_status ==e  )
    //     setlstOrderstatus(filterOrders)

    // }
   
    // else if(e=='Canceled'){
    //     let filterOrders = lstViewReturns.filter((orderStatus: any) => orderStatus.order_status ==e )

    //     console.log(filterOrders)
    //     setlstOrderstatus(filterOrders)

    // }
    let filterOrders:any = lstViewReturns.filter((status: any) => status.return_status ==e )

    console.log(filterOrders)
    setlstOrderstatus(filterOrders)
};







    useEffect(() => {
        viewReturns();


    }, [alert]);



return{getCurrentDate, lstViewReturns,viewReturns,handleDateChange,selectedDate,editOrders,viewOrderDetails,lstReturnDetails,imagePath,orderReturnStatusUpdate,lstOrderstatus,viewFillterOrders

}

}