import React from "react";
import { VarientGroupViewmodel } from "./Varientgroupviewmodel";
const VarientgroupView: React.FC = () => {

    const varientGroupViewModel = VarientGroupViewmodel();


    return (

        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">EIT </a></li>
                                        {/* <li className="breadcrumb-item"><a href="javascript: void(0);">ECommerce </a></li> */}
                                        <li className="breadcrumb-item active">Varient Group</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Varient Group </h4>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="mb-2 col-md-6">
                                        <div id="products-datatable_filter" className="dataTables_filter">
                                            <label>Search:<input type="search" className="form-control form-control-sm" value={varientGroupViewModel.searchTerm} onChange={varientGroupViewModel.handleSearch} /></label>
                                        </div>
                                    </div>
                                    <div className="mb-2 col-md-6 text-end my-auto">
                                        <a className="btn btn-danger mb-2"
                                        data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={varientGroupViewModel.newVarientsclose}>
                                            <i className="mdi mdi-plus-circle me-2"></i> Add varients
                                        </a>
                                    </div>
                                    <div className="mb-2 col-md-12 mt-3">
                                        <div className="table-responsive-sm">
                                            <table className="table table-centered mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Varient Group Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {varientGroupViewModel.filteredVarientGroups.map((d: any, index: number) => (
                                                        <tr key={d.variant_group_name}>
                                                            <td>{index + 1}</td>
                                                            <td>{d.variant_group_name}</td>
                                                            <td>
                                                                <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => varientGroupViewModel.editVarientGroup(d)}></i>
                                                                {<i className="mx-2 uil uil-trash-alt" onClick={() => varientGroupViewModel.deleteVarientGroup(d.variant_group_id)}></i>}
                                                            </td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>
                                            <nav className="text-right mt-3" >

                                                <ul className='pagination float-end' >
                                                    <li className='page-item'>
                                                        <a className='page-link' onClick={varientGroupViewModel.prePage}>prev</a>
                                                    </li>
                                                    {

                                                        varientGroupViewModel.numbers.map((n, i) =>
                                                        (

                                                            <li key={i} className={`page-item ${varientGroupViewModel.currentPage === n ? 'active' : ''}`} >
                                                                <a className='page-link' onClick={() => varientGroupViewModel.changePage(n)}>{n}</a>
                                                            </li>
                                                        ))
                                                    }
                                                    <li className='page-item'>
                                                        <a className='page-link' onClick={varientGroupViewModel.nextPage}>next</a>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div id="standard-modal" className="modal fade" tabIndex={-1} aria-labelledby="standard-modalLabel"
                aria-modal="true" role="dialog" >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <form onSubmit={varientGroupViewModel.saveVarientGroup}>
                            <div className="modal-header">
                                <h4 className="modal-title" id="standard-modalLabel">Add Varient </h4>
                                <button type="button" className="btn-close" id="myModal" data-bs-dismiss="modal" aria-label="Close" onClick={varientGroupViewModel.cancel}></button>
                            </div>
                            <div className="modal-body">
                                <div className="row">

                                    <div className="col-md-12 mb-2">
                                        <label >Varient Group</label>
                                        {/* <input type="text" id="variant_group_name" name="variant_group_name" value={varientGroupViewModel.newvarient.variant_group_name} className="form-control" onChange={varientGroupViewModel.newVarients} />  */}

                                        <input type="text" id="variant_group_name" name="variant_group_name" value={varientGroupViewModel.varientGroup.variant_group_name} className="form-control" onChange={varientGroupViewModel.handleChange} />
                                        {<div className="text-danger">{varientGroupViewModel.validate.variant_group_name}</div>}
                                    </div> 

                                    <div className="col-md-12 mb-2">
                                        <label >Upload file:</label>
                                        <input type="file" id="avatar" name="guide_image"  onChange={varientGroupViewModel.handleImageChange} className="form-control" accept="image/png, image/jpeg" />
                                    </div>
                                    <div className="col-md-2 mb-2 my-auto">

                                        {/* <img src={varientGroupViewModel.imageSrc} alt="" className="w-100"></img>  */}

                               
                                        {varientGroupViewModel.imageSrc && <img src={varientGroupViewModel.imageSrc} className="w-100"  />}
                                        { varientGroupViewModel.varientGroup.variant_group_name!=''  && !varientGroupViewModel.imageSrc?  <img src={varientGroupViewModel.imagePath+varientGroupViewModel.varientGroup.guide_image+'.webp'} className="w-100" />:''}

                                    </div>

                                    <div className="col-md-12 mb-2">
                                        <label>Notes</label>
                                        <textarea name="notes" id="notes" className="form-control" value={varientGroupViewModel.varientGroup.notes} onChange={varientGroupViewModel.handleChange}></textarea>
                                        {/* {<div className="text-danger">{varientGroupViewModel.validate.notes}</div>} */}
                                    </div>

                                    <div className="col-md-10 mb-2">

                                        <label >Variant Name</label>
                                        <input type="text" id="variant_name" name="variant_name" value={varientGroupViewModel.newvarient.variant_name} className="form-control" onChange={varientGroupViewModel.newVarients} />
                                        {<div className="text-danger">{varientGroupViewModel.validate.variant_name}</div>}

                                    </div>
                                    <div className="col-md-2 mb-2">
                                        <br></br>
                                        <button type="button" className="btn btn-primary" onClick={() => varientGroupViewModel.addVariant()}>Add </button>
                                        {/* <button  className="btn btn-primary" onClick={() => varientViewModel.addVariant()} >Add </button> */}

                                    </div>
                                    <div className="mb-2 col-md-12 mt-3">
                                        <div className="table-responsive-sm">
                                            <table className="table table-centered mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Varient Name</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        varientGroupViewModel.varientFeilds.map((item, index) => (
                                                            item.variant_name &&
                                                            <tr key={index}>
                                                                <td>{index + 1}</td>
                                                                <td>{item.variant_name}</td>
                                                                <td>
                                                                <i className="uil uil-edit-alt" onClick={() => varientGroupViewModel.editVarientFeild(item)}></i>
                                                                    <i className="mx-2 uil uil-trash-alt" onClick={() => varientGroupViewModel.removeVarientFeild(item)}></i>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-bs-dismiss="modal" onClick={varientGroupViewModel.cancel} >Close </button>
                                <button type="submit" className="btn btn-primary">Save </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>






    )


}
export default VarientgroupView;