import React from "react";
import TreePicker from 'rsuite/TreePicker';
import '../../../node_modules/rsuite/TreePicker/styles/index.less';

import AsyncSelect from "react-select/async";
import { ProductViewModel } from "./Productmodel";


const ProductView: React.FC = () => {

    const viewModel = ProductViewModel();

    return (
        <div className="content-page">
            <div className="content">

                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">Products </li>
                                    </ol>

                                </div>
                                <h4 className="page-title">Product Listing</h4>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="card mb-3">
                            <div className="row m-1 pt-2 pb-2">
                                <div className="mb-2 col-md-3">
                                    <div id="products-datatable_filter" className="dataTables_filter">
                                        <label>Search:<input type="search" className="form-control form-control-sm" value={viewModel.searchTerm} onChange={viewModel.handleSearch} /></label>
                                    </div>
                                </div>
                                <div className="col-md-12 text-end mb-2">
                                    <button type="button" className="btn btn-primary" onClick={viewModel.addListing}>Add Product</button>
                                </div>
                                <div className="col-md-12">
                                    <div className="table-responsive-sm">
                                        <table className="table table-centered mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Image</th>
                                                    <th>Name </th> 
                                                    <th>Category Name </th>
                                                    <th>SKU Id</th>
                                                    <th>Status</th>
                                                    <th>MRP</th>
                                                    <th>Selling Price</th>
                                                    <th>Available Stock</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    viewModel.filteredProducts && viewModel.filteredProducts.map((item: any, index: number) => (
                                                        <tr key={item.product_details_id}>
                                                            <td>{index + 1}</td>
                                                            <td><img src={viewModel.imagePath + item.image + '.webp'} style={{ 'width': '40px' }} /> </td>
                                                            <td>{item.title}</td> 
                                                            <td>{item.category_name}</td>
                                                            <td>{item.sku_id}</td>
                                                            <td>{item.status}</td>
                                                            <td>{item.mrp}</td>
                                                            <td>{item.selling_price}</td>
                                                            <td>{item.stock}</td>
                                                            <td>
                                                                <i className="uil uil-edit-alt" onClick={(e) => viewModel.editProduct(item)}></i>
                                                                <i className="mx-2 uil uil-trash-alt" onClick={(e) => viewModel.deleteProduct(item.product_details_id)}></i>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                        <nav className="text-right mt-3" >

                                            <ul className='pagination float-end' >
                                                <li className='page-item'>
                                                    <a className='page-link' onClick={viewModel.prePage}>prev</a>
                                                </li>
                                                {

                                                    viewModel.numbers.map((n, i) =>
                                                    (

                                                        <li key={i} className={`page-item ${viewModel.currentPage === n ? 'active' : ''}`} >
                                                            <a className='page-link' onClick={() => viewModel.changePage(n)}>{n}</a>
                                                        </li>
                                                    ))
                                                }
                                                <li className='page-item'>
                                                    <a className='page-link' onClick={viewModel.nextPage}>next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    )
}


export default ProductView;