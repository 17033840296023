import React from "react";
import AsyncSelect from "react-select/async";
import { StockAdjustmentsModel } from "./StockAdjustmentsModel";
 

const StockAdjustmentsView: React.FC = () => {
    const StockAdjustmentsViewModel = StockAdjustmentsModel();
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">Stock Adjustments</li>
                                    </ol>
                                </div>
                                <h4 className="page-title">Stock Adjustments </h4>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="mb-2 col-md-6">
                                            <div id="products-datatable_filter" className="dataTables_filter">
                                            <label>Search:<input type="search" className="form-control form-control-sm" value={StockAdjustmentsViewModel.searchTerm} onChange={StockAdjustmentsViewModel.handleSearch} /></label>
                                            </div>
                                        </div>
                                        <div className="mb-2 col-md-6 text-end my-auto">
                                            <a className="btn btn-danger mb-2" onClick={StockAdjustmentsViewModel.createStockAdjustments}>
                                                <i className="mdi mdi-plus-circle me-2"></i> Add Stock Adjustments
                                            </a>
                                        </div>
                                        <div className="mb-2 col-md-12 mt-3">
                                            <div className="table-responsive-sm">
                                                <table className="table table-centered mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Voucher No</th>
                                                           
                                                            <th>Date</th>
                                                            <th>Type</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            StockAdjustmentsViewModel.filteredProductStockAdjustments && StockAdjustmentsViewModel.filteredProductStockAdjustments.map((d: any, index: number) => (
                                                                <tr key={index}>
                                                                    <td>{index+1}</td>
                                                                    <td>{d.voucher_no}</td>
                                                                    <td>{d.date}</td>
                                                                    <td>{d.type}</td>
                                                                    <td>
                                                                        <i onClick={(e)=>StockAdjustmentsViewModel.edit(d)} className="uil uil-edit-alt"></i>
                                                                        <i onClick={(e)=>StockAdjustmentsViewModel.deleteStockAdjustments(d)} className="mx-2 uil uil-trash-alt"></i>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default StockAdjustmentsView;