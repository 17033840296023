export function CommonSolutions()
{

    const stringFormatForJson=(stringValue:any)=>
    {


              
try{
    let requestedString=stringValue;
    requestedString =(requestedString.replace(/\n/g, "")).replace(/'/g,"\"");

   return  JSON.parse( requestedString);
}

catch(e)
        {
            let requestedString=stringValue;
            requestedString = requestedString.replace(/\\n/g, "\\n");
            requestedString = requestedString.replace(/\\'/g, "'");
            requestedString = requestedString.replace(/\\"/g, '"');
            requestedString = requestedString.replace(/\\\\/g, "\\");
    
           return  JSON.parse( requestedString);
        }
    }

return{stringFormatForJson}

}