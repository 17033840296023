import React from "react";
import { HSNViewmodel } from "./HSNViewmodel";
const HSNView:React.FC = () => {


    const hsnViewModel =HSNViewmodel()

return(

   <div className="content-page">
    <div className="content">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="page-title-box">
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                <li className="breadcrumb-item active">HSN</li>
                            </ol>
                        </div>
                        <h4 className="page-title">HSN </h4>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="mb-2 col-md-6">
                                    <div id="products-datatable_filter" className="dataTables_filter">
                                    <label>Search:<input type="search" className="form-control form-control-sm" value={hsnViewModel.searchTerm} onChange={hsnViewModel.handleSearch} /></label>
                                    </div>
                                </div>
                                <div className="mb-2 col-md-6 text-end my-auto">
                                    <a href="javascript:void(0);" className="btn btn-danger mb-2" data-bs-toggle="modal" data-bs-target="#standard-modal">
                                        <i className="mdi mdi-plus-circle me-2"></i> Add HSN
                                    </a>
                                </div>
                                <div className="mb-2 col-md-12 mt-3">
                                    <div className="table-responsive-sm">
                                        <table className="table table-centered mb-0">
                                            <thead className="table-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Hsn Code </th> 
                                                    <th>Tax% </th>
                                                 
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {hsnViewModel.filteredHsn.map((d: any, index: number) => (
                                                    <tr key={d.hsn_code}>
                                                        <td>{index + 1}</td>
                                                        <td>{d.hsn_code}</td> 
                                                        <td>{d.tax_per}</td> 
                                                        <td>
                                                           
                                                            <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => hsnViewModel.editHsn(d)}></i>
                                                                    {/* <i className="uil uil-edit-alt" id="myModal" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => brandViewModel.editBrands(d)}></i> */}
                                                                    {/* {<i className="mx-2 uil uil-trash-alt" onClick={() => brandViewModel.deleteBrands(d.brand_id)}></i>} */}
                                                            {<i className="mx-2 uil uil-trash-alt" onClick={() => hsnViewModel.deleteHSn(d.hsn_id) }></i>}
                                                        </td>
                                                    </tr>
                                                ))
                                                }
                                            </tbody>
                                        </table>
                                        <nav className="text-right mt-3" >

                                            <ul className='pagination float-end' >
                                                <li className='page-item'>
                                                    <a className='page-link' onClick={hsnViewModel.prePage}>prev</a>
                                                </li>
                                                {

                                                       hsnViewModel.numbers.map((n, i) =>
                                                    (

                                                        <li key={i} className={`page-item ${hsnViewModel.currentPage === n ? 'active' : ''}`} >
                                                            <a className='page-link' onClick={() => hsnViewModel.changePage(n)}>{n}</a>
                                                        </li>
                                                    ))
                                                }
                                                <li className='page-item'>
                                                    <a className='page-link' onClick={hsnViewModel.nextPage}>next</a>
                                                </li>
                                            </ul>
                                        </nav>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 

    <div id="standard-modal" className="modal fade " tabIndex={-1} aria-labelledby="standard-modalLabel" aria-modal="true" role="dialog">
        <div className="modal-dialog">
            <div className="modal-content">
                <form onSubmit={hsnViewModel.saveHsns}>
                    <div className="modal-header">
                        <h4 className="modal-title" id="standard-modalLabel">Add HSN </h4>
                        <button type="button" id="myModal" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hsnViewModel.cancel}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <label>HSN Code</label>
                                <input type="text" id="hsn_code" name="hsn_code" value={hsnViewModel.hsn.hsn_code} className="form-control" onChange={hsnViewModel.handleChange} />
                                {<div className="text-danger">{hsnViewModel.validate.hsn_code}</div>}
                            </div>
                            <div className="col-md-12 mb-2">
                            <label>Tax%</label>
                                <input type="Number" id="tax_per" name="tax_per" value={hsnViewModel.hsn.tax_per} className="form-control" onChange={hsnViewModel.handleChange} />
                                {<div className="text-danger">{hsnViewModel.validate.tax_per}</div>}
                              
                            </div>
                          
                            <div className="col-md-12 mb-2">
                                <label className="form-label">Notes</label>
                                <textarea name="notes" id="notes" className="form-control" value={hsnViewModel.hsn.notes} onChange={hsnViewModel.handleChange}></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close </button>
                        <button type="submit" className="btn btn-primary">Save </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
);

}
export default HSNView; 