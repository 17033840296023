import { useEffect, useState } from "react"
import Swal from "sweetalert2";
import { EcommerceRepository } from "../../repository/EcommerceRepository";

export function VarientGroupViewmodel() {

    const ecommerceRepository = EcommerceRepository();

    const imagePath = ecommerceRepository.imagePath;

    const [lstVarientGroup, setVarientGroup] = useState({
        data: []
    })


    const [lstFiltervarientGroups, setFiltervarientGroups] = useState(
        []
    )

    const [varientGroup, setVarientGroups] = useState({
        variant_group_id: 0,
        Variants:[] as any,
        variant_group_name: '',
        variant_id: 0,
        variant_name: '',
        guide_image:'',
        notes: '',
        updated_date: ''
    })

    const [index, setIndex] = useState(-1);
    const [varientFeilds, setVarientFeilds] = useState([{

        variant_group_id: 0,
        variant_group_name: '',
        variant_id: 0,
        variant_name: '',
        guide_image:'',
        notes: '',
        updated_date: ''


    }]);


    const [newvarient, setNewVarient] = useState({
        variant_group_id: 0,
        variant_group_name: '',
        variant_id: 0,
        variant_name: '',
        notes: '',
        updated_date: ''
    })

    const newVarients = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            setNewVarient((prev) => ({ ...prev, [name]: value }));
        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setNewVarient((prev) => ({ ...prev, [name]: value }));
        }

    }

    const newVarientsclose = () => {
        cancel();

    }

    useEffect(() => {

        getVarientGroup();

    }, [alert]);


    const [validate, setValidate] = useState({
        variant_group_id: 0,
        variant_group_name: '',
        variant_id: 0,
        variant_name: '',
        notes: '',
        updated_date: ''
    })


    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e: any) => {
        debugger;
        let filteredVarientGroups = lstVarientGroup.data.filter((varientGroup: any) =>
            varientGroup.variant_group_name.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFiltervarientGroups(filteredVarientGroups);
        setSearchTerm(e.target.value);
    };


    const cancel = () => {
        debugger;
        setImageSrc('');
        setVarientGroups({
            variant_group_id: 0,
            variant_group_name: '',
            variant_id: 0,
            variant_name: '', 
            guide_image:'',
            notes: '',
            updated_date: '',
            Variants:[]
        })
        setVarientFeilds([{
            variant_group_id: 0,
            variant_group_name: '',
            variant_id: 0,
            variant_name: '',
            guide_image:'',
            notes: '',
            updated_date: ''

        }])
        setValidate({
            variant_group_id: 0,
            variant_group_name: '',
            variant_id: 0,
            variant_name: '',
            notes: '',
            updated_date: ''
        })


    }

    const [currentPage, setCurrentPage] = useState(1);
    //debugger;
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filteredVarientGroups = lstFiltervarientGroups.slice(firstIndex, lastIndex);
    const npage = Math.ceil(lstFiltervarientGroups.length / recordsPerPage);
    const numbers = [...Array(npage + 1).keys()].slice(1);

    function prePage() {
        if (currentPage != 1) {
            debugger;
            setCurrentPage(currentPage - 1);
        }
    }

    function nextPage() {
        if (currentPage != npage) {
            debugger;
            setCurrentPage(currentPage + 1);
        }
    }

    function changePage(id: React.SetStateAction<number>) {
        debugger;
        setCurrentPage(id);
    } 

    const [file, setFile] = useState<File | undefined>(undefined);
    const [imageSrc, setImageSrc] = useState<string | undefined>(undefined);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        debugger;
        if ('value' in e.target) {
            const { name, value } = e.target as HTMLInputElement | HTMLTextAreaElement;
            console.log('Before',varientGroup)
            setVarientGroups((prev) => ({ ...prev, [name]: value }));
            console.log('After',varientGroup)

            // for (let i = 0; i < varientFeilds.length; i++) {
            //     varientFeilds[i].variant_group_id = (+value);
            // }
            setVarientFeilds(varientFeilds);

        } else {
            const { name, value } = e.target as HTMLSelectElement;
            setVarientGroups((prev) => ({ ...prev, [name]: value }));
        }
    } 


    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        debugger;
        if (e.target instanceof HTMLInputElement && e.target.files && e.target.files.length > 0) {
            debugger;
            const selectedFile = e.target.files[0];

            if (selectedFile.size > ((1024 * 1024) * 3)) {
                Swal.fire("Opps", "Max Image size is 3MB ", "error");
            }
            else {
                setFile(selectedFile);
                // Read the file and convert it to a data URL
                const reader = new FileReader();
                reader.onloadend = () => {
                    setImageSrc(reader.result as string);
                };
                reader.readAsDataURL(selectedFile);
                debugger;
            }
        }
    };



    const [showModal, setShowModal] = useState(false);

    const getVarientGroup = async () => {

        debugger;
        try {
            const data: any = await ecommerceRepository.getVarientGroup();

            setVarientGroup({
                data
            })

            setFiltervarientGroups(data);
            // debugger;
        }
        catch (error) {
            setVarientGroup({
                data: []
            })
        }
    }

    const addVarientGroup = async (e: React.FormEvent) => {
        debugger;
        e.preventDefault();
        setShowModal(true);
    } 

    const uploadImage = async () => {
        debugger;
        if (file) {
            debugger;
            const formData = new FormData(); 
          
            formData.append('file', file);
            formData.append('imageToSave', varientGroup.guide_image);
            try {
                debugger;
                const responseData: any = await ecommerceRepository.uploadImage(formData);
                // window.location.reload();
                debugger;
                console.log('Image uploaded successfully:', responseData.imageUrl);
                debugger;
            }
            catch (error) {
                console.error('Error uploading image:', error);
            }
        }
    }



    const saveVarientGroup = async (e: React.FormEvent<HTMLFormElement>) => {
        debugger;
        e.preventDefault();

        const newValidate = { ...validate };

        if (!varientGroup.variant_group_name.trim()) {
            newValidate.variant_group_name = "Varient Group required"
            setValidate(newValidate);
        }
        // if (!varientFeilds[0].variant_name.trim()) {
        //     newValidate.variant_name = "Varient Name required"
        //     setValidate(newValidate);
        // }
        else{
            newValidate.variant_group_name =""; 
          //  newValidate.variant_name ="";
            setValidate(newValidate);

        }

        let varientFeildind = varientFeilds.findIndex(x => (x.variant_name == ''));
        if (varientFeildind > -1) {
            varientFeilds.splice(varientFeildind, 1);
            // varientGroup.varient_fields = varientFeilds

        }

        for (let i = 0; i < varientFeilds.length; i++) {
            debugger;
            varientFeilds[i].variant_group_name = varientGroup.variant_group_name ;
           // varientFeilds[i].notes = varientGroup.notes;
           
        } 

        
        for (let i = 0; i < varientFeilds.length; i++) {
            debugger;
           // varientFeilds[i].variant_group_name = varientGroup.variant_group_name ;
            varientFeilds[i].notes = varientGroup.notes;
           
        }

  

        if (Object.values(newValidate).every((error) => !error)) {
            try {
                debugger;
                newValidate.variant_group_name = "";

                try {
                    let variant_group = { 'variant_group_id': varientFeilds[0].variant_group_id, 'variant_group_name': varientFeilds[0].variant_group_name, 'notes': varientFeilds[0].notes, 'guide_image': varientFeilds[0].guide_image, 'updated_date': varientGroup.updated_date, };
                    let variants = JSON.stringify((varientFeilds as any));//[1].varient_fields); 

                    let date = new Date();
                    let imageName = (varientGroup.variant_group_id) + date.getTime().toString();
                    varientGroup.guide_image = imageName;
                    varientGroup.Variants = '';
    

                    const responseData: any = await ecommerceRepository.saveVarientGroup({ 'variant_group': varientGroup, 'variants': variants })

                    if (responseData[0].db_result > 0) {
                        cancel();

                        const buttonElement = document.getElementById('myModal') as HTMLButtonElement | null;

                        if (buttonElement) {
                            buttonElement.click();
                        } 
                        if(file){
                            await uploadImage();
                        }

                        getVarientGroup();
                    }       
                    Swal.fire("Record saved successfully", "Record saved successfully", "success");
                } catch (e) {
                    debugger
                }
               
            } catch (error) {
            }
        } else {
            setValidate(newValidate);
        }
    }

    const editVarientGroup = (e: any) => {
        debugger; 
        let varientFeilds = JSON.parse( ((e.Variants.replace(/\n/g, "")).replace(/'/g,"\"")));
        setVarientFeilds(varientFeilds);
        setVarientGroups(e); 

        // setImageSrc(imagePath+e.guide_image+'.webp');

        // let varientData = e;
        // setVarientGroups(varientData);
    
    }  

   


    const editVarientFeild = (e: any) => {
        debugger;
        // setVarients(e);
        let findrecordiNdex=varientFeilds.findIndex(x=>x.variant_name==e.variant_name);
        if(findrecordiNdex!=-1){
        setIndex(findrecordiNdex)
        }
        setNewVarient(e);

       
    }  

    const removeVarientFeild = (e: any) => {
        debugger;
        let varientFeildind = varientFeilds.findIndex(x => (x.variant_name == e.variant_name));
        varientFeilds.splice(varientFeildind, 1);
      // varientGroup.variant_id.toString() = [varientFeilds]
      setVarientFeilds([...varientFeilds])

    }

    const deleteVarientGroup = async (e: number) => {
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
            const responseData: any = await ecommerceRepository.deleteVarientGroup({ 'variant_group_id': e });
            if (responseData[0].db_result > 0) {
                debugger;
                Swal.fire("Record Deleted successfully", "", "success");
                getVarientGroup();
            }
        } else if (willDelete.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Your record is safe!", "", "info");
        }
    }

    const addVariant = () => {

        debugger;
        if (index > -1) {
            varientFeilds[index].variant_name = newvarient.variant_name;
            setVarientFeilds([...varientFeilds]);
            setIndex(-1)
        } else {
            let checkFeild = varientFeilds.filter((x: any) => x.variant_field_name === newvarient.variant_name);

            // if(categoryFeild.category_field_name != ''){
            if (checkFeild.length == 0) {
                debugger;
                // varient.variant_name = newvarient.variant_name;
                setVarientFeilds((prevRecords: any) => [...prevRecords, newvarient]);
                //setvarientFeildSubmit(false);

                setNewVarient({
                    variant_group_id: 0,
                    variant_group_name: '',
                    variant_id: 0,
                    variant_name: '',
                    notes: '',
                    updated_date: ''
                })

            } else {

                for (let i = 0; i < varientFeilds.length; i++) {
                    debugger;
                    if (varientFeilds[i].variant_group_id == varientGroup.variant_id) {
                        debugger;
                        // categoryFeilds[i].is_required = categoryFeild.is_required;
                        varientFeilds[i].variant_name = newvarient.variant_name;
                        break;
                    }
                }

                setVarientFeilds([...varientFeilds]);

            }
        }




    }










    return { validate, varientGroup, saveVarientGroup, getVarientGroup, lstVarientGroup, handleChange, cancel, filteredVarientGroups, addVarientGroup, searchTerm, handleSearch, editVarientGroup, deleteVarientGroup, prePage, nextPage, changePage, numbers, currentPage, newVarients, newvarient, addVariant, varientFeilds, newVarientsclose,editVarientFeild,removeVarientFeild,handleImageChange,imageSrc,
        imagePath }

}