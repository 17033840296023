import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { EcommerceRepository } from "../../repository/EcommerceRepository";
import { useHistory } from "react-router-dom";

export function PurchaseViewModel() {

    

    const ecommerceRepository = EcommerceRepository();
    const history = useHistory();

    const [purchaseInvoice,setPurchaseInvoice] = useState([{
        purchase_inovice_id : 0,
        invoice_no : '',
        bill_ref : '',
        date : '',
        merchant_id :0,
        gross :0,
        tax :0,
        discount :0,
        net_total :0,
        updated_date : '',
    }]); 


    const [lstFilterProductInv, setFilterProductInv] = useState([{
        purchase_inovice_id : 0,
        invoice_no : '',
        bill_ref : '',
        date : '',
        merchant_id :0,
        gross :0,
        tax :0,
        discount :0,
        net_total :0,
        updated_date : '',
    }]) 



    
    const [searchTerm, setSearchTerm] = useState('');   

    const [currentPage, setCurrentPage] = useState(1);
    debugger;
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const filteredProductInv = lstFilterProductInv.slice(firstIndex, lastIndex);
   // const npage = Math.ceil(lstFilterProductInv.length / recordsPerPage);
  

    const handleSearch = (e: any) => {
        debugger;
        let filteredProductInv = purchaseInvoice.filter((purchaseInvoice: any) => 
        purchaseInvoice.invoice_no.toLowerCase().includes(e.target.value.toLowerCase())
        );

        setFilterProductInv(filteredProductInv);
       // setFilterProduct({...filteredProducts});
        setSearchTerm(e.target.value);
    };

    const edit = (d:any) => {
        debugger;

        const queryParams = {
            id: d.purchase_invoice_id,
            invoice: d.invoice_no
          };
          const queryString = new URLSearchParams(queryParams).toString();
          history.push(`/createpurchaseinvoice?${queryString}`);

    }

    const newInvoice = () =>{
            debugger;
        // const queryParams = {
        //     id: '',
        //     invoice: ''
        //   };
        //   const queryString = new URLSearchParams(queryParams).toString();
        history.push(`/createpurchaseinvoice`);
         // history.push(`/create-purchase-invoice`);
    }

    const deleteproduct = async (d:any) =>{
        debugger;
        const willDelete = await Swal.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        });

        if (willDelete.isConfirmed) {
            debugger;
            const responseData: any = await ecommerceRepository.deletePurchaseInvoice({'purchase_inovice_id': d.purchase_invoice_id });
            debugger;
                if (responseData[0].db_result > 0) {
                    debugger;
                    viewInvoices();
                   Swal.fire("Record Deleted successfully", "", "success");
                   
                } 
            Swal.fire("Record Deleted successfully", "", "success");
        }else{
            Swal.fire("Your record is safe!", "", "info");
        } 

    }

    useEffect(()=>{
        viewInvoices();
    },[alert])
    
    const viewInvoices = async () => {
        debugger;
        try {
            debugger;
            const data:any = await  ecommerceRepository.viewPurchaseInvoice({"invoice_no":""});
            setPurchaseInvoice(data);
            setFilterProductInv(data);
           
        }
       
        catch (error) {
            setPurchaseInvoice([]);
           
        }
    } 

    return{
        purchaseInvoice,
        edit,
        deleteproduct,
        newInvoice,searchTerm,handleSearch,filteredProductInv
    }
}

