import React from "react";
import AsyncSelect from "react-select/async";
import { StockAdjustmentsModel } from "./StockAdjustmentsModel"; 

const CreateStockAdjustments: React.FC<{ details: any }> = ({ details }) => {
    const viewModel = StockAdjustmentsModel();
    //  const addProductModel = AddProductViewModel();
    return (
        <div className="content-page">
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box">
                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item"><a href="javascript: void(0);">ETI </a></li>
                                        <li className="breadcrumb-item active">Create Stock Adjustments</li>
                                    </ol>
                                </div>
                                <h3 className="page-title">Stock Adjustments </h3>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        
                                        <div className="mb-2 col-md-12">
                                            <div className="modal-body">
                                                <>
                                                    <div className="row">


                                                    <div className="col-md-2 mb-2">
                                                            <label className="form-label">Voucher No:</label>
                                                            <input className="form-control" id="voucher_no" placeholder="Voucher No" readOnly={true} name="voucher_no" value={viewModel.selectedStockAdjustments.voucher_no}   />
                                                        </div>


                                                        <div className="col-md-2 mb-2">
                                                            <label className="form-label">Date:</label>
                                                            <input className="form-control" type="date" placeholder="date" name="date"
                                                                value={viewModel.selectedStockAdjustments.date} onChange={viewModel.onDateChange} />
                                                        </div>


                                                     
                                                        <div className="col-md-2 mb-2">
                                                            <label className="form-label">Type:</label>

                                                            <select  className="form-control"   id="type" placeholder="Type" name="type"
                                                            
                                                            value={viewModel.selectedStockAdjustments.type} onChange={viewModel.onTypeChange}
                                                            >
                                                                 <option value={'Select'}>Select</option>
                                                            <option value={'In'}>Stock In</option>
                                                            <option value={'Out'}>Stock Out</option>
                                                            </select>
                                                            
                                                        </div>
                                                        <div className="col-md-2 mb-2"></div>

                                                        
                                                        

                                                        <div className="col-md-4 text-end">
                                                          
                                                            <button className="btn btn-primary mx-2" type="submit" onClick={viewModel.getStockAdjustments}>View Stock Adjustments</button>
                                                           
                                                            <button className="btn btn-primary mx-2" type="submit" onClick={viewModel.saveStockAdjustments}>Save</button>
                                                        </div>
                                                        <div className="col-md-4 mb-3">
                                                            <label className="form-label">Notes: </label>

                                                            <textarea className="form-control"  id="notes" value={viewModel.selectedStockAdjustments.notes} onChange={viewModel.onNotesChange}></textarea>
                                                             
                                                            
                                                            
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <h4 className="d-flex">Products</h4>
                                                    </div>
                                                    <div className="col-md-10">
                                                        <button className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={viewModel.addProduct}> + Add </button>
                                                    </div>

                                                </div>
                                                <div className="mb-2 col-md-12 mt-3">
                                                    <div className="table-responsive-sm">
                                                        <table className="table table-centered mb-0">
                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th>S.No</th>
                                                                    <th>Product</th>
                                                                    
                                                                    <th>Quantity</th>
                                                                    
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                     viewModel.lstStockAdjustmentsChildProducts.map((d: any, index: number) => (
                                                                        <tr key={index}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{d.title}</td>
                                                                            
                                                                            <td>{d.qty}</td>
                                                                            
                                                                            <td>
                                                                                <i className="uil uil-edit-alt" data-bs-toggle="modal" data-bs-target="#standard-modal" onClick={() => viewModel.StockAdjustmentsChildEdit(d,index)}></i>
                                                                                <i className="mx-2 uil uil-trash-alt" onClick={() => viewModel.removeStockAdjustmentsProduct(index)}></i>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                              
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="standard-modal" className="modal fade " tabIndex={-1} aria-labelledby="standard-modalLabel" aria-modal="true" role="dialog">
                <div className="modal-dialog ">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" id="standard-modalLabel">Add Product</h4>
                            <button type="button" id="addProductModelClose" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {/* <AddProduct onProductAdd={viewModel.handleAddButtonClick }
                                data={viewModel.purchaseInvoiceDetails}
                            /> */}

<div className="row">
                                                        <div className="col-md-6 mb-2">
                                                            <label className="form-label">Product<span style={{color:"red"}}>*</span></label>
                                                            <AsyncSelect
                                                                isClearable
                                                                isSearchable
                                                                cacheOptions
                                                                defaultOptions={viewModel.lstProducts}
                                                                onChange={viewModel.onProductChange}
                                                                value={viewModel.lstProducts.length > 0 ? (viewModel.lstProducts.find((option: any) => option.value == (viewModel.selectedProduct as any).product_details_id) || null) : null}
                                                                loadOptions={viewModel.loadProduct}
                                                            />
                                                        </div>
                                                        <div className="col-md-6"></div>
                                                        <div className="col-md-6 mb-2">
                                                            <label className="form-label">Qty<span style={{color:"red"}}>*</span></label>
                                                            <input className="form-control" id="productQty" placeholder="Qty" name="productQty"
                                                             value={viewModel.selectedProduct.qty} onChange={(e)=>viewModel.onProductQtyChange(e)} />
                                                        </div>

                                                        <div className="col-md-12 mt-2 text-end">
               
               <a className="btn btn-danger mb-2" onClick={(e)=>viewModel.addProductDetails('add')}>
                   Add <i className="mdi mdi-check mx-1"></i>
               </a>

               <a className="btn btn-danger mb-2 mx-2" onClick={(e)=>viewModel.addProductDetails('addPlus')}>
                   Add <i className="mdi mdi-plus mx-1"></i>
               </a>
           </div>
                                                        </div>


                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CreateStockAdjustments